<template>
  <div id="invitationRecord">
    <div class="search">
      <el-radio-group v-model="post.is_teacher">
        <el-radio-button :label="1">内部邀请人</el-radio-button>
        <el-radio-button :label="2">外部邀请人</el-radio-button>
        <el-radio-button :label="3">变更记录</el-radio-button>
      </el-radio-group>
      <el-input
        v-model="post.search"
        size="medium"
        style="width: 218px"
        :placeholder="
          post.is_teacher == 3 ? '输入变更后邀请人' : '输入邀请人名称搜索'
        "
      ></el-input>
    </div>
    <pagination2
      :option="post"
      :url="
        post.is_teacher == 3
          ? '/openClass/inviteeChangeLog'
          : '/openClass/invitationList'
      "
      ref="invitationList"
    >
      <template v-slot:default="{ tableData }">
        <changeRecord
          :list="tableData"
          v-if="post.is_teacher == 3"
        ></changeRecord>
        <template v-else>
          <el-table
            ref="dialogTable"
            :header-cell-style="{
              background: 'rgba(245,245,245,1)',
              color: '#333333',
            }"
            :data="tableData"
            style="width: 100%"
          >
            <el-table-column label="邀请人">
              <template slot-scope="{ row }">
                <div class="membership">
                  <div class="tu">
                    <img
                      style="width: 22px; height: 22px"
                      :src="row.uphoto"
                      alt=""
                    />
                  </div>
                  <div class="right">{{ row.uname }}</div>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="邀请总人数" prop="address" align="right">
              <template slot-scope="{ row }">
                <div>{{ row.count }}</div>
              </template>
            </el-table-column>
            <el-table-column label="邀请概况" prop="address" align="right">
              <template slot-scope="{ row }">
                <div>
                  今日新增：{{ row.today_num }}
                  <br />
                  最近7天：{{ row.week_num }}
                  <br />
                  最近30天：{{ row.month_num }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" prop="address" align="right">
              <template slot-scope="{ row }">
                <el-button type="text" @click="Invited(row)">
                  查看列表
                </el-button>
                <template v-if="post.is_teacher == 1">
                  <el-divider direction="vertical"></el-divider>
                  <el-button type="text" @click="bindUser(row)">
                    变更邀请人
                  </el-button>
                </template>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </template>
    </pagination2>
    <el-dialog
      class="dialogVisible"
      :visible.sync="dialogVisible"
      width="800px"
    >
      <div slot="title" class="title">
        <span style="color: #ff3535">{{ post2.inviter_name }}</span>
        的邀请列表
      </div>
      <div class="flex-center inviter-search">
        <el-button
          @click="exportTable"
          type="primary"
          size="medium"
          class="mr20"
        >
          导出
        </el-button>
        <el-radio-group v-model="post2.areaType">
          <el-radio-button :label="1">今天</el-radio-button>
          <el-radio-button :label="2">最近7天</el-radio-button>
          <el-radio-button :label="3">最近30天</el-radio-button>
          <el-radio-button :label="4">全部</el-radio-button>
        </el-radio-group>
        <el-date-picker
          size="medium"
          style="width: 292px; margin-left: 20px"
          v-model="time"
          type="daterange"
          @change="timepick"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </div>
      <pagination2
        v-if="dialogVisible"
        :option="post2"
        url="/openClass/inviteeList"
        @complete="inviteeListComplete"
      >
        <template v-slot:default="{ tableData }">
          <el-table
            ref="dialogTable"
            :header-cell-style="{
              background: 'rgba(245,245,245,1)',
              color: '#333333',
            }"
            :data="tableData"
            style="width: 100%"
          >
            <el-table-column label=" 昵称">
              <template slot-scope="{ row }">
                <div class="membership">
                  <div class="tu">
                    <img
                      style="width: 22px; height: 22px"
                      :src="row.uphoto"
                      alt=""
                    />
                  </div>
                  <div class="right">{{ row.uname }}</div>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="进入时间 " prop="address">
              <template slot-scope="{ row }">
                {{ row.invite_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
              </template>
            </el-table-column>
          </el-table>
        </template>
      </pagination2>
    </el-dialog>
    <bindUser
      v-if="showBindUser"
      :value="showBindUser"
      @close="bindUser"
    ></bindUser>
  </div>
</template>
<script>
import changeRecord from './changeRecord'
import bindUser from './bindUser'

export default {
  name: 'invitationRecord',

  components: {
    bindUser,
    changeRecord,
  },

  data() {
    return {
      post2: {
        open_class_id: this.$route.params.id,
        inviter_id: '',
        startTime: 0,
        endTime: 0,
        areaType: 4,
      },

      time: '',
      inviteeListCount: 0,
      dialogVisible: false,
      showBindUser: false,

      post: {
        is_teacher: 1,
        open_class_id: this.$route.params.id,
        search: '',
      },
    }
  },

  filters: {
    getis_teacher(val) {
      let text
      switch (Number(val)) {
        case 0:
          text = '学员'
          break
        default:
          text = '网校老师'
          break
      }
      return text
    },
  },

  watch: {
    dialogVisible(val) {
      if (!val) {
        this.post2 = {
          open_class_id: this.$route.params.id,
          inviter_id: '',
          startTime: 0,
          endTime: 0,
          areaType: 4,
        }
        this.time = ''
      }
    },

    'post2.areaType'(val) {
      if (val != 0) {
        this.time = ''
        this.post2.startTime = 0
        this.post2.endTime = 0
      }
    },
  },

  methods: {
    Invited({ inviter_id, uname }) {
      this.post2.inviter_id = inviter_id
      this.post2.inviter_name = uname
      this.$nextTick(() => {
        this.dialogVisible = true
      })
    },

    // 绑定用户
    bindUser(row) {
      this.showBindUser = row ? row : null
      if (!row) {
        this.$refs.invitationList.reset()
      }
    },

    timepick(val) {
      // 如果清空了选择的时间
      if (!val) {
        this.post2.startTime = 0
        this.post2.endTime = 0
        this.post2.areaType = 4
      } else {
        val = val || [0, 0]
        const v1 = Math.floor(val[0] / 1000)
        const v2 = Math.floor(val[1] / 1000)

        this.post2.startTime = v1
        this.post2.endTime = v2 ? v2 + 86399 : 0
        this.post2.areaType = 0
      }
    },

    inviteeListComplete(val) {
      this.inviteeListCount = val.count
    },

    // 导出
    async exportTable() {
      let verify = this.inviteeListCount

      if (
        this.post2.areaType == 4 &&
        (!this.post2.startTime || !this.post2.endTime)
      ) {
        this.$root.prompt('请选择时间段导出')
        return
      }

      if (this.post2.endTime - this.post2.startTime > 30 * 86400) {
        this.$root.prompt({
          msg: '最多支持30天的数据导出，当前区间超过30天，请重新选择！',
          type: 'warning',
        })
        return
      }

      if (verify > 0) {
        let startTime, endTime
        if (this.post2.areaType === 0) {
          startTime = this.post2.startTime
          endTime = this.post2.endTime
        } else {
          const timing =
            this.post2.areaType == 1
              ? 0
              : this.post2.areaType == 2
              ? 6 * 86400
              : 29 * 86400
          startTime = Math.floor(Date.now() / 1000 - timing)
          endTime = Math.floor(Date.now() / 1000)
        }
        await this.$confirm(
          `确定要导出当前${verify}条数据？`,
          `导出${this.$options.filters.formatTimeStamp(
            startTime,
            'yyyy-MM-dd'
          )} ~ ${this.$options.filters.formatTimeStamp(
            endTime,
            'yyyy-MM-dd'
          )}数据`,
          {
            dangerouslyUseHTMLString: true,
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }
        )
        // 所有搜索条件get传递过去
        const query = JSON.stringify(_.assign({}, this.post2))

        this.$store.dispatch(
          'open',
          `${location.origin}/openClass/inviteeExcel?query=${encodeURIComponent(
            query
          )}`
        )
      } else {
        this.$root.prompt('暂无数据，无法导出！')
      }
    },
  },
}
</script>
<style lang="scss" scoped>
#invitationRecord {
  .search {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
    justify-content: space-between;
  }
  .membership {
    display: flex;
    align-items: center;
    .tu {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      margin-right: 10px;
    }
    .right {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 14px;
      color: #333333;
      line-height: 14px;
    }
  }
  ::v-deep .dialogVisible {
    .el-dialog__body {
      min-height: 500px;
      padding-top: 20px !important;
    }
    .el-table__body-wrapper {
      // height: 350px;
      overflow-y: auto;
      // padding-right: 20px;
    }
    .title {
      font-size: 14px;
      color: #666666;
      line-height: 14px;
    }
  }
}
.inviter-search {
  margin-bottom: 20px;
  justify-content: right;
  transform: scale(0.9);
  transform-origin: right;
  ::v-deep .el-radio-button__inner {
    padding: 10px 20px;
  }
}
</style>
