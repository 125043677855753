<template>
  <div class="box">
    <div class="flex-center title">
      <span class="text">{{ text }}</span>
      <helpIcon class="help" v-if="prompt" :prompt="prompt"> </helpIcon>
    </div>
    <div class="value">{{ number || '--' }}</div>
  </div>
</template>
<script>
import helpIcon from '@/components/helpIcon'

export default {
  props: {
    text: String,
    prompt: String,
    number: [Number, String]
  },

  name: 'digitalDisplay',

  components: {
    helpIcon
  }
}
</script>
<style lang="scss" scoped>
.box {
  flex: 1;
  height: 108px;
  margin-left: 20px;
  padding-left: 20px;
  border-radius: 2px;
  margin-bottom: 20px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(224, 224, 224, 1);
  .title {
    padding-top: 20px;
    .text {
      font-size: 13px;
      color: rgba(51, 51, 51, 1);
      line-height: 17px;
    }
    .help {
      margin-left: 4px;
    }
  }
  .value {
    margin-top: 20px;
    font-size: 24px;
    font-weight: bold;
    line-height: 31px;
    color: rgba(51, 51, 51, 1);
  }
}
</style>
