<!--
 * @Author: cyy
 * @Date: 2022-03-03 17:27:33
 * @LastEditTime: 2022-03-21 10:19:03
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \ teacher.dingdingkaike\src\views\LiveBroadcastManagement\openCourse\detailcomponents\setting\components\fieldControl.vue
-->
<template>
  <div id="fieldControl">
    <div class="page-list">
      <div class="item">
        <div class="left">
          <div class="text1">来宾欢迎</div>
          <div class="text2">
            <span
              @click="welcomeOpen"
              :style="`${
                options.is_show_welcome == 1 ? 'color: #FF3535;' : ''
              }`"
            >
              {{ options.is_show_welcome == 1 ? '停用' : '启用' }}
            </span>
            <span style="margin: 0 10px">|</span>
            <span @click="toggleVisible(1)">配置</span>
          </div>
        </div>
        <div class="right">
          启用后，可自定义内容，对榜单前十的用户进入直播间展示欢迎语
        </div>
      </div>
      <div class="item">
        <div class="left">
          <div class="text1">个性聊天气泡</div>
          <div class="text2" @click="toggleVisible(2)">配置</div>
        </div>
        <div class="right">
          对已打赏、送礼、邀请榜单前十的学员显示个性的聊天气泡，激励学员，提示活跃度
        </div>
      </div>
      <div class="item">
        <div class="left">
          <div class="text1">学员行为展示</div>
          <div class="text2" @click="toggleVisible(3)">配置</div>
        </div>
        <div class="right">
          学员在直播间分享、订阅、访问课程等会有对应的信息展示，带动其他学员
        </div>
      </div>
      <div class="item">
        <div class="left">
          <div class="text1">添加微信按钮</div>
          <div class="text2" @click="toggleVisible(4)">配置</div>
        </div>
        <div class="right">
          在直播间会显示加微信按钮，点击会弹出已配置好的内容
        </div>
      </div>
    </div>
    <!--来宾欢迎-->
    <el-dialog
      class="dialog welcomeMessage"
      :visible.sync="visible1"
      :append-to-body="true"
      :close-on-click-modal="false"
      @closed="wlMsgClose"
      width="800px"
    >
      <slot slot="title">
        <div class="title">
          <div class="text">来宾欢迎</div>
          <helpIcon
            prompt="启用后，可自定义内容，对榜单前十的用户进入直播间展示欢迎语"
          ></helpIcon>
        </div>
      </slot>
      <div class="content">
        <div class="left">欢迎语</div>
        <div class="right">
          <el-input
            class="textarea"
            type="textarea"
            maxlength="30"
            rows="6"
            resize="none"
            v-model="options.welcome_text"
            show-word-limit
          ></el-input>
          <div class="text">
            <div class="t1">显示格式：[昵称] 来了，欢迎语</div>
            <div
              class="t2"
              @click="isEmptyWelcomeText(), (viewWelcomeMsg = !viewWelcomeMsg)"
            >
              预览
            </div>
          </div>
          <div class="demo" v-if="viewWelcomeMsg && options.welcome_text">
            王多鱼 来了，{{ options.welcome_text }}
          </div>
        </div>
      </div>
      <slot slot="footer">
        <div class="save-btn">
          <div class="btn1" @click="visible1 = false">取消</div>
          <div class="btn2" @click="saveWelcomeText">保存</div>
        </div>
      </slot>
    </el-dialog>
    <!--个性聊天气泡-->
    <el-dialog
      class="dialog bubble"
      :visible.sync="visible2"
      :append-to-body="true"
      :close-on-click-modal="false"
      width="800px"
    >
      <slot slot="title">
        <div class="title">
          <div class="text">个性聊天气泡</div>
          <helpIcon
            prompt="开启后，对已打赏、送礼、邀请榜单前十的学员显示个性的聊天气泡，激励学员，提示活跃度"
          ></helpIcon>
        </div>
      </slot>
      <ul class="list">
        <li class="item">
          <el-switch
            :width="34"
            active-value="1"
            inactive-value="2"
            :value="options.is_show_reward"
            @change="saveSwitchVal('is_show_reward')"
          />
          <div class="text">打赏榜单前十的学员</div>
          <img class="img" src="~@ass/img/1.4.2.8/img_dasqp.png" alt="" />
        </li>
        <li class="item">
          <el-switch
            :width="34"
            active-value="1"
            inactive-value="2"
            :value="options.is_show_gift"
            @change="saveSwitchVal('is_show_gift')"
          />
          <div class="text">送礼榜单前十的学员</div>
          <img class="img" src="~@ass/img/1.4.2.8/img_slqp.png" alt="" />
        </li>
        <li class="item">
          <el-switch
            :width="34"
            active-value="1"
            inactive-value="2"
            :value="options.is_show_invite"
            @change="saveSwitchVal('is_show_invite')"
          />
          <div class="text">邀请榜单前十的学员</div>
          <img class="img" src="~@ass/img/1.4.2.8/img_yqqp.png" alt="" />
        </li>
        <li class="item">
          <el-switch
            :width="34"
            active-value="1"
            inactive-value="2"
            :value="options.is_show_all"
            @change="saveSwitchVal('is_show_all')"
          />
          <div class="text">多个榜单前十的学员</div>
          <img class="img" src="~@ass/img/1.4.2.8/img_dbdqp.png" alt="" />
        </li>
      </ul>
    </el-dialog>
    <!--学员行为展示-->
    <el-dialog
      class="dialog studentBehavior"
      :visible.sync="visible3"
      :append-to-body="true"
      :close-on-click-modal="false"
      width="800px"
    >
      <slot slot="title">
        <div class="title">
          <div class="text">学员行为展示</div>
          <helpIcon
            prompt="开启后，学员在直播间分享、订阅、访问课程等会有对应的信息展示，带动其他学员"
          ></helpIcon>
        </div>
      </slot>
      <ul class="list">
        <li class="item">
          <el-switch
            :width="34"
            active-value="1"
            inactive-value="2"
            :value="options.is_show_visit_course"
            @change="saveSwitchVal('is_show_visit_course')"
          />
          <div class="text">访问课程</div>
          <helpIcon
            prompt="学员点击直播间推送的课程会显示：***正在报名课程《课程名称》"
          ></helpIcon>
          <img class="img" src="~@ass/img/1.4.2.8/img_fwkcsy.png" alt="" />
        </li>
        <li class="item">
          <el-switch
            :width="34"
            active-value="1"
            inactive-value="2"
            :value="options.is_show_visit_electronics"
            @change="saveSwitchVal('is_show_visit_electronics')"
          />
          <div class="text">访问资料</div>
          <helpIcon
            prompt="学员点击直播间推送的资料会显示：***刚刚领取了资料"
          ></helpIcon>
          <img class="img" src="~@ass/img/1.4.2.8/img_fwzlsy.png" alt="" />
        </li>
        <li class="item">
          <el-switch
            :width="34"
            active-value="1"
            inactive-value="2"
            :value="options.is_show_visit_member"
            @change="saveSwitchVal('is_show_visit_member')"
          />
          <div class="text">访问会员</div>
          <helpIcon
            prompt="学员点击直播间推送的会员会显示：***刚刚获得了会员VIP"
          ></helpIcon>
          <img class="img" src="~@ass/img/1.4.2.8/img_fwhysy.png" alt="" />
        </li>
        <li class="item">
          <el-switch
            :width="34"
            active-value="1"
            inactive-value="2"
            :value="options.is_show_share_live"
            @change="saveSwitchVal('is_show_share_live')"
          />
          <div class="text">分享直播</div>
          <helpIcon
            prompt="学员点击分享按钮会显示：***刚刚分享了直播"
          ></helpIcon>
          <img class="img" src="~@ass/img/1.4.2.8/img_fxzbsy.png" alt="" />
        </li>
        <li class="item">
          <el-switch
            :width="34"
            active-value="1"
            inactive-value="2"
            :value="options.is_show_subscribe_live"
            @change="saveSwitchVal('is_show_subscribe_live')"
          />
          <div class="text">订阅直播</div>
          <helpIcon
            prompt="学员点击订阅按钮会显示：***刚刚订阅了直播间"
          ></helpIcon>
          <img class="img" src="~@ass/img/1.4.2.8/img_dyjbsy.png" alt="" />
        </li>
        <li class="item">
          <el-switch
            :width="34"
            active-value="1"
            inactive-value="2"
            :value="options.is_show_goods_push"
            @change="saveSwitchVal('is_show_goods_push')"
          />
          <div class="text">访问推送的商品</div>
          <helpIcon
            prompt="学员点击直播间上链接的商品和代金券会显示：***正在下单"
          ></helpIcon>
          <img
            class="img"
            style="margin-left: 20px"
            src="~@ass/img/1.4.4.7/img_fwtssp.png"
            alt=""
          />
        </li>
      </ul>
    </el-dialog>

    <!-- 添加微信按钮 -->
    <el-dialog
      class="dialog studentBehavior"
      :visible.sync="visible4"
      :append-to-body="true"
      :close-on-click-modal="false"
      width="800px"
      @close="saveSwitchVal2cancel"
    >
      <slot slot="title">
        <div class="title">
          <div class="text">添加微信按钮</div>
        </div>
      </slot>
      <div class="switch">
        <el-switch
          :value="options.is_show_qrcode"
          @change="saveSwitchVal2('is_show_qrcode')"
          :width="34"
          active-value="1"
          inactive-value="2"
        ></el-switch>
        <span v-if="options.is_show_qrcode == 1" class="sp">已开启</span>
        <span v-else class="sp">已关闭</span>
        <el-popover placement="bottom-end" trigger="hover" width="650">
          <div style="width: 100%">
            <img
              src="~@ass/img/1.4.2.3/img_cksl_ltfl.png"
              style="width: 100%"
              alt=""
            />
          </div>
          <el-button type="text" slot="reference" class="ml20">
            查看示例
          </el-button>
        </el-popover>
      </div>
      <ul class="list2">
        <div class="maskList2" v-if="options.is_show_qrcode == 2"></div>
        <li class="item2">
          <span class="redcolor">*</span>
          <span class="sp2">引导语</span>
          <el-input
            maxlength="30"
            show-word-limit
            v-model="options.qrcode_leading_words"
            placeholder="请输入引导语"
          ></el-input>
        </li>

        <li class="item2 mt30">
          <span class="redcolor">*</span>
          <span class="sp2">二维码</span>
          <div class="img_contain">
            <img v-if="options.qrcode_url" :src="options.qrcode_url" alt="" />
            <span class="fs12" v-else style="color: #666666">未添加图片</span>
          </div>
          <div class="btn">
            <div class="sp3">建议尺寸500*500px，JPG、PNG格式，小于2M</div>
            <el-button style="width: 126px">选择图片</el-button>
            <change-photoimg
              :size="2"
              @complete="completephoto"
              class="changePhotoimg"
            ></change-photoimg>
          </div>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button @click="saveSwitchVal2cancel">取 消</el-button>
        <el-button type="primary" @click="saveSwitchVal2('wx')">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import changePhotoimg from '@cm/base/changePhotoimg'
export default {
  data() {
    return {
      visible1: false,

      visible2: false,

      visible3: false,

      visible4: false,

      options: {},

      oldOptions: {},

      // 预览欢迎语
      viewWelcomeMsg: false,
    }
  },

  components: {
    changePhotoimg,
  },

  created() {
    this.getData()
  },

  methods: {
    async saveSwitchVal2(name) {
      this.post = {
        qrcode_url: this.options.qrcode_url,
        qrcode_leading_words: this.options.qrcode_leading_words,
      }
      if (name == 'wx') {
        if (this.options.is_show_qrcode == 1) {
          if (!this.options.qrcode_leading_words.trim()) {
            this.options.qrcode_leading_words = ''
            this.$root.prompt({
              msg: '请输入引导语',
              type: 'error',
            })
            return
          }

          if (!this.options.qrcode_url) {
            this.$root.prompt({
              msg: '请选择二维码',
              type: 'error',
            })
            return
          }
        }
        this.post = _.assign({}, this.post, {
          is_show_qrcode: this.options.is_show_qrcode,
        })
        await this.editLeading(this.post)
        this.visible4 = false
        this.getData()
      } else {
        // 点击开关不去要请求数据，如果快速却换开关，后台回调会慢，导致错乱
        this.post = _.assign({}, this.post, {
          is_show_qrcode: this.options.is_show_qrcode == 1 ? '2' : '1',
        })
        // 直接修改参数
        this.options.is_show_qrcode =
          this.options.is_show_qrcode == 1 ? '2' : '1'
        // await this.editLeading(post)
      }
    },

    // 微信配置点击取消时，重新获取数据
    saveSwitchVal2cancel() {
      this.visible4 = false
      this.getData()
    },

    async editLeading(post) {
      await this.$http({
        url: '/openClass/editLeading',
        data: _.assign({}, post, {
          open_class_id: this.$route.params.id,
        }),
      })

      this.$root.prompt({
        msg: '操作成功！',
        type: 'success',
      })
    },

    completephoto(val) {
      this.options.qrcode_url = val
    },

    getData() {
      return new Promise((resvlove, reject) => {
        this.$http({
          url: '/openClass/getLiveControl',
          data: {
            open_class_id: this.$route.params.id,
          },
          callback: ({ data }) => {
            this.options = _.assign({}, data)
            this.oldOptions = _.assign({}, data)
            resvlove()
          },
          error: () => {
            reject()
          },
        })
      })
    },

    error() {
      this.cancel()
      this.$root.prompt({
        msg: '保存失败！',
        type: 'error',
      })
    },

    // 编辑数据
    editData(option) {
      return new Promise((resvlove, reject) => {
        this.$http({
          url: '/openClass/editLiveControl',
          isMultipleSelf: 'all',
          data: _.assign({}, option, {
            open_class_id: this.$route.params.id,
          }),
          callback: () => {
            this.getData()
            resvlove()
          },
          error: () => {
            this.error()
            reject()
          },
        })
      })
    },

    toggleVisible(type) {
      this[`visible${type}`] = !this[`visible${type}`]
    },

    // 启用欢迎语
    welcomeOpen() {
      if (this.options.is_show_welcome == 1) {
        this.$confirm('是否停用来宾欢迎？', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          this.editData({ is_show_welcome: 2 })
        })
      } else {
        this.editData({ is_show_welcome: 1 }).then(() => {
          this.$root.prompt({
            msg: '启用成功！',
            type: 'success',
          })
        })
      }
    },

    isEmptyWelcomeText() {
      if (!this.options.welcome_text.trim()) {
        this.$root.prompt({
          msg: '欢迎语不能为空！',
          type: 'error',
        })
        return true
      }
    },

    // 保存欢迎语
    async saveWelcomeText() {
      if (this.isEmptyWelcomeText()) return
      await this.editData({ welcome_text: this.options.welcome_text }).catch(
        () => {
          this.options.welcome_text = this.oldOptions.welcome_text
        }
      )
      this.visible1 = false
    },

    // 欢迎语
    wlMsgClose() {
      this.options.welcome_text = this.oldOptions.welcome_text
    },

    cancel() {
      this.options = _.assign({}, this.oldOptions)
    },

    // 保存
    async saveSwitchVal(name) {
      const val = this.options[name] == 1 ? '2' : '1'
      this.options[name] = val
      await this.editData({ [name]: val })
      this.$root.prompt({
        msg: '操作成功！',
        type: 'success',
      })
    },
  },
}
</script> 
<style lang="scss" scoped>
.page-list {
  .item {
    padding: 1rem 0;
    margin: 0 1rem;
    border-top: 1px solid #eeeeee;
    &:first-child {
      margin-top: -20px;
      border: 0;
    }
    .left {
      display: flex;
      align-items: center;
      position: relative;
      .text1 {
        flex: 1;
        font-size: 14px;
        color: #333333;
        line-height: 14px;
      }
      .text2 {
        font-size: 14px;
        color: #0aa29b;
        line-height: 14px;
        cursor: pointer;
      }
    }
    .right {
      font-size: 12px;
      color: #666666;
      line-height: 12px;
      margin-top: 14px;
    }
  }
}
.dialog {
  ::v-deep .el-dialog {
    height: 494px;
  }
  .switch {
    margin-bottom: 15px;
    .sp {
      margin-left: 16px;
      font-size: 14px;
      color: #333333;
    }
  }
  .title {
    display: flex;
    align-items: center;
    .text {
      font-size: 14px;
      color: #333333;
      margin-right: 10px;
    }
  }
}
.welcomeMessage {
  .content {
    display: flex;
    .left {
      font-size: 14px;
      color: #333333;
      position: relative;
      margin-left: 10px;
      white-space: nowrap;
      &:after {
        content: '*';
        position: absolute;
        left: -10px;
        top: 0;
        color: #ff3535;
      }
    }
    .right {
      margin-left: 113px;
      .textarea {
        width: 595px;
        border-radius: 4px;
      }
      .text {
        margin-top: 20px;
        display: flex;
        align-items: center;
        .t1 {
          font-size: 14px;
          color: #333333;
        }
        .t2 {
          font-size: 14px;
          color: #0aa29b;
          margin-left: 20px;
          cursor: pointer;
        }
      }
      .demo {
        padding: 0 12px;
        opacity: 0.79;
        font-size: 12px;
        font-weight: 500;
        color: #ffffff;
        margin-top: 20px;
        line-height: 26px;
        text-align: center;
        display: inline-block;
        background: #9f4949;
        border-radius: 13px;
      }
    }
  }
  .save-btn {
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    .btn1 {
      width: 74px;
      line-height: 36px;
      text-align: center;
      background: #ffffff;
      border-radius: 4px;
      font-size: 14px;
      color: #666666;
      cursor: pointer;
      border: 1px solid #eaeaea;
    }
    .btn2 {
      width: 104px;
      line-height: 36px;
      text-align: center;
      border-radius: 4px;
      font-size: 14px;
      color: #fff;
      cursor: pointer;
      margin-left: 20px;
      background: #0aa29b;
    }
  }
}

.list2 {
  padding: 30px 20px;
  background-color: #f5f5f5;
  position: relative;
  .maskList2 {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #f8f6f6;
    opacity: 0.85;
    z-index: 10;
    cursor: no-drop;
  }
  .item2 {
    display: flex;
    .sp2 {
      flex-shrink: 0;
      margin-left: 10px;
      margin-right: 48px;
      font-size: 14px;
      color: #333333;
    }
    .img_contain {
      width: 140px;
      height: 140px;
      background: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 100%;
        width: 100%;
      }
    }
    .btn {
      position: relative;
      margin-left: 20px;
      .sp3 {
        font-size: 12px;
        color: #666666;
        margin-bottom: 24px;
      }
      .changePhotoimg {
        height: 40px !important;
        position: absolute;
        top: 36px;
        width: 126px !important;
        overflow: hidden;
      }
    }
  }
}

.bubble .list .item {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  .text {
    margin: 0 48px 0 16px;
  }
}

.studentBehavior .list .item {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  .text {
    margin: 0 10px 0 16px;
  }
  .img {
    margin-left: 62px;
  }
}
</style>
</style>