<template>
  <div ref="map" style="height:292px;"></div>
</template>
<script>
import echarts from 'echarts'
import '@/components/dataAnalysis/china'

export default {
  name: 'chinaMap',

  props: {
    dataList: Array
  },

  watch: {
    dataList: {
      handler(value) {
        if (value.length > 0 && this.myChart) {
          this.myChart.setOption(this.getOptionData(), true, false)
        }
      },
      immediate: true
    }
  },

  mounted() {
    this.init()
  },

  destroyed() {
    this.offResize()
    this.myChart.dispose()
    this.myChart = null
  },

  methods: {
    onResize() {
      this.myChart.resize()
    },

    offResize() {
      window.removeEventListener('resize', this.onResize)
    },

    getOptionData() {
      return {
        tooltip: {
          formatter: function(params) {
            return params.seriesName + '<br />' + params.name + '：' + params.value
          } //数据格式化
        },
        visualMap: {
          min: 0,
          left: 'left',
          top: 'bottom',
          text: ['高', '低'], //取值范围的文字
          inRange: {
            color: ['#fff', '#51638d'] //取值范围的颜色
          },
          show: true //图注
        },
        geo: {
          map: 'china',
          roam: false, //不开启缩放和平移
          zoom: 1.23, //视角缩放比例
          label: {
            normal: {
              show: true,
              fontSize: 8,
              color: '#333',
              offset: [30, 30]
            },
            offset: [30, 30]
          },
          itemStyle: {
            normal: {
              borderColor: 'rgba(0, 0, 0, 0.2)'
            },
            emphasis: {
              areaColor: '#0aa29b', //鼠标选择区域颜色
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              shadowBlur: 20,
              borderWidth: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        },
        series: [
          {
            name: '人次分布',
            type: 'map',
            geoIndex: 0,
            data: this.dataList
          }
        ]
      }
    },

    init() {
      // var dataList = [
      //   { name: "南海诸岛", value: 0 },
      //   { name: "北京", value: this.randomValue() },
      //   { name: "天津", value: this.randomValue() },
      //   { name: "上海", value: this.randomValue() },
      //   { name: "重庆", value: this.randomValue() },
      //   { name: "河北", value: this.randomValue() },
      //   { name: "河南", value: this.randomValue() },
      //   { name: "云南", value: this.randomValue() },
      //   { name: "辽宁", value: this.randomValue() },
      //   { name: "黑龙江", value: this.randomValue() },
      //   { name: "湖南", value: this.randomValue() },
      //   { name: "安徽", value: this.randomValue() },
      //   { name: "山东", value: this.randomValue() },
      //   { name: "新疆", value: this.randomValue() },
      //   { name: "江苏", value: this.randomValue() },
      //   { name: "浙江", value: this.randomValue() },
      //   { name: "江西", value: this.randomValue() },
      //   { name: "湖北", value: this.randomValue() },
      //   { name: "广西", value: this.randomValue() },
      //   { name: "甘肃", value: this.randomValue() },
      //   { name: "山西", value: this.randomValue() },
      //   { name: "内蒙古", value: this.randomValue() },
      //   { name: "陕西", value: this.randomValue() },
      //   { name: "吉林", value: this.randomValue() },
      //   { name: "福建", value: this.randomValue() },
      //   { name: "贵州", value: this.randomValue() },
      //   { name: "广东", value: this.randomValue() },
      //   { name: "青海", value: this.randomValue() },
      //   { name: "西藏", value: this.randomValue() },
      //   { name: "四川", value: this.randomValue() },
      //   { name: "宁夏", value: this.randomValue() },
      //   { name: "海南", value: this.randomValue() },
      //   { name: "台湾", value: this.randomValue() },
      //   { name: "香港", value: this.randomValue() },
      //   { name: "澳门", value: this.randomValue() }
      // ];
      const myChart = (this.myChart = echarts.init(this.$refs.map))

      this.offResize()
      window.addEventListener('resize', this.onResize)

      const option = this.getOptionData()

      myChart.setOption(option)
    }
  }
}
</script>
<style></style>
