<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-03-29 15:10:37
 * @LastEditTime: 2022-06-28 15:49:38
 * @LastEditors: pengmingjun 1317525216@qq.com
 * @Description: 客户关系 =》 变更记录 
 * @FilePath: \ teacher.dingdingkaike\src\views\MarketingCenter\promoter\achievement.vue
-->
<template>
  <div class="transcoddetail">
    <el-table
      empty-text="暂无数据"
      :data="list"
      :header-cell-style="{
        background: 'rgba(245,245,245,1)',
        color: '#333333',
        height: '40px',
      }"
      style="width: 100%"
    >
      <el-table-column prop="transcode_type" label="变更前邀请人" width="250">
        <template slot-scope="{ row }">
          <div v-if="row.change_name" class="flex-center">
            <img class="photo" :src="row.change_photo" alt="" />
            <div class="font1 ml10">
              {{ row.change_name }}
            </div>
          </div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column prop="transcode_type" label="变更后邀请人">
        <template slot-scope="{ row }">
          <div class="flex-center">
            <img class="photo" :src="row.result_photo" alt="" />
            <div class="font1 ml10">
              {{ row.result_name }}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="video_spec" label="变更时间">
        <template slot-scope="{ row }">
          {{ row.c_time | formatTimeStamp('yyyy-MM-dd hh:mm:ss') }}
        </template>
      </el-table-column>
      <el-table-column prop="transcode_type" label="变更操作人">
        <template slot-scope="{ row }">
          <div class="flex-center">
            <img class="photo" :src="row.operator_photo" alt="" />
            <div class="font1 ml10">
              {{ row.operator_name }}
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  name: 'changeRecord',

  props: {
    list: Array,
  },
}
</script>
<style lang="scss" scoped>
.photo {
  width: 22px;
  height: 22px;
  flex-shrink: 0;
  border-radius: 50%;
}
</style>
