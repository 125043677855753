<template>
  <div id="addmember">
    <el-dialog
      title="开通功能"
      class="dialogVisible"
      width="900px"
      :visible="dialogstatus"
      @update:visible="v => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="cancel"
    >
      <div slot="title">
        <div class="title_style">
          <div class="font1">{{ type == 1 ? '添加学员' : '添加用户' }}</div>
          <div class="font2" v-if="type == 1">
            添加学员后，即拥有观看直播权限
          </div>
        </div>
      </div>
      <div class="main">
        <div class="bottom">
          <div class="selectGroup">
            <el-menu
              :default-active="activeIndex"
              class="el-menu-vertical-demo"
            >
              <el-menu-item
                v-for="(item, index) in grouplist"
                :key="item.gid"
                :index="'' + index"
                @click="isSelect(item)"
              >
                <div class="flex-center">
                  <img
                    style="margin-right: 6px"
                    src="../../assets/img/1.4.0/icon_xybs.png"
                    alt=""
                  />
                  <span slot="title" class="font1">
                    {{ item.gname }}({{ item.count }})
                  </span>
                </div>
              </el-menu-item>
            </el-menu>
          </div>
          <div class="tablelist">
            <div class="search">
              <el-select
                v-model="post.filter_type"
                class="mr10"
                style="width: 120px"
                size="medium"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in options2"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <el-input
                size="medium"
                style="width: 198px"
                v-model="post.search"
                placeholder="输入内容搜索"
              ></el-input>
            </div>
            <pagination2
              :option="post"
              url="/courseMember/groupUserList"
              ref="childDialogBox"
              :allFn="true"
            >
              <template v-slot:default="{ tableData }">
                <div class="pagination2">
                  <el-table
                    ref="dialogTable"
                    @row-click="handleRowClick"
                    :row-key="getrowkey"
                    @selection-change="handleSelectionChange"
                    :header-cell-style="{
                      background: 'rgba(245,245,245,1)',
                      color: '#333333',
                    }"
                    :data="tableData"
                    style="width: 100%"
                  >
                    <el-table-column
                      label=""
                      :reserve-selection="true"
                      type="selection"
                      :selectable="selectionEvent"
                    ></el-table-column>
                    <el-table-column
                      label="用户ID"
                      prop="uid"
                    ></el-table-column>
                    <el-table-column label="学员">
                      <template slot-scope="{ row }">
                        <div class="membership">
                          <div class="tu">
                            <img
                              style="width: 22px; height: 22px"
                              :src="row.uphoto"
                              alt=""
                            />
                          </div>
                          <div class="right">{{ row.uname }}</div>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column label="真实姓名">
                      <template slot-scope="{ row }">
                        <span v-if="row.student_remarks">
                          {{ row.student_remarks }}
                        </span>
                        <span v-else>-</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="手机号码">
                      <template slot-scope="{ row }">
                        <span v-if="row.umobile">
                          {{ row.umobile }}
                        </span>
                        <span v-else>-</span>
                      </template>
                    </el-table-column>

                    <el-table-column
                      label="注册时间"
                      prop="address"
                      min-width="90px"
                    >
                      <template slot-scope="{ row }">
                        {{ row.regdate | formatTimeStamp('yyyy-MM-dd hh:mm') }}
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </template>
            </pagination2>
          </div>
        </div>

        <div class="btns">
          <el-button type="" size="medium" style="width: 86px" @click="cancel">
            取消
          </el-button>

          <el-button
            type="primary"
            size="medium"
            @click="submit"
            v-if="type == 1"
          >
            确定( {{ listSelect.length > 0 ? listSelect.length : 0 }} )
          </el-button>

          <el-button v-else type="primary" size="medium" @click="submit">
            确定( {{ listSelect.length + ids.length }}
            <span v-if="slimt">/{{ slimt }}</span>
            )
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'addmember',
  data() {
    return {
      post: {
        gid: 0,
        search: '',
        filter_type: 2,
        open_class_id: this.type == 1 ? this.$route.params.id : undefined,
        ids: this.ids,
      },
      listSelect: [], //右侧列表选中的数据
      activeIndex: '0', //左侧默认选中
      grouplist: [], //左侧列表
      //控制弹窗的显示
      dialogVisible: false,

      options2: [
        {
          value: 1,
          label: '用户ID',
        },
        {
          value: 2,
          label: '学员昵称',
        },
        {
          value: 3,
          label: '真实姓名',
        },
        {
          value: 4,
          label: '手机号码',
        },
      ],
    }
  },

  props: {
    // 限制个数
    slimt: {
      type: Number,
      default: undefined,
    },

    // 1公开课的学员选择 2，优惠券的学员选择 -- 没有红色提示
    type: {
      type: Number,
      default: 1,
    },

    // 已选择的数据的id集合
    ids: {
      type: Array,
      default: () => [],
    },

    dialogstatus: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.studentGroup()
  },

  methods: {
    selectionEvent(row) {
      if (this.type == 1) {
        if (row.is_select) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },

    getrowkey(row) {
      if (row.uid) {
        return row.uid
      }
    },

    handleRowClick(row) {
      if (this.type == 1 && row.is_select) {
        this.$refs.dialogTable.toggleRowSelection(row)
      }
      if (this.type == 2) {
        this.$refs.dialogTable.toggleRowSelection(row)
      }
    },

    submit() {
      if (this.listSelect.length + this.ids.length > this.slimt) {
        this.$root.prompt(`添加的用户不能超过${this.slimt}个`)
        return
      }
      this.$emit('complete', this.listSelect)
      this.cancel()
    },

    //选各种的数据
    handleSelectionChange(val) {
      this.listSelect = val
    },

    //选中左侧分组
    isSelect(item) {
      this.post.gid = item.gid
    },

    //左侧列表的获取
    studentGroup() {
      this.$http({
        url: '/courseMember/studentGroup',
        data: {
          ids: this.ids,
        },
        callback: ({ data }) => {
          this.grouplist = data
        },
      })
    },
    cancel() {
      // 取消后回复选择数据原样
      this.$emit('update:dialogstatus', false)
    },
  },
}
</script>
<style lang="scss" scoped>
.el-menu-item.is-active {
  background-color: #ecf5ff;
}
#addmember {
  .pagination2 {
    height: 248px;
    overflow-y: auto;
    @extend %scroll;
  }
  .tablelist {
    .membership {
      display: flex;
      align-items: center;
      .tu {
        width: 22px;
        height: 22px;
        border-radius: 2px;
        margin-right: 10px;
      }
      .right {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 14px;
        color: #333333;
        line-height: 14px;
      }
    }
  }

  ::v-deep .el-table__header-wrapper {
    height: 42px;
  }
  ::v-deep .el-dialog__body {
    padding: 0;
  }
  ::v-deep .el-dialog {
    height: 518px;
  }
  .title_style {
    display: flex;
    align-items: center;
    .font1 {
      font-size: 14px;
      color: #333333;
      line-height: 14px;
    }
    .font2 {
      font-size: 12px;
      margin-left: 10px;
      color: #ff3535;
      line-height: 16px;
    }
  }
  .main {
    padding: 20px;
    .header {
      display: flex;
      align-items: center;
      .guige {
        margin-right: 30px;
        font-size: 14px;
        color: #333333;
        line-height: 14px;
      }
    }
    .bottom {
      display: flex;
      margin-top: 20px;
      .selectGroup {
        height: 334px;
        width: 214px;

        ::v-deep .el-menu {
          height: 334px;
          overflow-y: auto;
          overflow-x: hidden;
          border: solid 1px #e6e6e6;
          @extend %scroll;
        }
        ::v-deep .el-menu-item:hover,
        .el-menu-item:focus {
          background-color: #f0f8ff;
        }
        ::v-deep .el-menu-item {
          height: 36px;
          line-height: 36px;
        }
        .font1 {
          font-size: 12px;
          color: #333333;
        }
      }
      .tablelist {
        width: calc(100% - 200px);
        .search {
          margin-bottom: 10px;
          width: 100%;
          display: flex;
          justify-content: flex-end;
        }
      }
      .pagenation {
        margin-top: 20px;
        text-align: center;
      }
    }
    .btns {
      text-align: right;
      margin-top: 10px;
    }
  }
}
</style>
