<template>
  <div id="subscriptionManagement">
    <subscription @subscriptionUpdate="subscriptionUpdate" :open_class_id="Number($route.params.id)"></subscription>
  </div>
</template>
<script>
import subscription from '@/components/course/subscription'
export default {
  name: 'subscriptionManagement',

  components: { subscription },
  
  data() {
    return {
      post: {},

      input: '',

      options: [
        {
          value: 0,
          label: '全部',
        },
        {
          value: 1,
          label: '免费',
        },
        {
          value: 2,
          label: '付费',
        },
        {
          value: 3,
          label: '密码',
        },
      ],
      
      value: 0,
    }
  },
  methods: {
    //取消订阅的回调
    subscriptionUpdate() {},
  },
}
</script>
;<style lang="scss" scoped>
#subscriptionManagement {
}
</style>
