<template>
  <div id="paybackRecord">
    <div class="search flex-center">
      <playback-footage
        :dialogstatus.sync="playbackFootagestatus"
        ref="playbackFootage"
        @complete="complete"
        @uploadConfirmClose="close"
        @close="close"
      >
        <el-button
          type="primary"
          @click="playbackFootagestatus = !playbackFootagestatus"
        >
          添加回放片段
        </el-button>
      </playback-footage>
      <div flex-center>
        <el-button
          type="text"
          class="mr30"
          @click="$router.push({ path: '/library/playback' })"
        >
          前往素材库
        </el-button>

        <el-date-picker
          v-model="value1"
          type="daterange"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </div>
    </div>
    <pagination2
      :option="post"
      :hadPagination="true"
      ref="dialogTable"
      url="/openClass/playbackList"
      @complete="getTableData"
    >
      <template v-slot:default="{ tableData }">
        <el-table
          ref="tablelist"
          @sort-change="sortChange"
          @selection-change="handleSelectionChange"
          @select-all="selectAll"
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column label="" type="selection"></el-table-column>

          <el-table-column label="回放视频">
            <template slot-scope="{ row }">
              <div class="membership">
                <div class="tu">
                  <img
                    :src="
                      row.video_img
                        ? row.video_img
                        : require('@ass/img/1.3.9/icon_spbsR.png')
                    "
                    alt=""
                  />
                  <div class="tip" v-if="row.zm_spec != 0">
                    {{ row.zm_spec | getzm_spec }}
                  </div>
                  <div class="time">{{ row.duration | formatTimeLength }}</div>
                </div>
                <div class="right" :title="row.file_name">
                  {{ row.file_name }}
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="直播时间" prop="start_time">
            <template slot-scope="{ row }">
              <template v-if="row.start_time > 0">
                {{ row.start_time | formatTimeStamp }}
              </template>
              <template v-else>- -</template>
            </template>
          </el-table-column>

          <el-table-column label="生成时间" prop="ctime">
            <template slot-scope="{ row }">
              {{ row.ctime | formatTimeStamp }}
            </template>
          </el-table-column>

          <el-table-column label="类型" prop="upload_type">
            <template slot-scope="{ row }">
              {{ row.upload_type == 1 ? '系统生成' : '手动上传' }}
            </template>
          </el-table-column>

          <el-table-column label="回放有效期" prop="playback_end_time">
            <template slot-scope="{ row }">
              <span v-if="row.type == 1">
                永久
              </span>
              <span v-else>
                {{ row.playback_end_time | formatTimeStamp('yyyy-MM-dd') }}
              </span>
            </template>
          </el-table-column>

          <el-table-column
            label="操作"
            align="right"
            prop="address"
            width="300px"
          >
            <template slot-scope="{ row, $index }">
              <el-button type="text" @click="settingTimeclick(row)">
                设置
              </el-button>
              <span class="sp_drive">|</span>
              <el-button
                type="text"
                @click="
                  cilckPreViewVideo(
                    row.is_video_encryption,
                    row.zmvidoe_url || row.video_url,
                    row.transcode_status
                  )
                "
              >
                预览
              </el-button>
              <span class="sp_drive">|</span>
              <el-button
                type="text"
                @click="upDown(row, 1)"
                :disabled="$index == 0"
              >
                上移
              </el-button>
              <span class="sp_drive">|</span>
              <el-button
                type="text"
                @click="upDown(row, 2)"
                :disabled="$index == listData.length - 1"
              >
                下移
              </el-button>
              <span class="sp_drive">|</span>
              <el-dropdown>
                <el-button type="text">更多</el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <p @click="renameVideo(row.recording_id, row.file_name)">
                      重命名
                    </p>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <p @click="delVideo(row.recording_id)">删除</p>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template #footer>
        <div class="footer">
          <el-checkbox
            :indeterminate="isIndeterminate"
            v-model="checked"
            @change="boxChange($event)"
          ></el-checkbox>
          <span class="ml10">已选择{{ videoIdArr.length }}条</span>
          <el-button
            style="width: 130px"
            class="ml20"
            size="medium"
            @click="dels"
            :disabled="videoIdArr.length == 0"
          >
            批量删除
          </el-button>
        </div>
      </template>
    </pagination2>
    <previewVideo
      v-if="previewSrc"
      :src="previewSrc"
      @close="preViewVideo"
    ></previewVideo>

    <el-dialog
      title="文件重命名"
      :visible.sync="renameVideodialogVisible"
      width="522px"
      :close-on-click-modal="false"
    >
      <el-input
        type="text"
        placeholder="输入新的文件名"
        v-model="renameVideotext"
        maxlength="50"
        show-word-limit
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click.stop="renameVideoCancel">取 消</el-button>
        <el-button type="primary" @click.stop="renameVideoFix">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 设置时间 -->
    <el-dialog
      title="设置"
      :visible.sync="settingTime"
      width="452px"
      v-if="settingTime"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form"
        :model="form"
        label-width="100px"
        label-position="left"
      >
        <el-form-item label="回放有效期">
          <el-radio-group v-model="form.type">
            <div style="margin-top:13px">
              <el-radio :label="1">永久</el-radio>
            </div>
            <div class="mt30 ">
              <div class="flex-center">
                <el-radio :label="2">限时</el-radio>
                <el-date-picker
                  v-if="form.type == 2"
                  v-model="form.playback_end_time"
                  type="date"
                  placeholder="选择日期"
                ></el-date-picker>
              </div>
            </div>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div class="tips">
        到期后，学员无法观看回放内容，但仍可以进入直播间查看聊天内容
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="settingTime = false">取 消</el-button>
        <el-button type="primary" @click="timeSubmit">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import previewVideo from '@cm/coursePop/previewVideo'
import playbackFootage from '@cm/playbackFootage'

export default {
  name: 'paybackRecord',

  data() {
    return {
      form: {
        recording_id: '',
        type: 1,
        playback_end_time: '',
      },

      settingTime: false,

      videoIdArr: [],

      checked: false,
      isIndeterminate: false,

      selectedNum: 0,

      post: {
        open_class_id: this.$route.params.id,
        start_time: '',
        end_time: '',
        order: '',
        sort: '',
      },

      value1: '',

      previewSrc: '',

      listData: [],

      playbackFootagestatus: false,
      renameVideoId: '',
      renameVideotext: '',
      renameVideodialogVisible: false,
    }
  },

  components: {
    previewVideo,
    playbackFootage,
  },

  filters: {
    getzm_spec(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '720P'
          break
        case 2:
          text = '1080P '
          break
        case 3:
          text = '360P'
          break
        case 4:
          text = '480P'
          break
      }
      return text
    },
  },

  watch: {
    value1: {
      handler(val) {
        if (val) {
          this.post.start_time = val[0] / 1000
          this.post.end_time = val[1] / 1000
        } else {
          this.post.start_time = ''
          this.post.end_time = ''
        }
      },
      deep: true,
      immediate: true,
    },

    selectedNum(val) {
      if (val > 0) {
        if (val == this.listData.length) {
          this.checked = true
          this.isIndeterminate = false
        } else if (val < this.listData.length) {
          this.isIndeterminate = true
          this.checked = false
        }
      } else {
        this.isIndeterminate = false
        this.checked = false
      }
    },
  },

  methods: {
    async timeSubmit() {
      await this.$http({
        url: '/DataBank/setPlayBackEndTime',
        data: _.assign({}, this.form, {
          playback_end_time: this.form.playback_end_time / 1000,
        }),
        successMsg: true,
      })
      this.settingTime = false
      this.$refs.dialogTable.reset()
    },

    settingTimeclick(val) {
      this.form = _.assign({}, val, {
        playback_end_time: val.playback_end_time
          ? val.playback_end_time * 1000
          : '',
      })
      this.settingTime = true
    },
    // listData

    // 全选状态
    selectAll(val) {
      if (val.length == 0) {
        this.checked = false
      } else {
        this.checked = true
      }
    },

    // 列表下边的多选
    boxChange(val) {
      if (val) {
        this.$refs.tablelist.toggleAllSelection()
      } else {
        this.$refs.tablelist.clearSelection()
      }
    },

    handleSelectionChange(val) {
      this.videoIdArr = val.map(item => item.recording_id)
      this.selectedNum = val.length
    },

    // 获取数据
    getTableData({ data }) {
      this.listData = data
    },

    //删除视频单个
    delVideo(id) {
      this.$confirm('是否删除已选文件，删除后可在回收站中查看！', '删除文件', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$http({
          url: '/DataBank/deleteRecording',
          data: {
            ids: id,
            type: 2,
          },
          callback: () => {
            this.$root.prompt({
              msg: '删除成功',
              type: 'success',
            })
            this.$refs.dialogTable.reset()
          },
          error: ({ info }) => {
            this.$root.prompt(info)
          },
        })
      })
    },

    // 上下移动
    async upDown({ recording_id }, sort) {
      await this.$http({
        url: '/DataBank/playbackSort',
        data: {
          recording_id,
          sort,
        },
      })
      this.$refs.dialogTable.reset()
    },

    //批量删除
    dels() {
      this.delVideo(this.videoIdArr)
    },

    // 预览视频
    preViewVideo(src) {
      this.previewSrc = typeof src === 'string' ? src : ''
    },

    //判断点击播放是否是加密视频,是则弹出错误
    cilckPreViewVideo(isJm, url, transcode_status) {
      //判断是否时加密中或者是转码中
      if (isJm == 3 || transcode_status == 3) {
        this.$root.prompt({
          msg: '视频正在处理，请稍后再试！',
        })
        return
      }
      if (isJm == 1) {
        this.$root.prompt('视频已加密,不支持直接在线播放')
      } else {
        this.preViewVideo(url)
      }
    },

    // 关闭回放弹窗
    close() {
      this.playbackFootagestatus = false
      this.$refs.dialogTable.reset()
    },

  

    // 添加回放
    async complete(val) {
      await this.$http({
        url: '/DataBank/playbackCopy',
        data: {
          open_class_id: this.post.open_class_id,
          video_ids: val
            .filter(item => !!item.video_id)
            .map(item => item.video_id),
          recording_ids: val
            .filter(item => !!item.recording_id)
            .map(item => item.recording_id),
        },
      })
      this.$root.prompt({
        type: 'success',
        msg: '操作成功',
      })
      this.$refs.dialogTable.reset()
    },

    sortChange(value) {
      this.post.sort = !value.order ? '' : value.order === 'ascending' ? 1 : 2
      this.post.order = !value.order ? '' : value.prop == 'start_time' ? 1 : 2
    },

    //重命名视频
    renameVideo(id, name) {
      this.renameVideodialogVisible = true
      this.renameVideoId = id
      this.renameVideotext = name
    },

    renameVideoCancel() {
      this.renameVideodialogVisible = false
      this.renameVideotext = ''
    },

    trimStr(str) {
      return str.replace(/(^\s*)|(\s*$)/g, '')
    },

    renameVideoFix() {
      this.renameVideotext = this.trimStr(this.renameVideotext)
      var regTextChar = new RegExp('[\\\\*:<>/|?""]')
      if (
        regTextChar.test(this.renameVideotext) ||
        this.renameVideotext == ''
      ) {
        this.$root.prompt('文件名不能为空且不能包含特殊符号：\\ /:*?"<>|')
      } else {
        this.$http({
          url: '/DataBank/setFileName',
          data: {
            type: 6,
            id: this.renameVideoId,
            name: this.renameVideotext,
          },
          callback: ({ code }) => {
            if (code == 200) {
              this.$refs.dialogTable.reset()
              this.renameVideodialogVisible = false
              this.renameVideotext = ''
            }
          },
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
#paybackRecord {
  .tips {
    font-size: 12px;
    font-weight: 400;
    color: #6a6a6a;
    line-height: 17px;

    margin-left: 100px;
    transform: translateY(-12px);
  }
  .search {
    margin-bottom: 15px;
    justify-content: space-between;
  }
  .membership {
    display: flex;
    align-items: center;

    .tu {
      position: relative;
      margin-right: 10px;
      width: 106px;
      height: 60px;
      img {
        height: 100%;
        width: 100%;
      }
      .tip {
        font-size: 12px;
        color: #ffffff;
        text-align: center;
        line-height: 18px;
        position: absolute;
        left: 0;
        top: 0;
        width: 38px;
        height: 18px;
        background: #fa6400;
        border-radius: 0px 0px 11px 0px;
      }
      .time {
        text-align: center;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 1px 0;
        background: rgba(0, 0, 0, 0.5);
        font-size: 12px;
        color: #ffffff;
        line-height: 12px;
      }
    }
    .right {
      width: calc(100% - 116px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .sp_drive {
    color: #1b9d97;
    margin: 0 10px;
  }
  .footer {
    margin-top: 30px;
    span {
      font-size: 14px;
      color: #333333;
      line-height: 19px;
    }
  }
}
</style>
