<template>
  <div style="min-height: 900px">
    <el-radio-group
      v-model="selectModule"
      class="selectModule"
      @change="moduleChange"
    >
      <el-radio-button :label="1">菜单导航</el-radio-button>
      <el-radio-button :label="2">快捷栏</el-radio-button>
      <el-radio-button :label="3">直播间皮肤</el-radio-button>
    </el-radio-group>

    <div v-show="mid == 1" class="customMenu">
      <leftComponent
        ref="leftComponent"
        :selectId="selectId"
        :menuList.sync="menuList"
        @selectMenu="selectMenu"
      ></leftComponent>
      <rightComponent
        ref="rightComponent"
        :value="selectValue"
        @removeModule="removeModule"
        
        :selectId="selectId"
        :menuList.sync="menuList"
        @selectMenu="selectMenu"
        v-if="selectValue"
      ></rightComponent>
      <div class="save-btn">
        <div class="ps">温馨提示：每次编辑后记得点保存并发布哦~</div>
        <div class="btns">
          <el-popover
            placement="top"
            class="vm view"
            trigger="hover"
            popper-class="customMenu-right-live-room-qrcode"
          >
            <div class="vm mid">
              <div class="qrcode customMenu-qrcode"></div>
              <div class="text">手机微信扫码预览</div>
            </div>
            <el-button slot="reference" class="btn1">预览</el-button>
          </el-popover>
          <el-button class="vm btn2" @click="save">保存并发布</el-button>
        </div>
      </div>
    </div>

    <div v-show="mid == 2">
      <shortcutBar></shortcutBar>
    </div>

    <div v-show="mid == 3">
      <div class="contain">
        <div class="item">
          <el-popover placement="top" trigger="hover" :open-delay="1000">
            <img
              style="width:160px;height:auto"
              src="~@ass/img/1.4.30/16733440314059.png"
              alt=""
            />
            <div
              slot="reference"
              class="tu"
              :class="selectpic == 1 ? 'active' : ''"
              @click="slectItem(1)"
            >
              <div class="tips" v-if="selectpic == 1">当前使用</div>
            </div>
          </el-popover>

          <div class="name">默认</div>
        </div>
        <div class="item ml20">
          <el-popover placement="bottom" trigger="hover" :open-delay="1000">
            <img
              style="width:160px;height:auto"
              src="~@ass/img/1.4.30/16733440535420.png"
              alt=""
            />
            <div
              slot="reference"
              class="tu2"
              :class="selectpic == 2 ? 'active' : ''"
              @click="slectItem(2)"
            >
              <div class="tips" v-if="selectpic == 2">当前使用</div>
              <img src="~@ass/img/1.4.30/16733219156914.png" alt="" />
            </div>
          </el-popover>

          <div class="name">兔年中国红</div>
        </div>
        <div class="item ml20">
          <el-popover
            v-if="customImg || skin_url"
            placement="bottom"
            trigger="hover"
            :open-delay="1000"
            title="推荐尺寸：1080x1920"
          >
            <img
              style="width:160px;height:auto"
              :src="customImg || skin_url"
              alt=""
            />
            <div
              slot="reference"
              class="tu2"
              :class="selectpic == 3 ? 'active' : ''"
              @click="slectItem(3)"
            >
              <div class="tips" v-if="selectpic == 3">当前使用</div>
              <img :src="customImg || skin_url" alt="" />
            </div>
          </el-popover>
          <div v-else class="empty" title="推荐尺寸：1080x1920">
            <div class="fs14 mt20">推荐尺寸：1080x1920</div>
          </div>

          <changePhotoimg
            :size="5"
            @complete="complete"
            class="changePhotoimg"
          ></changePhotoimg>
          <el-button
            size="medium"
            class="mt10 "
            style="margin-left: 50%;transform: translateX(-50%);"
          >
            自定义上传
          </el-button>
          <!-- <div class="name">点击上传</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import changePhotoimg from '@cm/base/changePhotoimg'
import QRCode from '@/assets/js/qrcode'
import leftComponent from '../components/customMenu-left'
import rightComponent from '../components/customMenu-right'
import shortcutBar from '../components/shortcutBar'

export default {
  name: 'customMenu',

  components: {
    leftComponent,
    rightComponent,
    shortcutBar,
    changePhotoimg,
  },

  props: {
    liveRoomUrl: String,
  },

  data() {
    return {
      customImg: '',
      skin_url: '',
      selectpic: 1,
      mid: 1,
      selectModule: 1,
      selectId: '',
      menuList: [],
    }
  },

  watch: {
    // 切换的时候 重新请求数据
    mid: {
      handler(val) {
        if (val == 1) {
          this.getMenuList()
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.getSkin()
    // 预览二维码
    this.createViewQrcode()
  },

  computed: {
    openClassId() {
      return this.$route.params.id
    },

    selectValue() {
      return this.menuList.find(item => item.id == this.selectId)
    },
  },

  methods: {
    async complete(val) {
      this.customImg = val
      await this.$http({
        url: 'liveMenu/saveSkinUrl',
        data: {
          open_class_id: this.openClassId,
          url: val,
        },
        successMsg: true,
      })
    },

    async getSkin() {
      const {
        data: { skin, skin_url },
      } = await this.$http({
        url: '/liveMenu/getSkin',
        data: {
          open_class_id: this.openClassId,
        },
      })
      this.selectpic = skin
      this.skin_url = skin_url
    },

    async slectItem(val) {
      await this.$http({
        url: '/liveMenu/saveSkin',
        data: {
          open_class_id: this.openClassId,
          skin: val,
        },
        successMsg: true,
      })
      this.getSkin()
    },

    selectMenu(id) {
      this.selectId = id
    },

    getMenuList() {
      return new Promise(resolve => {
        this.$root.$http({
          url: '/liveMenu/menuList',
          data: {
            open_class_id: this.$route.params.id,
          },
          callback: ({ data }) => {
            this.menuList = data.map(item =>
              Object.assign({}, item, {
                id: item.live_menu_id,
                isSys: item.is_default == 1,
                advertInfo: item.advertInfo || [],
                type: Number(item.type),
                is_confirm: Number(item.is_confirm),
                is_inside_open: Number(item.is_inside_open),
              })
            )
            // 备份旧数据
            this.oldMenuList = _.merge([], this.menuList)
            this.selectId = this.menuList[0] ? this.menuList[0].id : ''

            resolve()
          },
        })
      })
    },

    createViewQrcode() {
      new QRCode(document.querySelector('.customMenu-qrcode'), {
        text: this.liveRoomUrl,
        width: 156,
        height: 156,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H,
      })
    },

    // 模块切换
    moduleChange(val) {
      if (val == 2 && !_.isEqual(this.menuList, this.oldMenuList)) {
        this.$confirm('当前内容未保存，现在离开数据将会丢失', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        })
          .then(() => {
            this.mid = this.selectModule = 2
          })
          .catch(() => {
            this.mid = this.selectModule = 1
          })
      } else {
        this.mid = this.selectModule = val
      }

      if (val == 3) {
        this.getSkin()
      }
    },

    // 获取模块index
    getModuleIndex(id) {
      return this.menuList.findIndex(item => item.id == id)
    },

    // 删除模块
    removeModule(id) {
      const index = this.getModuleIndex(id)
      if (index > -1) {
        this.menuList.splice(index, 1)
      }
      this.$refs.leftComponent.changeisadd()
      this.$refs.rightComponent.changeisadd()
      this.selectMenu(this.menuList.filter(v=>v.type==1)[0].id)
    },

    // 保存
    save() {
      // 请先填写完整内容！
      let errItem
      if (
        (errItem = this.menuList.find(
          item => !item.name || (item.type == 6 && !item.link_url)
        ))
      ) {
        this.$root.prompt('请先填写完整内容！')
        this.selectId = errItem.id
        console.log('保存 error', errItem)
      } else {
        this.$http({
          url: '/liveMenu/saveMenu',
          data: {
            open_class_id: this.$route.params.id,
            list: this.menuList,
          },
          callback: () => {
            this.$root.prompt({
              msg: '操作成功',
              type: 'success',
            })
            const oldSelectIndex = this.menuList.findIndex(
              item => item.id == this.selectId
            )
            this.getMenuList().then(() => {
              if (oldSelectIndex > -1) {
                const val = this.menuList[oldSelectIndex]
                this.selectId = val ? val.id : ''
              }
            })
          },
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.contain {
  display: flex;
  align-items: center;
  .item {
    width: 150px;
    position: relative;
    height: 200px;
    .empty {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 2px solid #f5f5f5;
      border-radius: 10px;
    }
    ::v-deep .changePhotoimg {
      width: 150px !important;
      position: absolute;
      bottom: -50px;
      left: 1px;
      z-index: 1;
      // background-color: red;
      height: 40px !important;
    }
    .tu,
    .tu2 {
      cursor: pointer;
      border-radius: 10px;
      width: 100%;
      height: 200px;
      background-color: white;
      position: relative;
      border: 2px solid #f5f5f5;
      img {
        width: 100%;
        height: 100%;
      }
      &.active {
        border: 2px solid #186ee5;
      }
      .tips {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        position: absolute;
        width: 70px;
        text-align: center;
        background-color: #186ee5;
        color: white;
        font-size: 14px;
        line-height: 1.5;
        top: 10px;
        right: 0;
      }
    }
    .tu2 {
      // background-color: red;
    }
    .name {
      font-size: 14px;
      text-align: center;
      margin-top: 20px;
    }
  }
}
.customMenu {
  display: flex;
  padding-bottom: 10px;
}
::v-deep .selectModule {
  margin-bottom: 20px;
  .el-radio-button__inner {
    width: 140px;
  }
}
.save-btn {
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 1;
  bottom: 0;
  right: 0;
  left: $menuWidth;
  height: 60px;
  background: #ffffff;
  box-shadow: 0px -1px 4px 0px #0000001c;
  .ps {
    font-size: 12px;
    color: #ff3535;
    margin: 0 48px 0 20px;
  }
  .btns {
    flex: 1;
    font-size: 0;
    margin-left: -284px;
    text-align: center;
  }
  ::v-deep button {
    width: 150px;
    height: 40px;
    border-radius: 2px;
    font-size: 14px;
  }
  ::v-deep .view {
    font-size: 14px;
    color: #0aa29b;
    margin-left: 20px;
    cursor: pointer;
  }
  ::v-deep .btn1 {
    margin-right: 20px;
    border-color: #0aa29b;
    color: #0aa29b;
  }
  ::v-deep .btn2 {
    background: #0aa29b;
    color: #ffffff;
  }
}
</style>
