<template>
  <div id="tip">
    <el-dialog
      title="温馨提示"
      class="dialogVisible"
      width="422px"
      :visible="dialogstatus"
      @update:visible="(v) => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="cancel"
    >
      <div class="dialogVisible_bottom">{{ list.contain }}</div>
      <span slot="footer" class="dialog-footer">
        <span v-if="list.type == 1">
          <el-button size="medium" @click="cancel">取 消</el-button>
          <el-button size="medium" type="primary" @click="sure">
            确 定
          </el-button>
        </span>
        <el-button
          size="medium"
          v-if="list.type == 2"
          type="primary"
          @click="sure"
        >
          知道了
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'openingfunction',

  data() {
    return {}
  },

  props: {
    list: {
      type: Object,
      default: () => {},
    },
    dialogstatus: {
      type: Boolean,
      default: false,
    },
  },
   
  methods: {
    //确定
    sure() {
      this.$emit('sure')
    },
    
    cancel() {
      // 取消后回复选择数据原样
      this.$emit('update:dialogstatus', false)
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding-top: 0 !important;
}
::v-deep .el-button {
  width: 74px;
}
#tip {
  .dialogVisible_bottom {
    font-size: 14px;
    color: #666666;
    line-height: 22px;
  }
}
</style>
