<template>
  <div class="uploadRecordCourse">
    <slot
      :queue="newQueue"
      name="default"
      :removeFile="removeFile"
      :againUpload="againUpload"
    ></slot>
    <div class="vm uploadBtn" v-if="selectButtonStatus">
      <slot
        :uploadingstatus="uploadingstatus"
        :uploading="uploading"
        :queue="newQueue"
        name="uploadBtn"
        :removeFile="removeFile"
        :againUpload="againUpload"
      ></slot>
      <input
        ref="Upload_file"
        class="absolute"
        id="Upload_file"
        type="file"
        :accept="accept"
        @change="addFile"
        :multiple="typeselection == 1 ? true : false"
        />
    </div>
    <!-- 录播本地添加视频的按钮位置 -->
    <slot name="editsection_bt"></slot>

    <slot
      name="select"
      :uploadCompleteList="uploadCompleteList"
      :uploadError="uploadError"
    ></slot>
    <slot
      name="list"
      :uploadingstatus="uploadingstatus"
      :newQueue="newQueue"
      :selectButtonStatus="selectButtonStatus"
      :queue="newQueue"
      :removeFile="removeFile"
      :againUpload="againUpload"
    ></slot>
    <slot
      name="footer"
      :newQueue="newQueue"
      :beginUploadStatus="beginUploadStatus"
      :uploadingstatus="uploadingstatus"
    ></slot>
  </div>
</template>
<script>
import { getFileInfo } from '../../utils/index'
import { from } from 'rxjs';
import { mergeMap, } from 'rxjs/operators';
/**
 * // 点击第一层开始上传按钮 最后一个文件上传时中途取消,并且列表中没有上传成功的 在第二层
   // 初始化的时候 以及 第三层 继续上传的时候
   // 所有文件上传完毕，包括成功与失败，没有上传中的
 */
import TcVod from 'vod-js-sdk-v6'
// 修改了源码
// var opt = {
//     method: method,
//     url: url,
//     headers: params.headers,
//     qs: params.qs,
//     body: body,
//     json: json,
//     timeout: 30000
// }
// cos-js-sdk-v5 E:\htdocs\teacher.dingdingkaike\node_modules\cos-js-sdk-v5\src\base.js _submitRequest()

export default {
  name: 'Upload',

  props: {
    //1资料库视频上传，可多选 2，是录播课本地添加视频，只能添加单个视频
    typeselection: {
      type: Number,
      default: 1,
    },
    // 上传数量限制
    limit: {
      type: Number,
      default: 0,
    },
    // 同时上传数量
    meanwhile: {
      type: Number,
      default: 1,
    },
    accept: {
      type: String,
      default: '',
    },
    isUploadServe: {
      type: Boolean,
      default: true,
    },
    maxSize: {
      type: Number,

      default: 1024 * 1024 * 1024,
    },
    //分组id
    list: {
      default: 0,
    },
    //分辨率的数值
    value: {
      type: Number,
      default: 0,
    },
    //1是加密转码 2是普通转码
    radio: {
      type: Number,
      default: 2,
    },
    menuName: String,
  },

  data() {
    return {
      //上传按钮的状态
      selectFileButton: true,
      //开始上传按钮的显示状态
      beginUploadStatus: true,
      token: '',
      // 所有文件
      fs: {},
      // 上传队列
      queue: [],
      // 下一步需要上传的index
      nextIndex: undefined,
    }
  },

  destroyed() {
    this.destroyed11()
  },

  created() {
    this.tcVod = new TcVod({
      getSignature: () => {
        return this.getToken()
      },
    })
  },

  computed: {
    // 当前正在上传中的文件
    currUploadingFile() {
      return this.newQueue
        .filter((item) => item.status === 2)
        .map((item) => item.fid)
    },
    //正在上传中的状态
    uploadingstatus() {
      return (
        !this.beginUploadStatus &&
        (this.uploading ||
          (this.uploadCompleteList.length == 0 && this.uploadError.length == 0))
      )
    },

    //选择文件按钮的状态判断
    selectButtonStatus() {
      return this.newQueue.length == 0 || this.uploading
    },

    //当前待上传和上传中的文件
    uploading() {
      return (
        this.newQueue.filter((item) => item.status === 2 || item.status === 1)
          .length > 0
      )
    },

    //上传成功的文件
    uploadCompleteList() {
      return this.newQueue.filter((item) => item.status === 0)
    },

    // 上传失败的文件
    uploadError() {
      return this.newQueue.filter((item) => item.status === 3)
    },

    // 最终的队列值
    newQueue() {
      return this.queue
        .filter((item) => item.status != -1)
        .map((m) => {
          let array = _.assign({}, m, {
            file_name: m.file_name.substring(m.file_name.length - 50),
          })

          return array
        })
    },
  },

  methods: {
    //继续上传前的数据销毁
    destroyed11() {
      this.beginUploadStatus = true
      this.queue.forEach(this.stopUpload)
      this.token = ''
      // 所有文件
      this.fs = {}
      // 上传队列
      this.queue = []
      // 下一步需要上传的index
      this.nextIndex = undefined
    },
    continueupload() {
      this.destroyed11()
      this.$emit('continueupload', true)
    },
    // 获取腾讯上传token
    getToken() {
      return new Promise((resolve) => {
        this.$root.$http({
          name: 'getTxToken',
          url: '/index/getTxToken',
          isMultipleSelf: 'all',
          callback: ({ data }) => {
            this.token = data
            resolve(data)
          },
          error: () => {
            this.$root.prompt('获取上传凭证失败，请重新检查网络或重试')
            this.queue
              .filter((item) => item.status == 1 || item.status == 2)
              .forEach(this.stopUpload)
            // 全部停掉之后 nextIndex 手动指定
            // 否则就会出现错误的情况 状态对的 但是nextIndex错的
            this.nextIndex = this.queue.length
          },
        })
      })
    },

    // 验证上传文件
    verify(file) {
      if (this.$store.state.userInfo.leftStorage <= 0) {
        this.$root.prompt('文件超出剩余空间，请扩容')
        return true
      }
      if (file.size > this.maxSize) {
        this.$root.prompt('文件过大，请压缩后重新上传')
        return true
      }
      if (!/x-mpegurl|mp4/.test(file.type)) {
        this.$root.prompt('视频格式需为mp4, m3u8')
        return true
      }
      // if (
      //   this.queue.filter((item) => item.status === 1 || item.status === 2)
      //     .length >= this.limit
      // ) {
      //   this.$root.prompt(`上传队列已达上限，请等上传列表完成后再选择`)
      //   return true
      // }
    },

    // 上传前的处理 验证
    beforeVerify() {
      // 如果只允许上传一个 每次删除掉第一个
      if (this.limit === 1) {
        this.removeFile(0, true)
      }
      this.$emit('start')
    },
    
    // 使用 RxJS 修改后的 addFile 方法  
    // 获取上传文件
    addFile(uploadFile) {
      const { target } = uploadFile;
      // 上传前的处理
      this.beforeVerify();
      const fileList = target.files
      // 使用 from 创建一个发出文件的 Observable
      const fileObservable$ = from(fileList);
      // 上传文件、获取文件信息并处理
      fileObservable$.pipe(
        mergeMap(async item => {
          if(!this.verify(item)){
            return await this.handlefile(item)
          }
        }),
      ).subscribe({
        complete: () => {
          console.log("操作完成",!this.beginUploadStatus);
          if (!this.beginUploadStatus) {
            this.next();
          }
          target.value = '';
        }
      });
    },

    handlefile(item) {
      return new Promise(async (res,rej)=>{
        try {
          this.$emit('uploadType', 'uploading');
          let fileInfo = {};
          try {
            fileInfo = await getFileInfo(item);
          } catch (error) {
            if (this.value === 5) {
              this.$root.prompt({ msg: '无法获取该视频信息，无法进行自适应转码，请重新选择转码规格！' });
            }
          }
          const fid = Math.random();
          this.fs[fid] = item;
          this.queue.push({
            fid,
            status: 1, 
            percent: 0,
            speed: 0,
            type: item.type,
            file_size: item.size,
            file_name: item.name,
            subscription: {},
            $index: this.queue.length,
            video_long_side: fileInfo.width || 0,
            video_short_side: fileInfo.height || 0,
            video_bit_rate: fileInfo.averageBitrate || 0,
            chapter_hour: fileInfo.duration || 0,
            isUpload: Object.keys(fileInfo).length > 0
          });
          this.$emit('uploadType', 'uploaded');
          res()
        } catch (error) {
          rej()
        }
      })
    },
    // begin_upload() {
    //   if (this.queue.length == 0) {
    //     this.$root.prompt({ msg: '请先选择视频文件！' })
    //     return
    //   }
    //   this.beginUploadStatus = false
    //   this.next()
    //   this.$emit('click_begin_upload')
    // },

    // 开始上传两个
    next() {
      // 只有一次性同时上传2个
      if (this.currUploadingFile.length < this.meanwhile) {
        // 初始化没有下一步ID
        if (this.nextIndex === undefined) {
          this.nextIndex = 0
        }
        console.log('上传文件开始',this.currUploadingFile,this.meanwhile,this.nextIndex);
        // 必须下一步上传的文件存在
        if (this.queue[this.nextIndex]) {
          if (this.fs[this.queue[this.nextIndex].fid]) {
            const index = this.nextIndex
            // 设置下一步ID
            this.nextIndex += 1
            this.uploadFileStart(index)
          } else {
            // 设置下一步ID
            this.nextIndex += 1
          }
          // 继续执行下一步
          this.next()
        }
      }
    },

    /**
     * @description 删除 停止上传文件不包括成功的
     * @param 队列index
     * @pass 是否直接删除 不删除服务器文件
     */

    /**
     * @description 取消上传操作说明
       @author cyy
       上传和取消上传操作为异步操作
       取消上传时，上传文件的两种状态：1，上传中  2，上传成功
       1，上传中。确认取消后，状态为-1，此时上传的代码要判断上传文件的状态是否为2即正在上传中，否则停止上传
       2，上传成功。文件状态为0，此时不允许进行删除（改变队列数据状态的操作）操作。
     */
    removeFile(index, pass) {
      const item = this.queue[index]
      // console.log(index, item, this.queue, this.fs, this.nextIndex)

      if (item) {
        // 删除通用函数
        const deleteCallback = () => {
          // 删除的是否在上传中
          // const i = this.currUploadingFile.indexOf(item.fid)
          // 停止掉上传进度
          this.stopUpload(item)
          // 删除文件 因为会冗余数据
          delete this.fs[item.fid]
          // 隐藏文件
          item.status = -1
          //如果是初始化状态，直接删除
          if (this.beginUploadStatus) {
            this.queue.splice(index, 1)
            this.queue = this.queue.map((item, index) => {
              return _.assign({}, item, { $index: index })
            })
          } else {
            this.next()
          }
          if (pass) {
            this.queue.splice(index, 1)
            this.nextIndex = undefined
          }

          // 如果当前上传的只有一个 且这个被删除
          // if (!this.currUploadingFile.length && i > -1) {
          //   this.next()
          // }
        }
        // 如果需要删除服务器文件
        if (item.serverId && !pass) {
          this.$confirm(
            '是否删除已选文件，删除后可在回收站中查看！',
            '删除文件',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
            }
          )
            .then(() => {
              item.status = 5
              this.$http({
                name: `deleteRecording${item.serverId}`,
                url: '/DataBank/deleteRecording',
                data: {
                  type: 1,
                  ids: item.serverId,
                },
                callback: () => {
                  this.$emit('getJgInfo')
                  deleteCallback()
                },
                error() {
                  item.status = 6
                },
              })
            })
            .catch((err) => {
              console.log(err)
            })
        } else if (pass || item.serverId || item.status == 3) {
          deleteCallback()
        } else {
          if (this.currUploadingFile.length > 0 && item.status == 2) {
            this.$confirm('是否取消当前文件的上传？', '取消上传', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
            })
              .then(() => {
                // 上传成功的文件不允许被删除
                if (item.status != 0) {
                  if (item.serverId && !pass) {
                    this.$root.prompt('操作异常，请重试')
                  } else {
                    deleteCallback()
                  }
                }
              })
              .catch((err) => {
                console.log(err)
              })
          } else {
            if (item.serverId && !pass) {
              this.$root.prompt('操作异常，请重试')
            } else {
              deleteCallback()
            }
          }
        }
      }
      // this.beginUploadStatus = true
      // this.$emit('continueupload', true)t
      // this.$emit('currUploadingFile', this.currUploadingFile)
    },

    // 停止七牛上传
    stopUpload(item) {
      try {
        item.status = 3
        item.subscription.cancel()
      } catch (e) {
        // console.log(e)
        return
      }
    },

    // 重新上传
    againUpload(index) {
      const { fid, status, avinfo } = this.queue[index]
      // 如果是上传到资料库失败
      if (status === 4) {
        this.uploadServer(index, { avinfo })
      } else {
        const file = this.fs[fid]
        if (!file) {
          throw new Error('againUpload() 上传文件 不存在')
        }
        this.removeFile(index)

        // 取出文件重新上传
        this.addFile({
          target: { files: [file] },
        })
      }
    },

    // 开始上传
    uploadFileStart(uploadIndex) {
      if (typeof uploadIndex !== 'number') {
        throw new Error('uploadIndex 不是一个正确的队列下标')
      }
      const { fid } = this.queue[uploadIndex]
      if (!fid) {
        throw new Error('uploadFileStart() fid 不存在')
      }
      const file = this.fs[fid]
      if (!file) {
        throw new Error('uploadFileStart() 上传文件 不存在')
      }
      this.queue = this.queue.map((m) => {
        let array = _.assign({}, m, {
          file_name: m.file_name.substring(m.file_name.length - 50),
        })
        return array
      })
      // 添加正在上传中
      this.queue[uploadIndex].status = 2
      const uploader = (this.queue[uploadIndex].subscription =
        this.tcVod.upload({ mediaFile: file }))

      uploader.on('media_progress', (info) => {
        /**
         * 1.3.9.4此处获取上传同步信息
         */
        //上传百分比
        info.speed / 1024 / 1024
        this.queue[uploadIndex].percent = Math.floor(info.percent * 100)
        this.queue[uploadIndex].speed = Math.floor(info.speed / 1024 / 1024)
      })

      uploader
        .done()
        .then((doneResult) => {
          //当上传完成后，判断当前文件的上传状态是上传中，才允许执行下一步操作
          if (this.queue[uploadIndex].status == 2) {
            // // 上传到服务器资料
            this.uploadServer(uploadIndex, doneResult)
          }
        })
        .catch(() => {
          this.queue[uploadIndex].status = 3
          this.next()
        })
      // this.queue[uploadIndex].subscription = qiNiu
      //   .upload(
      //     file,
      //     undefined,
      //     this.token,
      //     { fname: '', params: {} },
      //     {
      //       retryCount: 3,
      //       useCdnDomain: true,
      //       disableStatisticsReport: true
      //     }
      //   )
      //   .subscribe({
      //     next: ({ total: { percent } }) => {
      //       this.queue[uploadIndex].percent = Math.floor(percent)
      //     },
      //     error: e => {
      //       this.queue[uploadIndex].status = 3
      //       this.next()
      //     },
      //     complete: result => {
      //       // 上传到服务器资料库
      //       this.uploadServer(uploadIndex, result)
      //     }
      //   })
    },

    // 上传到服务器资料库
    uploadServer(index, doneResult) {
      const item = this.queue[index]
      const url = item.url ? item.url : doneResult.video.url

      // 存储一些数据 重新上传会使用
      item.url = url
      item.avinfo = doneResult

      // 上传成功通用函数
      const success = () => {
        // 上传成功
        this.uploadComplete(index)
        // 向上传递
        this.$emit(
          'complete',
          _.merge({}, item, {
            url,
            doneResult,
            radio: this.radio,
          })
        )
        // console.log(
        //   _.merge({}, item, {
        //     url,
        //     doneResult,
        //   })
        // )
        this.next()
      }

      /**
       * 1.3.9.4此处需要更改
       */
      // 如果需要上传到服务器
      if (this.isUploadServe) {
        console.log("如果需要上传到服务器",item);
        if(item.isUpload){
          this.$http({
            url: '/Course/uploadVideoFile',
            isMultipleSelf: 'all',
  
            data: {
              video_url: url,
              fileSize: item.file_size,
              file_name: item.file_name,
              avInfo: doneResult,
              group_id: this.list,
              transcode_type: this.radio, // 1 加密  2普通
              video_spec: this.value, //视频分辨率规格 1 720P 2 1080P 3 360P 4 480P
              [this.$route.params.sl_id ? 'sl_id' : 'open_class_id']:
                this.$route.params.sl_id || this.$route.params.id,
              // 宽
              video_long_side:item.video_long_side,
              // 高
              video_short_side:item.video_short_side,
              // 比特率
              video_bit_rate:item.video_bit_rate,
              // 视频时长
              chapter_hour:item.chapter_hour,
            },
            callback: ({ id, classHour }) => {
              item.serverId = id
              if (classHour) {
                item.classHour = classHour
              }
              success()
            },
            error: () => {
              item.status = 4
              this.next()
            },
          })
        } else {
          this.$root.prompt({ msg: '无法获取该视频信息，无法进行自适应转码，请重新选择转码规格！' })
        }
      } else {
        success()
      }
    },

    // 上传成功
    uploadComplete(currIndex) {
      // 如果ID存在说明是上传成功回调 删除上传完成的
      // 如果上传失败了 也删除！！！
      const { fid } = this.queue[currIndex]
      if (fid) {
        const index = this.currUploadingFile.indexOf(fid)
        if (index > -1) {
          this.queue[currIndex].status = 0
          // 成功就上传文件 记得是成功（也可以不删除） 因为还有重新上传功能
          delete this.fs[fid]
        }
      } else {
        throw new Error('uploadComplete() fid 不存在')
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.warning-opennew {
  display: flex;
  justify-content: center;
  font-size: 13px;
  margin-top: 18px;
  i {
    color: #ff3535;
    margin-right: 10px;
  }
  a {
    cursor: pointer;
    color: #1b9d97;
    text-decoration: underline !important;
  }
}
.uploadBtn {
  position: relative;
  input[type='file'] {
    opacity: 0;
    text-indent: -9999px;
  }
}
.absolute {
  width: 126px;
  height: 42px;
}
</style>
