<template>
  <div id="pushSettings">
    <el-dialog
      class="dialogVisible"
      width="800px"
      :visible="dialogstatus"
      @update:visible="(v) => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="cancel"
    >
      <div slot="title" class="title">
        推送设置
        <span class="tip">
          提示：输入的内容只用于消息推送显示，不会替换到公开课直播的内容
        </span>
      </div>
      <div class="dialogVisible_bottom">
        <el-form
          label-position="left"
          :model="ruleForm"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="推送内容" :required="true">
            <el-input
              maxlength="100"
              show-word-limit
              v-model="ruleForm.push_content"
              placeholder="请输入推送内容"
            ></el-input>
          </el-form-item>

          <el-form-item :required="true" label="直播名称">
            <el-input
              maxlength="50"
              show-word-limit
              v-model="ruleForm.keyword1"
              placeholder="请输入直播名称"
            ></el-input>
          </el-form-item>

          <el-form-item label="直播时间" :required="true">
            <el-date-picker
              :pickerOptions="pickerOptions"
              value-format="yyyy-MM-dd HH:mm"
              format="yyyy-MM-dd HH:mm"
              style="width: 328px"
              v-model="ruleForm.keyword2"
              type="datetime"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="上课地点" :required="true">
            <el-input
              maxlength="50"
              show-word-limit
              v-model="ruleForm.keyword3"
              placeholder="请输入上课地点"
            ></el-input>
          </el-form-item>

          <el-form-item label="推送时间" :required="true">
            <el-date-picker
              type="datetime"
              @change="picktime"
              :pickerOptions="pickerOptions"
              style="width: 328px"
              format="yyyy-MM-dd HH:mm"
              v-model="push_timeval"
              placeholder="选择日期"
              value-format="timestamp"
            ></el-date-picker>
          </el-form-item>
        </el-form>
        <div class="tip2">
          <p>温馨提示：</p>
          <p>
            1、如果频繁推送消息，可能导致用户向微信投诉，导致公众号被微信官方封号；消息推送过于频繁而导致的封号与叮叮开课无关。
          </p>
          <p>
            2、微信官方规定，消息定时推送只能预约30天以内的时间；超过30天的消息无法推送。
          </p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel" size="medium">取 消</el-button>
        <el-button type="primary" @click="submitForm" size="medium">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'pushSettings',
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 86400000
        },
      },

      push_timeval: '',

      ruleForm: {
        open_class_id: '',
        push_content: '',
        keyword1: '',
        keyword2: null,
        keyword3: '',
        push_time: '',
      },
    }
  },

  created() {
    if (this.type == 1) {
      this.getpushSet()
    }
  },

  props: {
    //2是新建 1是列表点进来
    type: {
      type: Number,
      default: 1,
    },
    pushseting: {
      type: Object,
      default: () => {},
    },
    dialogstatus: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    getpushSet() {
      this.$http({
        url: `/openClass/pushSet?open_class_id=${this.pushseting.open_class_id}`,
        callback: ({ data }) => {
          this.ruleForm = data
          this.push_timeval = data.push_time * 1000
        },
        error: () => {
          this.$root.prompt('保存失败失败')
        },
      })
    },

    picktime(val) {
      this.ruleForm.push_time = val / 1000
    },

    submitForm() {
      this.ruleForm = _.assign({}, this.ruleForm, {
        push_content:
          this.ruleForm.push_content == undefined
            ? ''
            : this.ruleForm.push_content.trim(),
        keyword1:
          this.ruleForm.keyword1 == undefined
            ? ''
            : this.ruleForm.keyword1 == undefined
            ? ''
            : this.ruleForm.keyword1.trim(),
        keyword3:
          this.ruleForm.keyword3 == undefined
            ? ''
            : this.ruleForm.keyword3.trim(),
        open_class_id: this.pushseting.open_class_id,
      })
      if (!this.ruleForm.push_content) {
        this.$root.prompt('请输入推送内容')
        return
      }
      if (!this.ruleForm.keyword1) {
        this.$root.prompt('请输入直播名称')
        return
      }
      if (!this.ruleForm.keyword2) {
        this.$root.prompt('请选择直播日期')
        return
      }
      if (!this.ruleForm.keyword3) {
        this.$root.prompt('请输入直播老师')
        return
      }
      if (!this.ruleForm.push_time) {
        this.$root.prompt('请选择推送日期')
        return
      }

      if (this.type == 1) {
        this.$http({
          url: '/openClass/pushSet',
          data: this.ruleForm,
          callback: () => {
            this.$root.prompt({
              type: 'success',
              msg: '保存成功',
            })
          },
          error: () => {
            this.$root.prompt('保存失败失败')
          },
        })
      } else {
        this.$emit('complete', this.ruleForm)
      }
      this.cancel()
    },

    cancel() {
      // 取消后回复选择数据原样
      this.$emit('update:dialogstatus', false)
    },
  },
}
</script>

<style lang="scss" scoped>
#pushSettings {
  .title {
    font-size: 14px;
    color: #333333;
    line-height: 14px;
    .tip {
      margin-left: 20px;
      font-size: 12px;
      color: #ff3535;
      line-height: 14px;
    }
  }
  .tip2 {
    font-size: 12px;
    color: #666666;
    line-height: 20px;
    margin-top: 8px;
  }
  ::v-deep .dialogVisible {
    .el-dialog__body {
      padding-top: 20px;
    }
  }
}

::v-deep .el-input__inner {
  padding-right: 60px;
}
</style>