<template>
  <div id="liveRecording">
    <div class="search">
      <el-date-picker
        v-model="value1"
        type="daterange"
        value-format="timestamp"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      ></el-date-picker>
    </div>
    <pagination2 :option="post" url="/openClass/liveList" ref="childDialogBox">
      <template v-slot:default="{ tableData }">
        <el-table
          ref="dialogTable"
          @sort-change="sortChange"
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column label="直播时间" sortable="custom">
            <template slot-scope="{ row }">
              {{ row.start_time | formatTimeStamp }}
            </template>
          </el-table-column>

          <el-table-column label="直播老师" prop="uname">
            <template slot-scope="{ row }">
              {{ row.uname }}
            </template>
          </el-table-column>

          <el-table-column label="直播时长">
            <template slot-scope="{ row }">
              <span>
                {{ row.push_duration | formatTimeLength }}
              </span>
            </template>
          </el-table-column>

          <el-table-column label="直播类型">
            <template slot-scope="{ row }">
              <span>
                {{ row.live_mode | getLiveMode }}
              </span>
            </template>
          </el-table-column>

          <!-- <el-table-column label="在线人数" prop="online">
            <div slot="header">
              在线人数
              <helpIcon
                style="margin-left: 6px"
                content=" 在线人数指最高峰值人数"
              ></helpIcon>
            </div>
            <template slot-scope="{ row }">
              {{ row.online }}
            </template>
          </el-table-column> -->

          <el-table-column label="状态" prop="address">
            <template slot-scope="{ row }">
              {{ row.status | getstatus }}
            </template>
          </el-table-column>

          <el-table-column
            label="操作"
            align="right"
            min-width="100"
            prop="address"
          >
            <template slot-scope="{ row }">
              <el-button type="text" @click="dataAnalysisDetail(row)">
                数据统计
              </el-button>
              <!-- <span class="sp_drive">|</span>
              <el-button type="text" @click="playbackManagement">
                查看回放
              </el-button> -->
            </template>
          </el-table-column>
        </el-table>
      </template>
    </pagination2>
    <router-view />
  </div>
</template>
<script>
// import helpIcon from '@/components/helpIcon'
export default {
  name: 'liveRecording',

  components: {
    // helpIcon,
  },

  data() {
    return {
      post: {
        open_class_id: this.$route.params.id,
        start_time: '',
        end_time: '',
        sort: '',
      },

      value1: '',
    }
  },

  props: {
    fatherPath: {
      type: String,
      default: '',
    },
  },

  watch: {
    value1: {
      handler(val) {
        if (val) {
          this.post.start_time = val[0] / 1000
          this.post.end_time = val[1] / 1000
        } else {
          this.post.start_time = ''
          this.post.end_time = ''
        }
      },
      deep: true,
      immediate: true,
    },
  },
  filters: {
    getLiveMode(val) {
      let text
      switch (Number(val)) {
        case 1:
          text = '标准直播'
          break
        case 3:
          text = '智能直播'
          break
        default:
          text = '快直播'
          break
      }
      return text
    },

    getstatus(val) {
      let text
      switch (Number(val)) {
        case 1:
          text = '直播中'
          break

        default:
          text = '已结束'
          break
      }
      return text
    },
  },
  methods: {
    sortChange(value) {
      this.post = _.assign({}, this.post, {
        sort: !value.order ? '' : value.order === 'ascending' ? 1 : 2,
      })
    },

    dataAnalysisDetail(row) {
      this.$router.push({
        path: `${this.fatherPath}/detail/${this.$route.params.id}/1/dataStatistics`,
        query: { sl_id: row.live_history_id, type: 6, isopencourse: 1 },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
#liveRecording {
  .search {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
  .sp_drive {
    color: #1b9d97;
    margin: 0 10px;
  }
}
</style>