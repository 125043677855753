<template>
  <div id="livebroadcastSettings">
    <el-dialog
      title="直播设置"
      class="dialogVisible"
      width="800px"
      :visible="dialogstatus"
      @update:visible="(v) => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="cancel"
    >
      <div slot="title" class="title">直播设置</div>
      <div class="dialogVisible_bottom">
        <el-tabs v-model="activeName" @tab-click="tabClick">
          <el-tab-pane label="功能配置" name="1">
            <div class="item">
              <div class="left">
                <el-switch
                  @change="change"
                  v-model="functionlist.is_show_gift"
                  active-value="1"
                  inactive-value="2"
                ></el-switch>
                <div class="ml30">
                  <p class="p1">打赏/送礼</p>
                  <p class="p2">
                    开启后，学员可在直播间打赏/送礼（打赏金额不会到老师账户，可在网校账号中查看）
                  </p>
                </div>
              </div>
              <el-popover placement="right" trigger="hover" width="300">
                <div style="width: 100%">
                  <img
                    src="~@ass/img/1.4.2.3/img_dsslsl@2x.png"
                    style="width: 100%"
                    alt=""
                  />
                </div>
                <el-button type="text" slot="reference" class="fr">
                  查看示例
                </el-button>
              </el-popover>
            </div>

            <div class="item">
              <div class="left">
                <el-switch
                  @change="change"
                  v-model="functionlist.is_show_send_flowers"
                  active-value="1"
                  inactive-value="2"
                ></el-switch>
                <div class="ml30">
                  <p class="p1">直播间送花</p>
                  <p class="p2">
                    开启后，显示送花按钮，不限次数点击，营造直播氛围
                  </p>
                </div>
              </div>
              <el-popover placement="right" trigger="hover" width="300">
                <div style="width: 100%">
                  <img
                    src="~@ass/img/1.4.2.3/img_shsl@2x.png"
                    style="width: 100%"
                    alt=""
                  />
                </div>
                <el-button type="text" slot="reference" class="fr">
                  查看示例
                </el-button>
              </el-popover>
            </div>

            <div class="item">
              <div class="left">
                <el-switch
                  @change="change"
                  v-model="functionlist.is_show_xcx_play"
                  active-value="1"
                  inactive-value="2"
                ></el-switch>
                <div class="ml30">
                  <p class="p1">小程序观看</p>
                  <p class="p2">开启后，显示小程序观看按钮</p>
                </div>
              </div>
              <el-popover placement="right" trigger="hover" width="300">
                <div style="width: 100%">
                  <img
                    src="~@ass/img/1.4.2.3/img_xcxgksl@2x.png"
                    style="width: 100%"
                    alt=""
                  />
                </div>
                <el-button type="text" slot="reference" class="fr">
                  查看示例
                </el-button>
              </el-popover>
            </div>
          </el-tab-pane>

          <el-tab-pane label="显示设置" name="2">
            <div class="item">
              <div class="left">
                <el-switch
                  @change="change"
                  v-model="functionlist.is_chatroom_sys_msg"
                  active-value="1"
                  inactive-value="2"
                ></el-switch>
                <div class="ml30">
                  <p class="p1">禁言、拉黑、踢出提示</p>
                  <p class="p2">
                    开启后，学员被禁言、拉黑、踢出任一操作时，会在聊天区显示提示信息
                  </p>
                </div>
              </div>
              <el-popover placement="right" trigger="hover" width="300">
                <div style="width: 100%">
                  <img
                    src="~@ass/img/1.4.2.3/img_jylhsl@2x.png"
                    style="width: 100%"
                    alt=""
                  />
                </div>
                <el-button type="text" slot="reference" class="fr">
                  查看示例
                </el-button>
              </el-popover>
            </div>

            <div class="item">
              <div class="left">
                <el-switch
                  @change="change"
                  v-model="functionlist.is_show_home"
                  active-value="1"
                  inactive-value="2"
                ></el-switch>
                <div class="ml30">
                  <p class="p1">网校首页和个人中心按钮</p>
                  <p class="p2">开启后，学员在直播间可以访问网校和个人中心</p>
                </div>
              </div>
              <el-popover placement="right" trigger="hover" width="300">
                <div style="width: 100%">
                  <img
                    src="~@ass/img/1.4.2.3/img_wxsysl@2x.png"
                    style="width: 100%"
                    alt=""
                  />
                </div>
                <el-button type="text" slot="reference" class="fr">
                  查看示例
                </el-button>
              </el-popover>
            </div>

            <div class="item">
              <div class="left">
                <el-switch
                  @change="change"
                  v-model="functionlist.is_show_enter_msg"
                  active-value="1"
                  inactive-value="2"
                ></el-switch>
                <div class="ml30">
                  <p class="p1">进入直播间提示</p>
                  <p class="p2">
                    开启后，有新学员进入直播间时，其他学员会看到提示
                  </p>
                </div>
              </div>
              <el-popover placement="right" trigger="hover" width="300">
                <div style="width: 100%">
                  <img
                    src="~@ass/img/1.4.2.3/img_jrzbjsl@2x.png"
                    style="width: 100%"
                    alt=""
                  />
                </div>
                <el-button type="text" slot="reference" class="fr">
                  查看示例
                </el-button>
              </el-popover>
            </div>

            <div class="item">
              <div class="left">
                <el-switch
                  v-if="ishow"
                  active-value="1"
                  inactive-value="2"
                  :value="functionlist.is_cleaning_chatroom_msg"
                  @input="handleInput"
                ></el-switch>
                <div class="ml30">
                  <p class="p1">定时清理聊天内容</p>
                  <p class="p2">
                    开启后，系统在每天凌晨自动清理历史聊天内容；如果您的每次直播都不想展示历史消息，建议开启
                  </p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="left">
                <el-switch
                  @change="change"
                  v-model="functionlist.is_show_ta"
                  active-value="1"
                  inactive-value="2"
                ></el-switch>
                <div class="ml30">
                  <p class="p1">榜单过滤老师和助教</p>
                  <p class="p2">
                    开启后，学员端的榜单列表，
                    只展示学员身份的数据，老师和助教身份不会显示
                  </p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="left">
                <el-switch
                  @change="change"
                  v-model="functionlist.is_show_subscribe"
                  active-value="1"
                  inactive-value="2"
                ></el-switch>
                <div class="ml30">
                  <p class="p1">订阅按钮</p>
                  <p class="p2">
                    开启后，学员在微信H5端观看直播时会显示订阅按钮和取消订阅按钮
                  </p>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="left">
                <el-switch
                  @change="change"
                  v-model="functionlist.is_show_history_list"
                  active-value="1"
                  inactive-value="2"
                ></el-switch>
                <div class="ml30">
                  <p class="p1">历史榜单记录</p>
                  <p class="p2">
                    开启后，学生端榜单会显示全部数据；关闭后，仅展示这次直播的数据
                  </p>
                </div>
              </div>
            </div>
          </el-tab-pane>

          <el-tab-pane label="人数设置" name="3">
            <el-radio-group
              v-model="functionlist.is_show_online"
              class="mt20 mb20"
            >
              <el-radio label="1">真实人数</el-radio>
              <el-radio label="2">虚拟人数</el-radio>
              <el-radio label="3">不显示</el-radio>
            </el-radio-group>
            <div class="people_num" v-if="functionlist.is_show_online == '2'">
              <div class="popl_font1">
                自定义人数
                <span style="margin-left: 4px" class="redcolor">*</span>
              </div>
              <el-input
                maxlength="5"
                style="width: 300px"
                v-model="functionlist.virtual_online"
                placeholder="请输入数字"
                oninput="value=value.replace(/[^0-9]/g,'')"
                @blur="numChange"
              ></el-input>
            </div>
            <div class="tip" v-if="functionlist.is_show_online != '3'">
              提示：真实人数指直播间实时人数，虚拟人数可自定义数字+真实人数
            </div>
            <div class="btns">
              <!-- <el-button size="medium" @click="cancel">取消</el-button> -->
              <el-button type="primary" @click="change(1)" size="medium">
                保存
              </el-button>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'livebroadcastSettings',
  data() {
    return {
      ishow: true,

      functionlist: {
        is_chatroom_sys_msg: '1',
        is_get_mobile: '1',
        is_real_name_play: '1',
        is_show_enter_msg: '1',
        is_cleaning_chatroom_msg: '2',
        is_show_gift: '1',
        is_show_home: '1',
        is_show_invite_list: '1',
        is_show_online: '1',
        is_show_play_devices: '1',
        is_show_playback: '1',
        is_show_send_flowers: '1',
        is_show_xcx_play: '1',
        virtual_online: '',
        is_show_ta: '1',
        is_show_subscribe: '1',
        is_show_history_list: '1',
      },

      activeName: '1',
    }
  },

  created() {
    if (this.type == 1) {
      this.getliveSet()
    } else if (this.type == 2 && this.ruleForm != 0) {
      // 如果是编辑的，便使用接收的数据
      this.functionlist = this.ruleForm
    }
  },

  props: {
    ruleForm: [Object, Number],
    //2是新建或者是编辑的
    type: {
      type: Number,
      default: 1,
    },
    settingval: {
      type: String,
      default: '',
    },
    dialogstatus: {
      type: Boolean,
      default: false,
    },
  },
  
  methods: {
    handleInput() {
      if (this.functionlist.is_cleaning_chatroom_msg == '2') {
        this.$confirm(
          '开启定时清理后，将会删除该直播间的聊天信息，无法恢复，请您确认后开启！您也可以在控制台内批量删除消息。',
          '重要提示',
          {
            confirmButtonText: '确定开启',
            cancelButtonText: '取消',
            confirmButtonClass: 'livebroadcastSettingsbtcolor',
            cancelButtonClass: 'livebroadcastSettingsCancelButtonClass',
          }
        )
          .then(() => {
            this.ishow = false
            this.functionlist.is_cleaning_chatroom_msg = '1'
            this.ishow = true

            this.change()
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        this.functionlist.is_cleaning_chatroom_msg = '2'
        this.change()
      }
    },

    // handleClose(done) {
    //   if (
    //     this.activeName == '3' &&
    //     !this.functionlist.virtual_online &&
    //     this.functionlist.is_show_online == '2'
    //   ) {
    //     this.$root.prompt('请输入自定义人数')
    //     return
    //   } else {
    //     done()
    //   }
    // },

    // beforeHandle() {
    //   if (
    //     !this.functionlist.virtual_online &&
    //     this.functionlist.is_show_online == '2'
    //   ) {
    //     this.$root.prompt('请输入自定义人数')
    //     return false
    //   }
    // },

    tabClick() {
      if (this.settingval) {
        this.getliveSet()
      }
    },

    numChange(e) {
      this.functionlist.virtual_online = e.target.value
    },

    change(val) {
      if (
        this.activeName == '3' &&
        !this.functionlist.virtual_online &&
        this.functionlist.is_show_online == '2'
      ) {
        this.$root.prompt('请输入自定义人数')
        return
      }
      if (this.type == 1) {
        this.$http({
          url: `/openClass/liveSet`,
          data: _.assign({}, this.functionlist, {
            open_class_id: this.settingval,
          }),
          callback: () => {
            if (val == 1) {
              this.$root.prompt({
                msg: '操作成功',
                type: 'success',
              })
            }
          },
          error: () => {
            this.$root.prompt('操作失败')
          },
        })
      } else {
        this.$emit('complete', this.functionlist)
      }
    },

    //获取初始数据
    getliveSet() {
      this.$http({
        url: `/openClass/liveSet?open_class_id=${this.settingval}`,
        callback: ({ data }) => {
          this.functionlist = data
        },
        error: () => {
          this.$root.prompt('操作失败')
        },
      })
    },

    cancel() {
      // 取消后回复选择数据原样
      this.$emit('update:dialogstatus', false)
    },
  },
}
</script>
<style>
.livebroadcastSettingsbtcolor {
  background: #ff3535 !important;
  border: 1px solid #ff3535 !important;
}
.livebroadcastSettingsCancelButtonClass {
  width: 80px;
}
</style>
<style lang="scss" scoped>
#livebroadcastSettings {
  .people_num {
    display: flex;
    align-items: center;
    margin: 0 0 20px 0;
    .popl_font1 {
      margin-right: 26px;
      display: flex;
      font-size: 14px;
      color: #333333;
    }
  }
  .tip {
    font-size: 12px;
    color: #666666;
  }
  .btns {
    margin-top: 30px;
    .el-button {
      width: 74px;
    }
  }
  .title {
    font-size: 14px;
    color: #333333;
    line-height: 14px;
  }
  ::v-deep .dialogVisible {
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0;
      border-bottom: 1px solid #eeeeee;
      .el-button {
        font-size: 12px;
      }
      .left {
        display: flex;
        align-items: center;
        .p1 {
          margin-bottom: 14px;
          font-size: 14px;
          color: #333333;
          line-height: 14px;
        }
        .p2 {
          font-size: 12px;
          color: #666666;
          line-height: 12px;
        }
      }
    }
    .el-dialog__body {
      padding: 0px 0 20px;
    }
    .el-tabs__nav-scroll {
      padding: 0 30px;
    }
    .el-tabs__header {
      margin: 0 !important;
    }
    .el-tab-pane {
      position: relative;
      padding: 0px 40px;
      height: 405px;
      overflow-y: auto;
      &::-webkit-scrollbar-track {
        background-color: #fff;
      }
    }
  }
}
</style>
