<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-03-29 15:10:37
 * @LastEditTime: 2022-06-28 15:45:37
 * @LastEditors: pengmingjun 1317525216@qq.com
 * @Description: 客户关系 =》 变更记录 
 * @FilePath: \ teacher.dingdingkaike\src\views\MarketingCenter\promoter\achievement.vue
-->
<template>
  <div class="transcoddetail">
    <el-dialog
      width="900px"
      :visible="dialogstatus"
      @update:visible="v => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      @close="close"
    >
      <div slot="title" class="flex-center">
        <div class="title_font">变更邀请人</div>
      </div>
      <div class="search-input">
        <el-input
          v-model="post.search"
          size="medium"
          style="width: 218px"
          placeholder="输入邀请人名称搜索"
        ></el-input>
      </div>
      <pagination2 ref="inviteList" :option="post" url="/openClass/inviteList">
        <template v-slot:default="{ tableData }">
          <el-table
            empty-text="暂无数据"
            :data="tableData"
            :header-cell-style="{
              background: 'rgba(245,245,245,1)',
              color: '#333333',
              height: '40px',
            }"
            style="width: 100%"
          >
            <el-table-column prop="transcode_type" label="邀请人">
              <template slot-scope="{ row }">
                <div v-if="row.uid" class="flex-center">
                  <img class="photo" :src="row.uphoto" alt="" />
                  <div class="font1 ml10">
                    {{ row.uname }}
                  </div>
                  <div class="curr" v-if="row.uid == post.inviter_id">
                    当前
                  </div>
                </div>
                <div v-else>-</div>
              </template>
            </el-table-column>
            <el-table-column label="操作" prop="address" align="right">
              <template slot-scope="{ row }">
                <el-button
                  type="primary"
                  @click="bind(row)"
                  size="medium"
                  :class="{ disabled2: row.uid == post.inviter_id }"
                  :disabled="row.uid == post.inviter_id"
                >
                  变更
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </pagination2>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'changeRecord',

  props: {
    value: Object,
  },

  data() {
    return {
      //弹窗的控制
      dialogstatus: true,
      post: {
        inviter_id: this.value.inviter_id,
        open_class_id: this.$route.params.id,
        search: '',
      },
    }
  },

  created() {},

  methods: {
    close() {
      this.$emit('close')
    },

    async bind(row) {
      await this.$http({
        url: '/openClass/inviteeChange',
        successMsg: true,
        data: {
          uid: row.uid,
          inviter_id: this.post.inviter_id,
          open_class_id: this.$route.params.id,
        },
      })
      this.post.inviter_id = row.uid
    },
  },
}
</script>
<style lang="scss" scoped>
.title_font {
  font-size: 14px;
  color: #333333;
}

.photo {
  width: 22px;
  height: 22px;
  flex-shrink: 0;
  border-radius: 50%;
}
.search-input {
  margin: -20px 0 20px;
  text-align: right;
  font-size: 0;
}

.curr {
  width: 32px;
  height: 18px;
  background: #0aa29b;
  border-radius: 2px;
  font-size: 12px;
  color: #ffffff;
  line-height: 18px;
  text-align: center;
  margin-left: 15px;
}
.disabled2,
.disabled2:hover {
  border-color: #dedede;
  color: #fff;
  background: #dedede;
}
</style>
