<template>
  <div style="position: relative">
    <div class="datePicker">
      <div class="vm t1">统计频率：每5分钟记录一次</div>
      <div class="vm">
        <el-date-picker
          v-if="!isopencourse"
          v-model="datePicker"
          type="date"
          align="right"
          unlink-panels
          value-format="timestamp"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        ></el-date-picker>
      </div>
    </div>
    <div ref="onlineMember" style="margin: 0 20px; height: 386px"></div>
  </div>
</template>
<script>
import echarts from 'echarts'

export default {
  name: 'onlineMember',

  props: {
    isopencourse: {
      type: String,
      default: undefined,
    },
    id: String,
    type: [String, Number],
  },

  data() {
    return {
      datePicker: '',

      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date())
            },
          },
          {
            text: '昨天',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
            },
          },
          {
            text: '一周前',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            },
          },
        ],
      },
    }
  },

  watch: {
    datePicker(value) {
      this.beforeGetData(value)
    },

    type() {
      if (this.reset()) {
        this.beforeGetData(this.datePicker)
      }
    },
  },

  mounted() {
    this.reset()
  },

  destroyed() {
    this.offResize()
    this.destroy()
  },

  methods: {
    beforeGetData(value) {
      const startTime = value / 1000
      const endTime = (value + 86400000 - 1000) / 1000
      this.getData(startTime, endTime)
    },

    reset() {
      const date = new Date()
      const currMonthData = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      )
      if (+currMonthData === this.datePicker) {
        return true
      }
      this.datePicker = +currMonthData
    },

    destroy() {
      this.offResize()
      this.myChart.dispose()
      this.myChart = null
    },

    getData(startTime, endTime) {
      this.$http({
        url: `/Record/liveNum?type=${this.type}&${
          this.type == 3
            ? 'sr'
            : this.type == 2
            ? 'sp'
            : this.isopencourse == 1 && this.type == 6
            ? 'live_history'
            : this.type == 6 || this.type == 7
            ? 'open_class'
            : 'sl'
        }_id=${this.id}&startTime=${startTime}&endTime=${endTime}`,
        callback: ({ data = [] }) => {
          if (this.myChart) {
            this.myChart.setOption(this.getOptionData(data), true, false)
          } else {
            this.init(data)
          }
        },
      })
    },

    onResize() {
      if (this.myChart) {
        this.myChart.resize()
      }
    },

    offResize() {
      window.removeEventListener('resize', this.onResize)
    },

    getOptionData(list) {
      const timeData = list.map((item) => ({
        value: item[0],
        textStyle: { color: '#666' },
      }))

      return {
        legend: {
          left: 0,
          data: ['在线人数', '学习人数'],
        },
        dataZoom: [
          {
            startValue: timeData[0] ? '' : timeData[0].value,
          },
          {
            type: 'inside',
            // zoomOnMouseWheel: false,
            // moveOnMouseWheel : false,
          },
        ],
        xAxis: {
          type: 'category',
          data: timeData,
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: '#82edff',
              width: 2.5,
            },
          },
        },
        yAxis: {
          type: 'value',
          min: 0,
          max(value) {
            return value.max > 4 ? null : value.max + 4
          },
          minInterval: 1,
          splitLine: {
            show: true,
          },
          axisLine: {
            show: false,
            onZero: false,
          },
          axisTick: {
            show: false,
          },
        },
        tooltip: {
          trigger: 'axis',
        },
        series: [
          {
            name: '在线人数',
            type: 'line',
            data: list.map((item) => item[1]),
            lineStyle: {
              color: '#1A66E9',
            },
            itemStyle: {
              color: '#1A66E9',
            },
            smooth: true,
          },
          {
            name: '学习人数',
            type: 'line',
            data: list.map((item) => item[2]),
            lineStyle: {
              color: '#FFD84D',
            },
            itemStyle: {
              color: '#FFD84D',
            },
            smooth: true,
          },
        ],
      }
    },

    init(list) {
      const myChart = (this.myChart = echarts.init(this.$refs.onlineMember))

      this.offResize()
      window.addEventListener('resize', this.onResize)

      // 指定图表的配置项和数据
      const option = this.getOptionData(list)

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option)
    },
    
  },
}
</script>
<style lang="scss" scoped>
.datePicker {
  font-size: 0;
  text-align: right;
  padding-right: 20px;
  position: absolute;
  right: 0;
  top: -14px;
  z-index: 3;
  .t1 {
    font-size: 12px;
    line-height: 16px;
    margin-right: 30px;
    color: rgba(102, 102, 102, 1);
  }
}
::v-deep .el-range-editor.el-input__inner {
  height: 30px;
  font-size: 12px;
}
::v-deep .el-date-editor .el-range__icon,
::v-deep .el-date-editor .el-range-separator,
::v-deep .el-date-editor .el-range__close-icon {
  line-height: 25px;
}
::v-deep .el-date-editor.el-input {
  width: 150px;
}
</style>
