<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="900px"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <div slot="title">
      <div class="flex-center">
        <div class="title_font">{{ title }}</div>
        <div class="redcolor tip">转码规格480p和360p限时免费</div>
      </div>
    </div>
    <upload
      ref="upload"
      :meanwhile="2"
      @changedilog="changedilog"
      @complete="complete"
      @getJgInfo="$emit('getJgInfo')"
      @uploadType="changeUploadType"
      :list="value2"
      :value="value"
      :radio="radio"
      :typeselection="typeselection"
      :menuName="menuName"
      accept="video/mp4, application/x-mpegurl"
      :maxSize="3*1024 * 1024 * 1024"
    >
      <template #uploadBtn="{ queue }">
        <el-button
          type="primary"
          class="mb20"
          :disabled="queue.length == 1 && typeselection == 2"
        >
          选择文件
        </el-button>
        <div class="cover" v-if="queue.length == 1 && typeselection == 2"></div>
        <!-- <div class="upload-btn">
          <span>选择文件</span>
        </div> -->
        <!-- <a class="aaaaRed">建议上传1G以内的视频，避免学生观看时加载过久，可以使用格式工厂进行压缩</a>
        <div class="t2">
          支持mp4、m3u8文件，单个不可超过1GB，上传过程中请勿关闭该页面，否则上传失败！最多可同时选20个文件，逐个排队上传；建议用谷歌或者360极速模式浏览器上传；文件名请勿包含特殊字符(#^;<>)，以免上传失败！
        </div> -->
      </template>
      <template #select="{ uploadError, uploadCompleteList }">
        <!-- <div v-if="this.$refs.upload.currUploadingFile.length"></div> -->
        <div v-if="heardstatus">
          <div class="proposal">
            支持mp4、m3u8文件，单个不可超过3GB，推荐上传500M以内的视频；最高同时支持2个文件上传；建议用谷歌或者360极速模式浏览器上传；文件名请勿包含特殊字符(#^;<>)，以免上传失败！
          </div>
          <div class="flex-center">
            <div class="right-select1">
              <span>
                转码视频
                <!-- <el-radio-group
                  style="margin: 0 100px 0 40px"
                  @change="videochange"
                  v-model="radio"
                >
                  <el-radio :label="2">
                    普通转码
                  </el-radio> -->
                  <!-- && is_drm == 2 -->
                  <!-- <el-radio :label="1" :disabled="true || is_drm == 2">
                    加密转码(升级中)
                    <span>
                      <el-popover
                        style="margin-left: 8px"
                        placement="top-start"
                        width="517"
                        trigger="hover"
                      >
                        <div class="popover_video">
                          <p>
                            目的：将视频转码加密，结合版权保护方案，能够有效保护网校视频盗版问题
                          </p>
                          <p class="mt20 mb20">
                            说明：加密转码仅支持720p和1080p，转码后会生成转码文件并保留源文件，会占用网校存储空间；转码会消耗叮豆，不同规格单价不同；可在网校账户>>扣费记录查看明细。
                          </p>
                          <p class="mb15">
                            说明：转码后会生成转码文件并保留原文件，会占用网校存储空间；转码规格大于720p及以上时，会消耗叮豆，不同规格单价不同；可在网校账户>>扣费记录查看明细。
                            <el-button
                              type="text"
                              class="fs12"
                              @click="opennew('/capitalAccount/TopUpDingDou')"
                            >
                              查看计费规则
                            </el-button>
                          </p>
                          <p class="redcolor">
                            温馨提示：如需加密转码请联系客服经理开通版权保护功能！
                          </p>
                        </div>
                        <span style="vertical-align: middle" slot="reference">
                          <img
                            style="
                              height: 14px;
                              width: 14px;
                              display: inline-block;
                            "
                            src="../../assets/img/1.2.9/ico_wh@2x.png"
                            alt=""
                          />
                        </span>
                      </el-popover>
                    </span>
                  </el-radio>
                </el-radio-group> -->
              </span>

              <span style="margin: 0 0px 0 40px">
                转码规格
                <span>
                  <el-popover
                    style="margin-right: 10px"
                    placement="top-start"
                    width="517"
                    trigger="hover"
                  >
                    <div class="popover_video">
                      <p>
                        目的：能在用户端更快更流畅的打开和播放；同时也节省网校流量成本
                      </p>
                      <p class="mt20 mb20">
                        自适应转码规则：自适应转码指转码规格自动按源视频的分辨率进行转码；1080p为蓝光，720p为高清，480p为标清，360p为流畅；大于1080p，转码成1080p，比特率2500kbps；小于1080p大于720p，转码成720p，比特率1800kbps；小于720P大于480p：转码成480p，比特率900kbps；小于480P大于360p：转码成360p，比特率400kbps
                      </p>
                      <p class="mb15">
                        说明：转码后会生成转码文件并保留原文件，会占用网校存储空间；转码规格大于720p及以上时，会消耗叮豆，不同规格单价不同；可在网校账户>>扣费记录查看明细。
                        <el-button
                          type="text"
                          class="fs12"
                          size="mini"
                          @click="opennew('/capitalAccount/TopUpDingDou')"
                        >
                          查看计费规则
                        </el-button>
                      </p>
                      <p class="redcolor">
                        温馨提示：如需更高清的转码服务，请联系客服经理了解更高级的云服务转码！
                      </p>
                    </div>
                    <span style="vertical-align: middle" slot="reference">
                      <img
                        class="cp"
                        style="
                          height: 14px;
                          width: 14px;
                          display: inline-block;
                        "
                        src="../../assets/img/1.2.9/ico_wh@2x.png"
                        alt=""
                      />
                    </span>
                  </el-popover>
                </span>
              </span>
              <el-select
                v-model="value"
                placeholder="请选择"
                style="width: 180px; margin-left: 40px"
              >
                <el-option
                  v-for="item in radio == 2 ? options : options2"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  :disabled="
                    radio == 2
                      ? charge_mode == 2 &&
                        (item.value == 1 || item.value == 2 || item.value == 5)
                      : item.value == 3 || item.value == 4 || item.value == 5
                  "
                ></el-option>
              </el-select>
            </div>
          </div>

          <div class="right-select2">
            <span>上传至</span>
            <el-select
              v-model="value2"
              placeholder="请选择"
              style="width: 350px; margin-left: 40px"
            >
              <el-option
                v-for="item in menuList"
                :key="item.group_id"
                :label="item.name"
                :value="item.group_id"
              ></el-option>
            </el-select>
          </div>
        </div>

        <div class="after_upload" v-else-if="$refs.upload.uploadingstatus">
          <!-- v-else-if="$refs.upload.currUploadingFile.length > 0" -->
          <div class="flex-center">
            <div style="margin-right: 68px">
              转码：{{ heardvalue.type }}-{{ heardvalue.Specifications }}
            </div>
            <div>分组：{{ heardvalue.group }}</div>
          </div>
          <div class="after_upload_font">
            提示：已选文件上传中，可添加新文件，不可修改分组和转码规格；如需修改，请等待当前列表文件上传完成后
          </div>
        </div>
        <div v-else>
          <div class="flex-center status3">
            <div style="margin-right: 68px">
              转码：{{ heardvalue.type }}-{{ heardvalue.Specifications }}
            </div>
            <div>分组：{{ heardvalue.group }}</div>
          </div>
          <div class="after_upload_font2">
            上传完成，成功{{ uploadCompleteList.length }}个，失败{{
              uploadError.length
            }}个
          </div>
        </div>
      </template>

      <template
        #list="{
          queue,
          removeFile,
          selectButtonStatus,
          newQueue,
          uploadingstatus,
        }"
      >
        <el-table
          :empty-text="
            newQueue == 0 && uploadingstatus
              ? '请点击左上方【选择文件】按钮添加视频，如需修改转码和分组属性，点击右下角返回'
              : '请点击左上方【选择文件】按钮添加视频，然后点击底部【开始上传】按钮'
          "
          :data="queue"
          :class="{ isafterupload: !heardstatus }"
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }"
        >
          <el-table-column prop="file_name" label="文件" width="300">
            <template slot-scope="{ row }">
              <div class="file_name_class">{{ row.file_name }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="file_size" label="大小">
            <template slot-scope="scope">
              {{ scope.row.file_size | getFileSize }}
            </template>
          </el-table-column>

          <el-table-column
            prop="speed"
            label="上传速度"
            v-if="selectButtonStatus"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.speed == 0">-</span>
              <span v-else-if="scope.row.speed > 1">
                {{ scope.row.speed }}Mb/s
              </span>
              <span v-else>{{ scope.row.speed * 1024 }}Kb/s</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="percent"
            label="上传进度"
            v-if="selectButtonStatus"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.percent == 0">-</span>
              <span v-else>{{ scope.row.percent }}%</span>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="状态">
            <template slot-scope="scope">
              <span v-if="scope.row.status === 0" style="color: #2791fb">
                上传成功
              </span>
              <span v-else-if="scope.row.status === 1">等待上传</span>
              <span v-else-if="scope.row.status === 2">上传中</span>
              <span v-else-if="scope.row.status === 5">删除中</span>
              <span v-else-if="scope.row.status === 6">删除失败</span>
              <span v-else style="color: #ff3535">上传失败</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" v-if="selectButtonStatus">
            <template slot-scope="scope">
              <div class="btns">
                <!-- <span
                  class="text"
                  @click="preViewVideo(scope.row.url)"
                  v-if="scope.row.status === 0"
                >
                  预览
                </span> -->
                <!-- <span
                  class="text"
                  style="color: #0aa29b"
                  @click="againUpload(scope.row.$index)"
                  v-if="scope.row.status === 3 || scope.row.status === 4"
                >
                  重新上传
                </span> -->
                <!-- <span
                  v-if="scope.row.status != 1 && scope.row.status != 2"
                  class="space"
                ></span> -->
                <span
                  class="text"
                  v-if="scope.row.status !== 0"
                  @click="removeFile(scope.row.$index)"
                >
                  {{
                    scope.row.status === 1
                      ? '移除 '
                      : scope.row.status === 2
                      ? '取消上传'
                      : '删除'
                  }}
                </span>
              </div>
            </template>
          </el-table-column>
          >
        </el-table>
      </template>

      <template #footer="{ beginUploadStatus, uploadingstatus, newQueue }">
        <div class="_center mb10" v-if="beginUploadStatus">
          <el-button
            type="primary"
            style="width: 100px; margin-top: 7px"
            size="medium"
            @click="begin_upload"
            :disabled="uploadType == 'uploading'"
          >
            开始上传
          </el-button>
        </div>
        <div class="warning-opennew" v-else-if="uploadingstatus">
          <i class="el-icon-warning redcolor"></i>
          <div class="redcolor mr10">
            上传过程中请勿关闭本页面，否则上传失败！如需要进行其他操作，请在新窗口打开。
          </div>
          <a @click="opennew('/')">点此打开</a>
          <a
            @click="continueupload"
            style="margin-left: 30px"
            v-if="newQueue == 0"
          >
            返回上一层
          </a>
        </div>
        <div class="_center mt10" v-else>
          <el-button
            size="medium"
            class="w100"
            @click="continueupload"
            v-if="typeselection != 2"
          >
            继续上传
          </el-button>
          <el-button
            type="primary"
            class="w100"
            size="medium"
            @click="closedialog"
          >
            完成
          </el-button>
        </div>
      </template>
    </upload>
    <!--视频预览-->
    <previewVideo
      v-if="previewSrc"
      :src="previewSrc"
      @close="preViewVideo"
    ></previewVideo>
  </el-dialog>
</template>
<script>
import upload from '@cm/base/Upload'
import previewVideo from '@cm/coursePop/previewVideo'

export default {
  name: 'uploadFile',

  props: {
    // 分组列表获取的类型
    groudType: {
      type: Number,
      default: 1,
    },
    title: {
      type: String,
    },
    typeselection: {
      type: Number,
      default: 1,
    },
    leftStorage: [Number, String],
    list: {
      default: 0,
    },
    // 分组名
    menuName: String,
  },

  components: {
    upload,
    previewVideo,
  },
  
  data() {
    return {
      //转码规格
      value: 4,
      //上传后顶部信息
      heardvalue: {},
      heardstatus: true,
      //1按量2不按量
      charge_mode: this.$store.state.userInfo.charge_mode,
      //1开启2，关闭
      is_drm: this.$store.state.userInfo.jg_is_drm,
      //1，加密转码 2，普通转码
      radio: 2,
      //分组列表
      menuList: [],
      //被选中的分组列表的id
      value2: Number(this.list),

      options: [
        {
          value: 3,
          label: '流畅(360p)',
        },
        {
          value: 4,
          label: '标清(480p)',
        },
        {
          value: 1,
          label: '高清(720p)',
        },
        {
          value: 2,
          label: '蓝光(1080p)',
        },
        {
          value: 5,
          label: '自适应',
        },
      ],
      options2: [
        {
          value: 1,
          label: '高清(720p)',
        },
        {
          value: 2,
          label: '蓝光(1080p)',
        },
      ],
      // value: 4,

      uploadSuccess: [],

      visible: true,

      previewSrc: '',

      uploadType:'',
    }
  },
  created() {
    this.getGroupList()
    if (this.charge_mode == 1) {
      this.value = 5
    }
  },

  filters: {
    getFileSize(value) {
      var size = value / 1024 / 1024 / 1024

      function getPointNum(num, n) {
        let str = String(num)
        let index = str.indexOf('.')
        let str1 = str.substring(0, index + n + 1)
        str1 = Number(str1)
        return str1
      }
      size = getPointNum(size, 3) * 1024

      return size > 0
        ? size.toFixed(2) + 'MB'
        : (0.001 * 1024).toFixed(2) + 'MB'
    },
  },

  methods: {
    // continueupload222() {
    //     this.destroyed11()
    //   },
    videochange(val) {
      if (val == 2) {
        if (this.charge_mode == 1) {
          this.value = 5
        } else {
          this.value = 4
        }
      } else {
        this.value = 1
      }
    },
    continueupload() {
      this.heardstatus = true
      this.$refs.upload.destroyed11()
    },
    closedialog() {
      this.visible = false
      this.$emit('uploadConfirmClose')
    },
    //点击完成，关闭弹窗
    changedilog(val) {
      this.visible = val
    },

    // begin_upload00() {
    //   if (this.queue.length == 0) {
    //     this.$root.prompt({ msg: '请先选择视频文件！' })
    //     return
    //   }
    //   this.beginUploadStatus = false
    //   this.next()
    //   this.$emit('click_begin_upload')
    // },

    //点击开始初始化数据
    begin_upload() {
      this.$store.dispatch('getAmount').then(() => {
        if (this.$refs.upload.queue.length == 0) {
          this.$root.prompt({ msg: '请先选择视频文件！' })
          return
        }
        if (this.$store.state.amount <= 0) {
          this.$root.prompt({
            msg: '叮豆不足，无法上传和转码视频！',
          })
          return
        }
        this.$refs.upload.beginUploadStatus = false
        this.$refs.upload.next()

        this.heardstatus = false
        let value = { type: '', Specifications: '', group: '' }
        if (this.radio == 2) {
          value.type = '普通转码'
          value.Specifications = this.options.find(
            f => f.value == this.value
          ).label
        } else {
          value.type = '加密转码'
          value.Specifications = this.options2.find(
            f => f.value == this.value
          ).label
        }
        value.group = this.menuList.find(f => f.group_id == this.value2).name
        this.heardvalue = value
      })
    },
    opennew(val) {
      let url = this.$router.resolve({
        path: val,
      })

      this.$store.dispatch('open', url.href)
    },
    //获取分组列表
    getGroupList() {
      this.$http({
        url: `/DataBank/groupList?type=${this.groudType}`,
        callback: ({ data }) => {
          this.menuList = data.map(item => {
            return Object.assign({}, item, { group_id: Number(item.group_id) })
          })
        },
      })
    },
    handleClose(done) {
      const close = () => {
        done()
        setTimeout(() => {
          this.$emit('close')
        }, 300)
      }
      if (this.$refs.upload.currUploadingFile.length > 0) {
        this.$confirm(
          '当前有文件正在上传，离开当前页面会上传失败，已上传成功的自动保存到资料库；是否确定离开？',
          '温馨提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }
        ).then(close)
      } else {
        close()
      }
    },
    // 视频上传成功后调接口
    complete(val) {
      this.$root.prompt({
        msg: '上传成功',
        type: 'success',
      })
      this.$emit('completelist', val)
      this.uploadSuccess.push(val.serverId)
      // 重新获取剩余空间
      this.$emit('getJgInfo')
    },

    // 预览视频
    preViewVideo(src) {
      this.previewSrc = typeof src === 'string' ? src : ''
    },
    // 设置上传状态
    changeUploadType(type){
      console.log('设置上传状态',type);
      this.uploadType = type
    },
  },
}
</script>
<style lang="scss" scoped>
.title_font {
  font-size: 14px;
  color: #333333;
}
.tip {
  font-size: 13px;
  font-weight: 500;
  color: #f46061;
  line-height: 18px;
  margin-left: 10px;
}
.cover {
  cursor: no-drop;
  height: 50px;
  width: 104px;
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
}
.file_name_class {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.after_upload {
  width: 660px;
  position: absolute;
  top: 62px;
  left: 154px;
  .after_upload_font {
    margin-top: 10px;
    font-size: 13px;
    color: #999999;
    line-height: 13px;
  }
}
.status3 {
  font-size: 14px;
  color: #333333;
  line-height: 14px;
}
.after_upload_font2 {
  margin: 10px 0 16px;
  font-size: 13px;
  color: #999999;
  line-height: 13px;
}
::v-deep .isafterupload {
  height: 375px;
  & .el-table__body-wrapper {
    height: 330px;
  }
}
.popover_video {
  font-size: 12px;
  color: #4a4a4a;
  line-height: 22px;
}
.aaaaRed {
  width: 441px;
  height: 12px;
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #ff3535;
  line-height: 12px;
}
::v-deep .el-table__empty-text {
  width: 100%;
}
// ::v-deep .el-input--suffix .el-input__inner {
//   border: 0;
// }
::v-deep .el-dialog {
  height: 550px;
}
// ::v-deep .el-table {
//   height: 244px;
// }
::v-deep .el-table__body-wrapper {
  height: 205px;
  overflow-y: auto;
}
::v-deep .el-table__header-wrapper {
  height: 42px;
}

::v-deep .el-dialog__body {
  padding: 10px 20px 0px;
}

.upload-btn {
  width: 100px;
  height: 36px;
  border-radius: 1px;
  font-size: 14px;
  color: #fff;
  background-color: rgba(10, 162, 155, 1);
  line-height: 38px;
  cursor: pointer;
  text-align: center;
  border: 1px solid rgba(10, 162, 155, 1);
  margin-bottom: 20px;
}
.proposal {
  font-size: 12px;
  color: #999999;
  line-height: 18px;
  width: 726px;
  position: absolute;
  top: 65px;
  left: 142px;
}

.right-select1,
.right-select2 {
  .t1 {
    font-size: 14px;
    font-weight: bold;
    color: rgba(10, 162, 155, 1);
    line-height: 19px;
  }
}
.right-select2 {
  margin-top: 20px;
  margin-bottom: 30px;
}

.t2 {
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 20px;
  color: rgba(153, 153, 153, 1);
  line-height: 16px;
}

.btns {
  @extend %btns;
}
</style>
