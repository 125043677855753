<template>
  <div id="playbackFootage">
    <slot></slot>
    <el-dialog
      :title="title"
      class="dialogVisible"
      width="850px"
      :visible="dialogstatus"
      @update:visible="v => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :close-on-click-modal="true"
      :destroy-on-close="true"
      @close="cancel"
    >
      <div class="dialogVisible_bottom">
        <el-tabs
          v-if="showAudioMenu"
          @tab-click="changtab"
          v-model="post.active"
        >
          <el-tab-pane label="直播课回放" name="1"></el-tab-pane>
          <el-tab-pane label="公开课回放" name="2"></el-tab-pane>
          <el-tab-pane label="素材库视频" name="3"></el-tab-pane>
        </el-tabs>
        <div class="header-box">
          <div>
            <el-button
              type="primary"
              size="medium"
              @click="uploadStart1 = !uploadStart1"
              v-if="
                ($route.params.sl_id && post.active == 1) ||
                  ($route.params.id && post.active == 2)
              "
            >
              本地上传
            </el-button>
            <el-button type="text" size="medium" class="ml20" @click="renew">
              刷新列表
            </el-button>
          </div>
          <div class="header">
            <template>
              <el-select
                v-if="post.active != 2"
                style="width: 160px; margin-right: 10px"
                v-model="post.is_video_encryption"
                placeholder="请选择"
                size="medium"
              >
                <el-option
                  v-for="item in options1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <el-select
                style="width: 160px; margin-right: 10px"
                v-model="post.state"
                placeholder="请选择"
                size="medium"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </template>
            <el-input
              style="width: 198px"
              size="medium"
              v-model.trim="post.search"
              placeholder="输入文件名称搜索"
            ></el-input>
          </div>
        </div>
        <div class="selectDialogBox">
          <div class="leftMenu">
            <el-menu :default-active="post.group_index">
              <el-menu-item
                v-for="(item, index) in MenuTreeData"
                :index="`${index}`"
                :key="item.group_id"
                @click="handleNodeClick(item, index)"
              >
                <div class="my-custom">
                  <div style="display: flex">
                    <img
                      :src="
                        require('../assets/img/1.4.0.2/icon_wjj备份 9@2x.png')
                      "
                      class="tree-icon"
                      alt=""
                    />
                    <span class="label" :title="item.name">
                      {{ item.name }}
                    </span>
                  </div>
                  <span class="span_2" style="color: rgb(165 162 162)">
                    {{ item.count ? item.count : '0' }}
                  </span>
                </div>
              </el-menu-item>
            </el-menu>
          </div>
          <div style="width:calc(100% - 200px)">
            <pagination2
              ref="child"
              :option="post"
              url="/DataBank/uploaded"
              :allFn="true"
              :key="post.active"
            >
              <template v-slot:default="{ tableData }">
                <div class="pagination2Box">
                  <el-table
                    ref="tablelist"
                    @selection-change="handleSelectionChange"
                    @row-click="rowClick"
                    @sort-change="sortChange"
                    :data="tableData"
                    :header-cell-style="{
                      background: 'rgba(245,245,245,1)',
                      color: '#333333',
                    }"
                    :row-key="getRowkey"
                  >
                    <el-table-column
                      v-if="selection == 2"
                      type="selection"
                      :reserve-selection="true"
                      width="55"
                    ></el-table-column>
                    <el-table-column label width="35" v-if="selection == 1">
                      <template slot-scope="scope">
                        <el-radio
                          :label="scope.row.recording_id || scope.row.video_id"
                          :value="selectionId[0]"
                        >
                          &nbsp;
                        </el-radio>
                        <!-- <el-checkbox
                          :label="scope.row.recording_id || scope.row.video_id"
                          :value="selectionId"
                          :disabled="
                            scope.row.transcode_status == 3 ||
                              scope.row.is_video_encryption == 3
                          "
                        >
                          &nbsp;
                        </el-checkbox> -->
                      </template>
                    </el-table-column>

                    <el-table-column
                      prop="file_name"
                      label="视频"
                      min-width="220"
                      sortable="custom"
                    >
                      <template slot-scope="scope">
                        <div class="TitleVideo">
                          <div class="imgVideo">
                            <lazy
                              class="img"
                              :src="
                                scope.row.video_img
                                  ? scope.row.video_img
                                  : require('../assets/img/1.3.9/icon_spbsR.png')
                              "
                              alt=""
                            />
                            <div
                              class="encryption"
                              v-if="scope.row.zm_spec && scope.row.zm_spec != 5"
                            >
                              <span>{{ scope.row.zm_spec | getzm_spec }}</span>
                              <!-- <span v-if="scope.row.zm_spec == 1">720p</span>
                          <span v-else-if="scope.row.zm_spec == 2">1080p</span>
                          <span v-else>-</span> -->
                            </div>
                            <div class="videoTime">
                              <p>
                                {{
                                  (scope.row.chapter_hour ||
                                    scope.row.duration) | formatTimeLength
                                }}
                              </p>
                            </div>
                          </div>
                          <div class="textVideo">
                            <p :title="scope.row.file_name">
                              {{ scope.row.file_name || scope.row.name }}
                            </p>
                            <h4 v-if="scope.row.course_name">
                              课程: {{ scope.row.course_name }}
                            </h4>
                            <h5>
                              <template v-if="post.active != 2">
                                <img
                                  v-if="scope.row.is_video_encryption == 1"
                                  src="../assets/img/1.3.9.4/icon_dg.png"
                                  alt=""
                                />
                                <img
                                  v-else-if="scope.row.is_video_encryption == 2"
                                  src="../assets/img/1.3.9.4/icon_cw.png"
                                  alt=""
                                />
                                <img
                                  style="
                                    height: 10px;
                                    width: 10px;
                                    margin-top: 2px;
                                  "
                                  v-else
                                  src="../assets/img/1.3.9.4/icon_zmz.png"
                                  alt=""
                                />
                                <span v-if="scope.row.is_video_encryption == 1">
                                  已加密
                                </span>
                                <span v-if="scope.row.is_video_encryption == 2">
                                  未加密
                                </span>
                                <span v-if="scope.row.is_video_encryption == 3">
                                  加密中
                                </span>
                              </template>

                              <!-- 1未转码  2已转码  3转码中 -->
                              <span
                                :class="post.active != 2 ? 'ml10' : ''"
                                class="flex-center"
                              >
                                <img
                                  v-if="scope.row.transcode_status == 2"
                                  src="../assets/img/1.3.9.4/icon_dg.png"
                                  alt=""
                                />
                                <img
                                  v-else-if="scope.row.transcode_status == 1"
                                  src="../assets/img/1.3.9.4/icon_cw.png"
                                  alt=""
                                />
                                <img
                                  v-else
                                  style="
                                    height: 10px;
                                    width: 10px;
                                    margin-top: 2px;
                                  "
                                  src="../assets/img/1.3.9.4/icon_zmz.png"
                                  alt=""
                                />
                                <span v-if="scope.row.transcode_status == 2">
                                  视频已转码
                                </span>
                                <span v-if="scope.row.transcode_status == 1">
                                  视频未转码
                                </span>
                                <span v-if="scope.row.transcode_status == 3">
                                  视频转码中
                                </span>
                              </span>
                            </h5>
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="add_time"
                      sortable="custom"
                      label="生成时间"
                    >
                      <template slot-scope="scope">
                        {{
                          (scope.row.ctime || scope.row.add_time)
                            | formatTimeStamp('yyyy-MM-dd hh:mm')
                        }}
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </template>
            </pagination2>
          </div>
        </div>

        <div style="text-align: right; height: 56px" class="mr20">
          <el-button @click="close" size="medium" style="width: 74px">
            取消
          </el-button>
          <el-button
            type="primary"
            size="medium"
            @click="add"
            style="width: 74px"
          >
            确定
          </el-button>
        </div>
      </div>
    </el-dialog>

    <!--上传视频-->
    <videoUpload
      v-if="uploadStart1"
      :leftStorage="leftStorage"
      :list="post.group_id"
      @close="uploadClose"
      @uploadConfirmClose="uploadClose"
      @getJgInfo="getJgInfo"
      :menuName="isMenuName"
      :typeselection="1"
      :groudType="post.type"
      title="上传视频"
    ></videoUpload>
  </div>
</template>

<script>
import videoUpload from '@cm/base/UploadFile'
export default {
  name: 'playbackFootage',
  data() {
    return {
      uploadStart1: false,

      post: {
        type: 2,
        active: this.singgleType == 2 ? '2' : '1',
        search: '', //搜索内容
        state: '0',
        order: '',
        sort: '', //  排序顺序   1正  2倒叙
        is_video_encryption: this.jimiType,
        group_id: '0',
        group_index: '0',
      },

      isMenuName: '全部视频',

      videoData: [],

      MenuTreeData: [],

      options1: [
        {
          value: '0',
          label: '全部加密状态',
        },
        {
          value: '2',
          label: '未加密',
        },
        {
          value: '1',
          label: '已加密',
        },
        {
          value: '3',
          label: '加密中',
        },
      ],

      options: [
        {
          value: '0',
          label: '全部转码状态',
        },
        {
          value: '1',
          label: '视频未转码',
        },
        {
          value: '2',
          label: '视频已转码',
        },
        {
          value: '3',
          label: '视频转码中',
        },
      ],
    }
  },

  created() {
    this.getJgInfo()
  },

  components: {
    videoUpload,
  },

  props: {
    // 选择固定的加密类型0,全部类型 1，已加密 2， 未加密 3，加密中
    jimiType: {
      type: String,
      default: '0',
    },
    showAudioMenu: {
      type: Boolean,
      default: true,
    },
    dialogstatus: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '请选择视频',
    },
    //1单选 2多选
    selection: {
      type: Number,
      default: 2,
    },
  },

  filters: {
    getzm_spec(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '720P'
          break
        case 2:
          text = '1080P '
          break
        case 3:
          text = '360P'
          break
        case 4:
          text = '480P'
          break
        default:
          '-'
      }
      return text
    },
  },

  watch: {
    'post.active': {
      handler() {
        this.datalist = []
        this.videoData = []
        this.post.group_id = '0'
        this.post.group_index = '0'
        this.$nextTick(this.renew)
      },
    },
    dialogstatus() {
      this.post = {
        type: 2,
        active: this.singgleType == 2 ? '2' : '1',
        search: '', //搜索内容
        state: '0',
        order: '',
        sort: '', //  排序顺序   1正  2倒叙
        is_video_encryption: this.jimiType,
        group_id: '0',
        group_index: '0',
      }
      this.$nextTick(this.renew)
    },
  },

  computed: {
    selectionId() {
      return this.videoData.map(item => item.serverId)
    },
  },

  methods: {
    getRowkey(row) {
      return row.recording_id || row.video_id
    },

    handleSelectionChange(val) {
      this.videoData = val
    },

    // 上传弹窗关闭
    uploadClose() {
      this.uploadStart1 = false 
      this.$emit('uploadConfirmClose')
      this.renew()
    },

    newreset() {
      if (this.dialogstatus) {
        this.$refs.child.reset()
      }
    },

    changtab() {
      this.selectAll = false
      this.videoData = []
      // type 2 直播、 6 公开课 1 录播
      this.post.type = this.post.active == 1 ? 2 : this.post.active == 2 ? 6 : 1
      this.post.search = ''
      this.post.state = '0'
    },

    // 获取机构存储空间
    getJgInfo() {
      this.$http({
        url: '/User/jigouAuth',
        isMultipleSelf: 'replacePrev',
        callback: ({ data: { useStorage, jigouStorage, leftStorage } }) => {
          this.useStorage = useStorage
          this.jigouStorage = jigouStorage
          this.leftStorage = Math.floor(leftStorage * 100) / 100
          this.$store.commit('setLeftStorage', leftStorage)
        },
      })
    },

    add() {
      if (this.selectionId.length === 0) {
        this.close()
        return
      }
      if (this.selection == 1) {
        this.$emit('complete', this.videoData[0])
      } else {
        let arr = []
        this.videoData.forEach(item => {
          arr.push(this.change(item))
        })
        this.$emit('complete', arr)

        // this.$emit('complete', this.videoData, this.teacher_id)
      }
      this.close()
    },

    rowClick(row) {
      clearTimeout(this.rowClickTimer)
      if (row.transcode_status == 3 || row.is_video_encryption == 3) {
        return false
      } else {
        this.rowClickTimer = setTimeout(() => {
          // const id = row.recording_id || row.video_id
          if (this.selection == 1) {
            this.videoData = []
            this.videoData.push(this.change(row))
          } else {
            // const index1 = this.videoData.findIndex(item => item.serverId == id)
            // if (index1 > -1) {
            //   this.videoData.splice(index1, 1)
            // } else {
            //   this.change(row)
            // }
          }
        }, 100)

        if (this.selection == 2) {
          this.$refs.tablelist.toggleRowSelection(row)
        }
      }
    },

    // 选择视频
    change({
      recording_id,
      video_id,
      duration,
      chapter_hour,
      url,
      video_url,
      size,
      video_size,
      file_name,
      name,
      vid,
      zmvidoe_url,
      is_video_encryption,
      zm_spec,
    }) {
      return {
        serverId: recording_id || video_id,
        classHour: chapter_hour || duration,
        url: video_url || url,
        file_size: (video_size || size) * 1024 * 1024,
        file_name: file_name || name,
        vid,
        is_video_encryption,
        zm_spec,
        zmvidoe_url,
        recording_id,
        video_id,
      }
    },

    // 选择视频
    // change({
    //   recording_id,
    //   video_id,
    //   duration,
    //   chapter_hour,
    //   url,
    //   video_url,
    //   size,
    //   video_size,
    //   file_name,
    //   name,
    //   vid,
    //   zmvidoe_url,
    //   is_video_encryption,
    //   zm_spec,
    // }) {
    //   this.videoData.push({
    //     serverId: recording_id || video_id,
    //     classHour: chapter_hour || duration,
    //     url: video_url || url,
    //     file_size: (video_size || size) * 1024 * 1024,
    //     file_name: file_name || name,
    //     vid,
    //     is_video_encryption,
    //     zm_spec,
    //     zmvidoe_url,
    //     recording_id,
    //     video_id,
    //   })
    // },

    close() {
      this.visible = false
      this.$emit('close')
      this.videoData = []
      //   setTimeout(() => {
      //   }, 300)
    },

    sortChange(value) {
      var order = 0
      var sort = 0
      switch (value.order) {
        case null:
          break
        case 'descending':
          sort = 2
          break
        case 'ascending':
          sort = 1
          break
      }
      if (value.prop == 'ctime') {
        order = 2
      } else {
        order = 1
      }
      this.post.order = order
      this.post.sort = sort
    },

    handleNodeClick(data, index) {
      this.post.group_id = data.group_id
      this.post.group_index = `${index}`
    },

    getTreeMenu() {
      this.$http({
        url: '/DataBank/groupList',
        data: {
          type: this.post.type,
          is_video_encryption: this.jimiType,
        },
        callback: ({ data }) => {
          this.MenuTreeData = data
        },
      })
    },

    //刷新列表
    renew() {
      this.getTreeMenu()
      this.newreset()
    },

    cancel() {
      // 取消后回复选择数据原样
      this.videoData = []
      this.close()
      this.$emit('update:dialogstatus', false)
    },
  },
}
</script>

<style lang="scss" scoped>
#playbackFootage {
  ::v-deep .uploadRecordCourse {
    padding: 0 20px;
  }
  ::v-deep .el-menu {
    border: 0 !important;
  }
  ::v-deep .el-menu-item:hover,
  .el-menu-item:focus {
    background-color: #f0f8ff;
  }
  ::v-deep .el-menu-item {
    height: 36px;
    line-height: 36px;
    padding-left: 0 !important;
  }
  ::v-deep .el-menu-item.is-active {
    background-color: #ecf5ff;
  }
  ::v-deep .el-table {
    width: 100% !important;
    margin-left: 0;
  }

  .selectDialogBox {
    display: flex;
    position: relative;
    margin: 0 20px 10px;
    .pagination2Box {
      overflow: auto;
      height: 328px;
      @extend %scroll;

      ::v-deep .el-checkbox__input.is-disabled .el-checkbox__inner {
        background-color: #e5e5e5;
      }

      .className {
        display: flex;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        img {
          width: 24px;
          height: 30px;
        }

        p {
          margin-left: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-top: 3px;
        }
      }
      .TitleVideo {
        display: flex;

        .imgVideo {
          background: #000;
          margin-right: 10px;
          width: 141px;
          height: 80px;
          position: relative;
          overflow: hidden;
          border-top-right-radius: 4px;
          border-top-left-radius: 4px;

          .img {
            border-radius: initial;
            margin: 0 auto;
            width: auto;
            height: 100%;
            margin: 0 auto;
          }

          .encryption {
            position: absolute;
            top: 0;
            width: 45px;
            height: 22px;
            border-radius: 0px 0px 11px 0px;
            overflow: hidden;
            background: #fa6400;

            span {
              font-size: 12px;
              color: #ffffff;
              line-height: 18px;
              margin-left: 4px;
            }
          }

          .videoTime {
            width: 100%;
            height: 20px;
            background: rgba(0, 0, 0, 0.5);
            position: absolute;
            bottom: 0;
            text-align: center;

            p {
              height: 12px;
              font-size: 14px;
              color: #ffffff;
              line-height: 20px;
            }
          }
        }

        .textVideo {
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          p {
            min-width: 168px;
            font-size: 14px;
            color: #333333;
            overflow: hidden;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          h4 {
            font-size: 12px;
            color: #999999;
            line-height: 14px;
            margin-top: 15px;
            font-weight: normal;
          }

          h5 {
            display: flex;
            align-items: center;
            font-size: 12px;
            color: #999999;
            line-height: 14px;
            margin-top: 13px;
            font-weight: normal;

            img {
              margin-right: 6px;
              height: 8px;
              width: 8px;
            }
          }
        }
      }
    }

    .leftMenu {
      width: 200px;
      flex-shrink: 0;
      overflow: auto;
      height: 396px;
      overflow-x: hidden;
      @extend %scroll;

      border: 1px solid #e4e4e4;

      ::v-deep .el-tree-node__content {
        height: 36px;
      }

      ::v-deep .el-tree-node__expand-icon {
        font-size: 22px;
        display: none;
      }

      ::v-deep .el-tree-node:focus > .el-tree-node__content {
        background: #ebf8f7;
      }

      ::v-deep .el-tree-node__content:hover {
        background: #ebf8f7;
      }

      ::v-deep .el-tree-node:focus > .el-tree-node__content {
        background: #ebf8f7;
      }

      .my-custom {
        display: flex;
        margin-left: 10px;
        height: 30px;
        width: 185px;
        justify-content: space-between;

        img {
          width: 14px;
          height: 14px;
          margin-right: 11px;
          margin-top: 7px;
        }

        .label {
          width: 125px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          line-height: 30px;
        }

        .span_2 {
          line-height: 30px;
          text-align: right;
          margin-right: 5px;
        }
      }
    }
  }

  ::v-deep .el-tabs__nav-wrap::after {
    height: 1px;
  }
  ::v-deep .el-dialog__body {
    padding: 0;
  }

  .header-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 20px;
  }
}
</style>
