<template>
  <div id="memberManagement">
    <div class="search">
      <div class="left">
        <el-button type="primary" @click="statusAddMember = true">
          添加学员
        </el-button>
        <el-button @click="delAllOpenClassUser">
          全部移出
        </el-button>
      </div>
      <div class="flex-center">
        <el-button
          type="text"
          @click="$router.push({ path: '/manageStudent', query: { type: 1 } })"
        >
          前往黑名单
        </el-button>
        <el-select
          v-model="post2.play_type"
          class="ml30 mr10"
          style="width: 120px"
          size="medium"
          placeholder="请选择"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-select
          v-model="post2.filter_type"
          class="mr10"
          style="width: 120px"
          size="medium"
          placeholder="请选择"
        >
          <el-option
            v-for="item in options2"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-input
          style="width: 218px"
          v-model="post2.search"
          size="medium"
          @keyup.enter.native="post = Object.assign({}, post2)"
          placeholder="输入搜索内容"
        ></el-input>
        <el-button
          class="ml10"
          size="medium"
          type="primary"
          @click="post = Object.assign({}, post2)"
        >
          筛选
        </el-button>
      </div>
    </div>

    <pagination2
      :option="post"
      url="/openClass/openClassUser"
      ref="childDialogBox"
      @complete="completeListDate"
    >
      <template v-slot:default="{ tableData }">
        <el-table
          ref="tablelist"
          @selection-change="handleSelectionChange"
          @select-all="selectAll"
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column label="" type="selection"></el-table-column>

          <el-table-column label="用户ID" prop="user_id"></el-table-column>

          <el-table-column label="学员" min-width="150">
            <template slot-scope="{ row }">
              <div class="membership">
                <div class="tu">
                  <img
                    style="width: 22px; height: 22px"
                    :src="row.uphoto"
                    alt=""
                  />
                </div>
                <div class="right" :title="row.uname">
                  {{ row.uname }}
                  <!-- <span v-if="row.student_remarks">
                    ({{ row.student_remarks }})
                  </span> -->
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            label="真实姓名"
            prop="student_remarks"
          ></el-table-column>

          <el-table-column label="加入时间" prop="c_time" min-width="90px">
            <template slot-scope="{ row }">
              {{ row.c_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
            </template>
          </el-table-column>

          <!-- <el-table-column label="实名" prop="urealname">
            <template slot-scope="{ row }">
              <span v-if="row.urealname">
                {{ row.urealname }}
              </span>
              <span v-else>无</span>
            </template>
          </el-table-column> -->

          <el-table-column label="手机号码">
            <template slot-scope="{ row }">
              <span v-if="row.umobile">
                {{ row.umobile }}
              </span>
              <span v-else>无</span>
            </template>
          </el-table-column>

          <el-table-column label="类型" prop="address">
            <template slot-scope="{ row }">
              {{ row.play_type | getplay_type }}
            </template>
          </el-table-column>

          <el-table-column label="操作" align="right" min-width="100px">
            <template slot-scope="{ row }">
              <el-button
                type="text"
                v-if="row.play_type == 3"
                @click="toOrder(row)"
              >
                查看订单
              </el-button>
              <span class="sp_drive" v-if="row.play_type == 3">|</span>
              <el-button type="text" @click="block(row.user_id)">
                移出直播
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template #footer>
        <div class="footer">
          <el-checkbox
            :indeterminate="isIndeterminate"
            v-model="checked"
            @change="boxChange($event)"
          ></el-checkbox>
          <span class="ml10">已选择{{ selectedIds.length }}条</span>
          <el-button
            style="width: 130px"
            class="ml20"
            size="medium"
            @click="block()"
            :disabled="selectedIds.length == 0"
          >
            <!-- :disabled="selectedIds.length == 0" -->
            批量移出直播
          </el-button>
        </div>
      </template>
    </pagination2>
    <!-- 添加学员 -->
    <newaddmember
      :type="3"
      v-if="statusAddMember"
      :dialogstatus.sync="statusAddMember"
      @complete="complete"
    ></newaddmember>
    <!-- 提示 -->
    <tip
      v-if="status"
      @sure="sure"
      :list="list"
      :dialogstatus.sync="status"
    ></tip>

    <el-dialog
      title="选择课程(单选)"
      :visible.sync="addCourseBool"
      width="800px"
    >
      <add-student-dialog ref="dialogRef"></add-student-dialog>
    </el-dialog>
  </div>
</template>
<script>
import newaddmember from '@cm/base/newaddmember'
import tip from '../components/tip'
export default {
  name: 'memberManagement',
  components: {
    tip,
    newaddmember,
  },
  data() {
    return {
      // 自定义全选框
      checked: false,
      // 未全部选中的状态
      isIndeterminate: false,
      // 当前选中的个数
      selectedNum: 0,
      // 当前列表数据的长度
      tableLength: 0,

      // 添加学员
      statusAddMember: false,

      //多个选择的id
      selectedIds: [],
      //单个移出直播的id
      user_ids: [],

      list: {},

      status: false,

      post: {
        open_class_id: this.$route.params.id,
        search: '',
        play_type: 0,
        filter_type: 2,
      },
      post2: {
        open_class_id: this.$route.params.id,
        search: '',
        filter_type: 2,
        play_type: 0,
      },

      input: '',

      course: [],

      addCourseBool: false,

      options: [
        {
          value: 0,
          label: '全部',
        },
        {
          value: 1,
          label: '免费',
        },
        {
          value: 2,
          label: '密码',
        },
        {
          value: 3,
          label: '付费',
        },
        {
          value: 4,
          label: '手动添加',
        },
        {
          value: 5,
          label: '超级会员',
        },
        {
          value: 6,
          label: '直播课关联',
        },
      ],

      options2: [
        {
          value: 1,
          label: '用户ID',
        },
        {
          value: 2,
          label: '学员昵称',
        },
        {
          value: 3,
          label: '真实姓名',
        },
        {
          value: 4,
          label: '手机号码',
        },
      ],

      value: 0,
    }
  },

  computed: {
    open_class_id() {
      return this.$route.params.id
    },
  },

  filters: {
    getplay_type(val) {
      let text
      switch (Number(val)) {
        case 1:
          text = '免费 '
          break
        case 2:
          text = '密码'
          break
        case 3:
          text = '付费'
          break
        case 4:
          text = '手动添加'
          break
        case 5:
          text = '超级会员'
          break
        case 6:
          text = '直播课关联'
          break
      }
      return text
    },
  },

  watch: {
    selectedNum(val) {
      if (val > 0) {
        if (val == this.tableLength) {
          this.checked = true
          this.isIndeterminate = false
        } else if (val < this.tableLength) {
          this.isIndeterminate = true
          this.checked = false
        }
      } else {
        this.isIndeterminate = false
        this.checked = false
      }
    },
  },

  methods: {
    toOrder(row) {
      this.$router.push({
        path: '/capitalOrder/opencourseDetail/' + row.open_class_order_id,
      })
    },

    // 全部移出
    async delAllOpenClassUser() {
      this.$confirm('是否确定移出全部学员？', '全部移出', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(async () => {
          await this.$http({
            url: '/openClass/delAllOpenClassUser',
            data: {
              open_class_id: this.open_class_id,
            },
            successMsg: true,
          })

          this.$refs.childDialogBox.reset()
        })
        .catch(() => {})
    },

    // 自定因全选的状态
    selectAll(val) {
      if (val.length == 0) {
        this.checked = false
      } else {
        this.checked = true
      }
    },

    // 列表下边的多选
    boxChange(val) {
      // this.tablelistLength = val2.list.length
      if (val) {
        this.$refs.tablelist.toggleAllSelection()
      } else {
        this.$refs.tablelist.clearSelection()
      }
    },
    // 获取列表数据
    completeListDate(val) {
      this.tableLength = val.data && val.data.length
    },

    // 添加学员
    complete(val) {
      const uids = val.map(item => item.uid)
      this.$http({
        url: '/openClass/addUser',
        data: {
          open_class_id: this.open_class_id,
          uids: uids,
        },
        callback: () => {
          this.$root.prompt({
            type: 'success',
            msg: '操作成功',
          })
          this.$refs.childDialogBox.reset()
        },
        error: () => {
          this.$root.prompt('操作失败')
        },
      })
      // console.log(uids)
    },

    handleSelectionChange(val) {
      this.selectedIds = val.map(m => m.user_id)
      this.selectedNum = val.length
    },

    sure() {
      this.$http({
        url: '/openClass/delOpenClassUser',
        data: {
          open_class_id: this.open_class_id,
          user_ids: this.user_ids,
        },
        callback: () => {
          this.$root.prompt({
            type: 'success',
            msg: '操作成功',
          })
          this.$refs.childDialogBox.reset()
          this.status = false
        },
        error: () => {
          this.$root.prompt('操作失败')
        },
      })
    },

    block(val) {
      this.list = {
        type: 1,
        contain:
          '是否确定移出直播？移出后需要重新加入，如为付费直播，需重新购买！',
      }
      if (val) {
        this.user_ids = [val]
      } else {
        this.user_ids = this.selectedIds
        if (this.user_ids.length == 0) {
          this.$root.prompt('请先选择内容')
          return
        }
      }
      this.$nextTick(() => {
        this.status = true
      })
    },
  },
}
</script>
<style lang="scss" scoped>
#memberManagement {
  .search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .membership {
    display: flex;
    align-items: center;
    .tu {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      margin-right: 10px;
    }
    .right {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 14px;
      color: #333333;
      line-height: 14px;
    }
  }
  .sp_drive {
    color: #1b9d97;
    margin: 0 10px;
  }
  .footer {
    margin-top: 30px;
    span {
      font-size: 14px;
      color: #333333;
      line-height: 19px;
    }
  }
}
</style>
