<template>
  <div class="subscription">
    <!-- <div class="flex-center container1">
      <el-button type="primary" class="notice">发送上课通知</el-button>
      <span class="font1">
        已报名的学员可以订阅上课通知，只有订阅后才能收到推送；系统默认开课前15分钟发送，如学生未收到可手动发送
      </span>
    </div> -->
    <!-- 列表部分 -->
    <div class="container2">
      <pagination2
        @tableDate="tableDate"
        ref="reset"
        :option="post"
        url="/course/subscriptionList"
        @complete="loading = false"
      >
        <template v-slot:default="{ tableData }">
          <el-input
            v-model="post.search"
            class="fr input"
            placeholder="输入学员昵称/真实姓名搜索"
          ></el-input>
          <el-select
            class="fr select condition"
            v-model="post.status"
            placeholder="请先选择内容"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-table
            :header-cell-class-name="nullselect"
            ref="multipleTable"
            @select-all="allselect"
            :cell-class-name="checkbox"
            @selection-change="handleSelectionChange"
            :data="tableData"
            :header-cell-style="{
              background: 'rgba(245,245,245,1)',
              color: '#333333',
            }"
          >
            <el-table-column
              type="selection"
              :selectable="checkboxInit"
              width="50px"
            ></el-table-column>
            <el-table-column label="学员" prop="name">
              <template slot="header">
                <div class="flex-center">
                  学员
                  <helpIcon
                    style="margin-left: 6px"
                    prompt="已报名并订阅的学员会在列表显示，订阅后取消也会显示；只报名未订阅的学员不会显示"
                  ></helpIcon>
                </div>
              </template>
              <template slot-scope="{ row }">
                <div class="tu1 fl mr10">
                  <img :src="row.uphoto" width="20" height="20" alt="" />
                </div>
                <span>{{ row.uname }}</span>
                <span v-if="row.student_remarks">
                  ({{ row.student_remarks }})
                </span>
              </template>
            </el-table-column>

            <el-table-column label="真实姓名">
              <template slot-scope="scope">
                {{ scope.row.student_remarks || '-' }}
              </template>
            </el-table-column>

            <el-table-column label="订阅时间">
              <template slot-scope="scope">
                {{ scope.row.c_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
              </template>
            </el-table-column>
            <el-table-column label="订阅状态" prop="status">
              <template slot-scope="scope">
                <span v-if="scope.row.status == 1" style="color: #f39802">
                  已订阅
                </span>
                <span v-if="scope.row.status == 2" style="color: #ff3535">
                  已取消
                </span>
              </template>
            </el-table-column>
            <el-table-column label="最后操作时间">
              <template slot-scope="scope">
                {{ scope.row.u_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
              </template>
            </el-table-column>
            <el-table-column label="最后操作人" prop="operator_name">
              <template slot-scope="scope">
                {{ scope.row.operator_name }}
                <span v-if="scope.row.operator_remarks">
                  ({{ scope.row.operator_remarks }})
                </span>
              </template>
            </el-table-column>
            <!-- <el-table-column label="操作人角色" prop="operator_role">
              <template slot-scope="scope">
                {{ scope.row.operator_role || '--' }}
              </template>
            </el-table-column> -->
            <el-table-column label="操作" width="180">
              <template slot-scope="scope">
                <el-button
                  v-if="scope.row.status == 1"
                  type="text"
                  @click="cancel(scope.row)"
                >
                  取消订阅
                </el-button>
                <el-button type="text" v-else>--</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="flex-center mt20 button2">
            <el-checkbox
              :indeterminate="isIndeterminate"
              v-model="checked"
              @change="boxChange($event)"
            ></el-checkbox>
            <div class="font2 mr20 ml10">已选择{{ beselect.length }}条</div>
            <el-button type="" @click="cancels">批量取消订阅</el-button>
          </div>
        </template>
      </pagination2>
    </div>
  </div>
</template>
<script>
import helpIcon from '@/components/helpIcon'
export default {
  name: 'subscription',
  data() {
    return {
      // 自定义全选框
      checked: false,
      // 未全部选中的状态
      isIndeterminate: false,
      // 当前选中的个数
      selectedNum: 0,
      // 当前列表数据的长度
      tableLength: 0,

      post: {
        status: 0,
        search: '',
        course_id: this.$route.query.course_id || undefined,
        open_class_id: this.open_class_id || undefined,
      },

      options: [
        {
          value: 0,
          label: '全部状态',
        },
        {
          value: 1,
          label: '已订阅',
        },
        {
          value: 2,
          label: '已取消',
        },
      ],

      beselect: [],

      tableList: [],
    }
  },

  props: {
    //1是其他课程的 2是公开课的
    open_class_id: {
      type: Number,
      default: undefined,
    },
  },

  components: {
    helpIcon,
  },

  watch: {
    selectedNum(val) {
      let outselect = this.tableList.filter(f => f.status == 1).length
      if (val > 0) {
        if (val == outselect) {
          this.checked = true
          this.isIndeterminate = false
        } else if (val < outselect) {
          this.isIndeterminate = true
          this.checked = false
        }
      } else {
        this.isIndeterminate = false
        this.checked = false
      }
    },
  },

  methods: {
    // tableList
    // 列表下边的多选
    boxChange(val) {
      // this.tablelistLength = val2.list.length
      if (val) {
        this.$refs.multipleTable.toggleAllSelection()
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },

    //不满足条件不可选判断
    checkboxInit(row) {
      if (row.status == 2) {
        return 0 //不可勾选
      } else {
        return 1
      }
    },

    //获取端口传回的数据
    tableDate(val) {
      this.tableList = val || []
      this.tableLength = val.length
    },

    //当没有被选择时全部选择按钮的样式
    nullselect({ rowIndex, columnIndex }) {
      let outselect = this.tableList.filter(f => f.status == 1).length
      if (outselect == 0) {
        if (rowIndex == 0 && columnIndex == 0) {
          return 'noselect'
        }
      }
    },
    //判断列表行中是否显示选择按钮
    checkbox(row) {
      if (row.row.status === '2' && row.columnIndex === 0) {
        return 'mycell'
      }
    },

    //选取选中的数据
    handleSelectionChange(val) {
      this.beselect = val.filter(f => f.status == 1)
      this.selectedNum = this.beselect.length
    },

    //获取全部选择时的数据
    allselect(val) {
      if (val.length == 0) {
        this.checked = false
      } else {
        this.checked = true
      }
      this.beselect = val.filter(m => m.status != 2)
      if (this.beselect.length == 0) {
        this.$refs.multipleTable.clearSelection()
      }
    },
    //批量取消订阅
    cancels() {
      if (this.beselect.length > 0) {
        let ids = this.beselect.map(m => m.id)
        this.$confirm(
          '取消后所选学员无法收到该堂课的上课通知！学员在课程目录和直播间可再次订阅',
          '取消订阅',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }
        )
          .then(() => {
            this.$http({
              url: '/course/setSubscription',
              data: {
                id: ids,
              },
              callback: () => {
                this.$refs.reset.reset()
                this.$emit('subscriptionUpdate')
              },
            })
          })
          .catch(() => {})
      } else {
        this.$root.prompt({
          msg: '请先选择内容，只可选中已订阅的学员！',
        })
      }
    },
    //取消订阅
    cancel(val) {
      this.$confirm(
        '取消后所选学员无法收到该堂课的上课通知！学员在课程目录和直播间可再次订阅',
        '取消订阅',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }
      )
        .then(() => {
          this.$http({
            url: '/course/setSubscription',
            data: {
              id: [val.id],
            },
            callback: () => {
              this.$refs.reset.reset()
              this.$emit('subscriptionUpdate')
            },
          })
        })
        .catch(() => {})
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .noselect .el-checkbox__inner {
  cursor: no-drop;
}
::v-deep .pagination {
  padding: 28px 0 8px;
}
::v-deep .mycell .el-checkbox .el-checkbox__input {
  display: none;
}
.subscription {
  padding-bottom: 20px;
  padding-right: 20px;
  .container1 {
    margin-bottom: 20px;
    margin-top: 20px;
    .notice {
      width: 114px;
      height: 36px;
      padding: 8px 15px;
    }
    .font1 {
      font-size: 12px;
      color: rgba(102, 102, 102, 1);
      line-height: 16px;
      margin-left: 20px;
    }
  }
  .container2 {
    .condition {
      margin-bottom: 20px;
    }
    .input {
      width: 218px;
      height: 36px;
    }
    .select {
      width: 120px;
      height: 36px;
      margin-right: 10px;
    }
    .tu1 {
      width: 20px;
      height: 20px;
      border-radius: 10px;
      background-color: grey;
    }
    .font2 {
      font-size: 14px;
      color: rgba(51, 51, 51, 1);
      line-height: 19px;
    }
    .button2 {
      .el-button {
        width: 130px;
        height: 36px;
        padding: 8px 20px;
      }
    }
  }
}
</style>
