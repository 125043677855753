<template>
  <div class="absolute" ref="pie"></div>
</template>
<script>
import echarts from 'echarts'

export default {
  name: 'pieChart',

  props: {
    pieData: {
      type: Array,
      default() {
        return []
      }
    },
    options: {
      type: Object,
      default() {
        return {}
      }
    },
    title: String
  },

  watch: {
    pieData: {
      handler(value) {
        if (value.length > 0 && this.myChart) {
          this.myChart.setOption(this.getOptionData(), true, false)
        }
      },
      immediate: true
    },

    options: {
      handler(value) {
        if (Object.keys(value).length > 0 && this.myChart) {
          this.myChart.setOption(this.getOptionData(), true, false)
        }
      },
      immediate: true
    }
  },

  mounted() {
    this.init()
  },

  destroyed() {
    this.offResize()
    this.myChart.dispose()
    this.myChart = null
  },

  methods: {
    onResize() {
      if (this.myChart) {
        this.myChart.resize()
      }
    },

    offResize() {
      window.removeEventListener('resize', this.onResize)
    },

    // 获取option参数
    getOptionData() {
      return _.merge(
        {},
        {
          title: {
            text: this.title || ''
          },
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c} ({d}%)'
          },
          legend: {
            orient: 'horizontal',
            bottom: 0,
            left: 'center',
            itemGap: 30,
            textStyle: {
              color: '#999'
            },
            data: this.pieData.map(item => item.name)
          },
          series: [
            {
              name: '',
              type: 'pie',
              radius: ['50%', '70%'],
              avoidLabelOverlap: false,
              center: ['50%', '50%'],
              bottom: 34,
              label: {
                show: false,
                position: 'center'
              },
              // emphasis: {
              //   label: {
              //     show: true,
              //     fontSize: "30",
              //     fontWeight: "bold",
              //   },
              // },
              labelLine: {
                show: false
              },
              data: this.pieData
            }
          ]
        },
        this.options
      )
    },

    init() {
      const myChart = (this.myChart = echarts.init(this.$refs.pie))

      this.offResize()
      window.addEventListener('resize', this.onResize)

      const option = this.getOptionData()

      myChart.setOption(option)
    }
  }
}
</script>
