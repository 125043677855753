<template>
  <div id="detail">
    <template v-if="$route.name == 'publicClassdetail'">
      <div class="headerinfo">
        <ul class="ul1">
          <li class="item">
            <!-- {{ openClassHeaderlist }} -->
            <div class="cover">
              <img :src="openClassHeaderlist.cover_img" alt="" />
              <div class="tip">
                {{ openClassHeaderlist.status | getstatus }}
                <span v-if="openClassHeaderlist.live_mode != 3">
                  -{{ openClassHeaderlist.live_mode | getlive_mode }}
                </span>
                (<span v-if="openClassHeaderlist.screen_type==2">竖屏</span>
                <span v-else>横屏</span>)
              </div>
              <div class="time" v-if="openClassHeaderlist.down_time > 0">
                距离开播：{{
                  openClassHeaderlist.down_time | formatTimeLength(2)
                }}
              </div>
            </div>
            <div class="operation">
              <div class="title">{{ openClassHeaderlist.name }}</div>
              <div class="courseinfo">
                <div class="courseinfo">
                  <span v-if="openClassHeaderlist.start_time > 0">
                    直播时间：{{
                      openClassHeaderlist.start_time
                        | formatTimeStamp('yyyy-MM-dd hh:mm')
                    }}
                    <span style="margin: 0 5px">|</span>
                  </span>

                  直播老师：{{ openClassHeaderlist.uname }}
                </div>
              </div>
              <div class="smartName" v-if="openClassHeaderlist.msg_name">
                智能脚本：{{ openClassHeaderlist.msg_name }}
              </div>
              <div class="money">
                <span
                  class="redcolor"
                  v-if="openClassHeaderlist.play_type != 2"
                >
                  {{
                    openClassHeaderlist.play_price > 0
                      ? '￥' + openClassHeaderlist.play_price
                      : '免费'
                  }}
                </span>
                <span class="redcolor" v-else>
                  密码：{{ openClassHeaderlist.play_pwd }}
                </span>

                <span
                  class="strick"
                  v-if="
                    openClassHeaderlist.play_type == 3 &&
                    openClassHeaderlist.cost_price > 0
                  "
                >
                  ￥{{ openClassHeaderlist.cost_price }}
                </span>
              </div>
              <div class="coursebtns" :class="[openClassHeaderlist.msg_name&&'smartTop']">
                <div class="leftbnt">
                  <el-button
                    type="primary"
                    class="share"
                    style="color: #fff !important"
                    size="mini"
                    v-if="fatherPath == '/intelligence'&&openClassHeaderlist.is_old==1"
                    @click="containManager(openClassHeaderlist)"
                  >
                    内容管理
                  </el-button>
                  <classesBegin
                    v-else-if="openClassHeaderlist.is_old==1"
                    :item="openClassHeaderlist"
                    class="vm table-classesBegin"
                    type="4"
                  >
                    <el-button type="primary" size="mini">发起直播</el-button>
                  </classesBegin>
                  <el-button
                    size="mini"
                    class="share ml20"
                    @click="share(openClassHeaderlist)"
                  >
                    分享直播
                  </el-button>
                  <el-button
                    v-if="fatherPath == '/publicClass'"
                    size="mini"
                    class="share"
                    style="margin-left: 20px !important"
                    @click="exportTemplateclick(openClassHeaderlist)"
                  >
                    导出聊天记录
                  </el-button>
                </div>
                <div class="rightbtn">
                  <span class="sp_name" @click="consoleClick">控制台</span>
                  <span class="sp_diver">|</span>
                  <span
                    class="sp_name"
                    @click="pushsetingclick(openClassHeaderlist)"
                  >
                    推送设置
                  </span>
                  <!-- <span class="sp_diver">|</span>
                  <span
                    class="sp_name"
                    @click="setting(openClassHeaderlist.open_class_id)"
                  >
                    设置
                  </span> -->
                  <span class="sp_diver">|</span>
                  <span class="sp_name" @click="editor">编辑</span>
                  <!-- <span class="sp_diver">|</span>
                  <span
                    class="sp_name"
                    @click="destory(openClassHeaderlist.open_class_id)"
                  >
                    清理聊天记录
                  </span> -->
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="detaincontain">
        <el-tabs v-model="activeName">
          <el-tab-pane label="成员管理" name="1">
            <member-management v-if="activeName == '1'"></member-management>
          </el-tab-pane>
          <el-tab-pane label="直播设置" name="7">
            <setting v-if="activeName == '7'" :screen_type="openClassHeaderlist.screen_type"></setting>
          </el-tab-pane>
          <el-tab-pane label="直播间装修" name="8">
            <customMenu
              :liveRoomUrl="openClassHeaderlist.share_url"
              v-if="activeName == '8'"
            ></customMenu>
          </el-tab-pane>
          <el-tab-pane label="直播记录" name="2">
            <live-recording
              v-if="activeName == '2'"
              :fatherPath="fatherPath"
            ></live-recording>
          </el-tab-pane>
          <!-- <el-tab-pane label="聊天记录" name="9">
            <chat-record
              v-if="activeName == '9'"
              :fatherPath="fatherPath"
            ></chat-record>
          </el-tab-pane> -->
          <el-tab-pane label="订阅管理" name="3">
            <subscription-management
              v-if="activeName == '3'"
            ></subscription-management>
          </el-tab-pane>
          <el-tab-pane label="邀请记录" name="4">
            <invitation-record v-if="activeName == '4'"></invitation-record>
          </el-tab-pane>
          <el-tab-pane label="回放记录" name="5">
            <payback-record v-if="activeName == '5'"></payback-record>
          </el-tab-pane>
          <el-tab-pane label="数据统计" name="6">
            <dataAnalysisDetail v-if="activeName == '6'" :isExportAll="false"></dataAnalysisDetail>
          </el-tab-pane>
        </el-tabs>
      </div>
    </template>

    <!-- 导出模板 -->
    <export-template
      v-if="exportTemplatestatus"
      :dialogstatus.sync="exportTemplatestatus"
      :openCouseId="openCouseId"
    ></export-template>
    
    <tip
      v-if="status"
      @sure="sure"
      :list="list"
      :dialogstatus.sync="status"
    ></tip>

    <shareinfo
      v-if="shareinfo"
      :dialogstatus.sync="shareinfo"
      :shareinfoval="shareinfoval"
      :fatherPath="fatherPath"
    ></shareinfo>

    <push-settings
      v-if="status2"
      :pushseting="pushseting"
      :dialogstatus.sync="status2"
    ></push-settings>

    <livebroadcast-settings
      v-if="status3"
      :settingval="settingval"
      :dialogstatus.sync="status3"
    ></livebroadcast-settings>

    <router-view />
  </div>
</template>

<script>
import exportTemplate from './exportTemplate'
import tip from './components/tip'
import dataAnalysisDetail from '@/components/dataAnalysis/dataAnalysisDetail4'
import livebroadcastSettings from '@/components/livebroadcastSettings'
import pushSettings from './components/pushSettings'
import shareinfo from '@/components/openCourse/shareinfo'
import paybackRecord from './detailcomponents/paybackRecord'
import memberManagement from './detailcomponents/memberManagement'
import liveRecording from './detailcomponents/liveRecording'
import subscriptionManagement from './detailcomponents/subscriptionManagement'
import invitationRecord from './detailcomponents/invitationRecord'
// import chatRecord from './detailcomponents/chatRecord'
import setting from './detailcomponents/setting/main'
import customMenu from './detailcomponents/customMenu'
import { createNamespacedHelpers } from 'vuex'
const { mapActions } = createNamespacedHelpers('OpenClassopenClassLive')

export default {
  name: 'detail',

  components: {
    // chatRecord,
    tip,
    livebroadcastSettings,
    pushSettings,
    memberManagement,
    liveRecording,
    subscriptionManagement,
    invitationRecord,
    paybackRecord,
    shareinfo,
    setting,
    customMenu,
    dataAnalysisDetail,
    exportTemplate,
  },

  created() {
    this.getopenClassHeader()
    if (this.$route.params.type == 3) {
      this.activeName = '3'
    }
    if (this.$route.params.type == 4) {
      this.activeName = '4'
    }
  },

  data() {
    return {
      exportTemplatestatus: false,

      // 父级路由
      fatherPath: '',

      status: false,

      //是否有进入控制台的权限
      consoleInfostatus: false,

      pushseting: {},

      settingval: '',

      shareinfoval: {},

      status2: false,

      status3: false,

      shareinfo: false,

      //获取头部信息
      openClassHeaderlist: { share_url: '' },

      activeName: '1',
    }
  },

  watch: {
    $route: {
      handler(value) {
        this.fatherPath = '/' + value.path.split('/')[1]
      },
      immediate: true,
    },
  },

  filters: {
    getstatus(val) {
      let text
      switch (Number(val)) {
        case 1:
          text = '直播中'
          break
        case 2:
          text = '未直播'
          break
        case 3:
          text = '直播结束'
          break
      }
      return text
    },

    getlive_mode(val) {
      let text
      switch (Number(val)) {
        case 1:
          text = '标准直播'
          break
        case 2:
          text = '快直播'
          break
      }
      return text
    },
  },

  methods: {
    ...mapActions(['sendToWindowsSizeInOpenClass']),

    // 导出模板
    exportTemplateclick(item) {
      this.openCouseId = item.open_class_id
      this.$nextTick(() => {
        this.exportTemplatestatus = true
      })
    },

    sure() {
      this.status = false
    },

    //编辑
    editor() {
      if (this.openClassHeaderlist.status == 1) {
        this.$root.prompt('直播中，不可编辑！')
        return
      }
      this.$router.push({
        path: `${this.fatherPath}/newData/${this.$route.params.id} `,
      })
    },

    //直播设置
    setting(val) {
      this.settingval = val
      this.status3 = true
    },

    //推送设置
    pushsetingclick(val) {
      this.pushseting = val
      this.status2 = true
    },

    //分享直播
    share(item) {
      this.shareinfoval = item
      this.shareinfo = true
    },

    // 内容管理
    containManager(val) {
      this.$router.push({
        path: '/intelligence/intelligenceManager',
        query: { open_class_id: val.open_class_id },
      })
    },

    getopenClassHeader() {
      this.$http({
        url: '/openClass/openClassHeader',
        data: {
          open_class_id: this.$route.params.id,
        },
        callback: ({ data }) => {
          this.openClassHeaderlist = _.assign({}, data, {
            open_class_id: this.$route.params.id,
          })
        },
        error: () => {
          this.$root.prompt('操作失败')
        },
      })
    },

    consoleInfo(val) {
      return new Promise((resolve) => {
        this.$http({
          url: '/console/checkTeacher',
          mute: true,
          data: {
            open_class_id: val,
          },
          callback: () => {
            this.consoleInfostatus = false

            resolve()
          },
          error: () => {
            this.consoleInfostatus = true
            resolve()
          },
        })
      })
    },

    //控制台
    consoleClick() {
      this.consoleInfo(this.openClassHeaderlist.open_class_id).then(() => {
        if (this.consoleInfostatus) {
          this.list = {
            type: 2,
            contain: '直播老师和该公开课助教老师可进入控制台！',
          }

          this.$nextTick(() => {
            this.tipType = 2
            this.status = true
          })
        } else {
          //如果是客户端，则打开客户端
          //windo.qt是客户端带的
          // if (this.$store.state.isWindowsApp) {
          let url = this.$router.resolve({
            path:
              '/OpenClass_openClassLive/' +
              this.openClassHeaderlist.open_class_id,
          })
          this.$store.dispatch('open2', { type: 7, url: url.href })

          if (this.$store.state.isWindowsApp) {
            this.sendToWindowsSizeInOpenClass({ width: 300, height: 653 })
          }
        }
      })
    },

    //删除
    del() {
      this.list = {
        type: 1,
        contain: '是否删除该直播？可在回收站还原！',
      }
      this.$nextTick(() => {
        this.status = true
      })
    },

    // 解散清空
    async destory(val) {
      await this.$confirm(
        '是否确定解散并清空聊天记录？解散后已在直播间的学员会被踢出，需要重新进入！付费直播无需重新购买！',
        '温馨提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }
      )

      this.$http({
        url: '/openClass/dissolution',
        data: {
          open_class_id: val,
        },
        callback: () => {
          this.$root.prompt({
            msg: '解散成功',
            type: 'success',
          })
        },
        error: () => {
          this.$root.prompt('操作失败')
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
#detail {
  .headerinfo {
    padding: 20px;
    background-color: #fff;
    .ul1 {
      .item {
        display: flex;
        .cover {
          border-radius: 5px;
          width: 280px;
          height: 157px;
          background-color: grey;
          margin-right: 20px;
          position: relative;
          img {
            height: 100%;
            width: 100%;
          }
          .tip {
            padding: 6px 4px;
            font-size: 12px;
            color: #ffffff;
            line-height: 12px;
            position: absolute;
            top: 10px;
            left: 10px;
            background: #000000;
            border-radius: 4px;
            // opacity: 0.59;
          }
          .time {
            border-radius: 0 0 5px 5px;
            width: 100%;
            text-align: center;
            position: absolute;
            bottom: 0;
            left: 0;
            background: #000000;
            // opacity: 0.69;
            font-size: 14px;
            font-weight: bold;
            color: #ffffff;
            line-height: 14px;
            letter-spacing: 1px;
            padding: 9px 0;
          }
        }
        .operation {
          width: calc(100% - 300px);
          .title {
            font-size: 16px;
            font-weight: bold;
            color: #363636;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .courseinfo {
            font-size: 13px;
            color: #666666;
            line-height: 13px;
            margin: 14px 0 14px;
          }
          .smartName {
            margin-bottom:20px;
            font-size: 13px;
            color: #A1A1A1;
            line-height: 13px;
          }
          .money {
            .redcolor {
              font-size: 14px;
              font-weight: bold;
              line-height: 14px;
            }
            .strick {
              margin-left: 6px;
              text-decoration: line-through;
              font-size: 12px;
              color: #666666;
              line-height: 14px;
            }
          }
          .coursebtns {
            margin-top: 49px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .leftbnt {
              ::v-deep .el-button {
                width: 100px !important;
                border-radius: 15px !important;
                border: 1px solid #0aa29b;
              }
              .share {
                color: #0aa29b !important;
              }
            }
            .rightbtn {
              font-size: 13px;
              color: #0aa29b;
              line-height: 13px;

              .sp_name {
                padding: 0 5px;
                cursor: pointer;
              }
              ::v-deep .el-dropdown {
                font-size: 13px;
                color: #0aa29b;
              }
            }
          }
          .smartTop {
            margin-top: 14px;
          }
        }
      }
    }
  }
  ::v-deep .detaincontain {
    background-color: #fff;
    margin-top: 20px;
    .el-tabs__nav-scroll {
      padding: 0 30px;
    }
    .el-tabs__nav-wrap::after {
      height: 1px !important;
    }
    .el-tabs__content {
      padding: 10px 20px;
    }
  }
}
</style>
