<template>
  <!--编辑器中使用的组件 图片添加和上传 -->
  <div>
    <div class="editor my-editor" ref="editor"></div>
    <div class="upload-img">
      <!-- 本地上传弹窗 -->
      <upload
        v-if="uploadStart"
        @close="localUpload"
        :typeselection="1"
        :title="title"
        :uploadType="uploadType"
        :list="group_id1"
      ></upload>
      <!-- 图片选择弹窗 localUpload 选中图片的回调 list  获取选中左侧列表的id group_id-->
      <addpicture
        :size="10"
        v-if="addpicturestatus"
        :dialogstatus="addpicturestatus"
        @locationUpload="localUpload"
        :type="type"
        ref="addpicture"
        @group_id="groupIdChange"
        @list="uploadComplete"
        @close="uploadConfirm"
      ></addpicture>
    </div>
  </div>
</template>
<script>
const E = require('wangeditor')
//本地上传弹窗
import upload from '@cm/base/UploadtextFile'
//图片组件
import addpicture from '@cm/base/addpicture'

export default {
  name: 'editor',

  components: {
    upload,
    addpicture,
  },

  props: {
    value: String,
    title: {
      type: String,
      default: '上传图片(多选)',
    },
    uploadType: {
      type: Number,
      default: 2,
    },
    url: {
      type: String,
      default: '',
    },
    isshow: {
      type: Boolean,
      default: false,
    },
    uid: {
      type: [Number, String],
      default: undefined,
    },
    type: {
      type: Number,
      default: 1,
    },
  },

  data() {
    return {
      editor: null,

      group_id1: '',
      uploadStart: false,
      addpicturestatus: false,
    }
  },

  watch: {
    value(newVal, oldVal) {
      if (!oldVal && newVal !== oldVal && this.editor) {
        this.html(newVal)
      }
    },
  },

  mounted() {
    this.init()
  },

  beforeDestroy() {
    // 销毁编辑器
    this.editor.destroy()
    this.editor = null
  },

  methods: {
    // 初始化编辑器
    init() {
      this.editor = new E(this.$refs.editor)
      this.editor.config.showLinkImg = false
      this.editor.config.uploadImgFromMedia = this.uploadConfirm

      this.editor.config.customAlert = s => {
        this.$root.prompt(s)
      }
      this.editor.config.onchange = html => {
        this.$emit('input', html)
      }
      // 自定义菜单配置
      this.editor.config.menus = [
        'head', // 标题
        'bold', // 粗体
        'fontSize', // 字号
        'fontName', // 字体
        'underline', // 下划线
        'strikeThrough', // 删除线
        'foreColor', // 文字颜色
        'backColor', // 背景颜色
        'link', // 插入链接
        'list', // 列表
        'justify', // 对齐方式
        'quote', // 引用
        'image', // 插入图片
        'code', // 插入代码
        'undo', // 撤销
      ]
      this.editor.create()
      this.html(this.value)
    },

    html(val) {
      this.editor.txt.html(val)
    },

    text() {
      this.editor.txt.text()
    },

    uploadConfirm() {
      this.addpicturestatus = !this.addpicturestatus
    },

    uploadComplete(val) {
      const text = `
        <img src="${this.$options.filters.imageslim(
          val.url,
          2
        )}" style="max-width:100%;vertical-align: bottom !important;"/>
      `
      this.editor.cmd.do(
        'insertHTML',
        text
        // `<img src="${this.$options.filters.imageslim(
        //     val.url,
        //     2
        //   )}" style="max-width:100%;"/>`
      )
        // `<img src="${this.$options.filters.imageslim(
        //   val.url,
        //   2
        // )}" style="max-width:100%;"/>`
    },

    //本地上传弹窗控制
    localUpload() {
      this.uploadStart = !this.uploadStart
    },

    //获取图片上传中的分组id
    groupIdChange(val) {
      this.group_id1 = val
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .editor {
  width: 100%;
  max-width: 688px;
  line-height: 40px;
  font-size: 14px;
  &.w-e-full-screen-editor {
    max-width: 100%;
  }
  img {
    display: initial;
    border-radius: initial;
  }
}
::v-deep .upload-img .el-dialog {
  line-height: initial;
}
::v-deep .w-e-text p {
  // line-height: 0px;
}
</style>
