<template>
  <div id="cascader">
    <el-popover
      placement="bottom"
      popper-class="customMenuleftclass"
      :visible-arrow="false"
      trigger="click"
    >
      <div class="popMainContain">
        <div class="left">
          <ul class="leftUL" ref="leftUL">
            <li
              class="letftitem"
              @mouseenter="systemDefault(false)"
              @click="didingyi"
            >
              自定义
            </li>
            <li
              class="letftitem"
              @mouseenter="systemDefault(true)"
              :style="
                isshow ? 'color: #0aa29bff; background-color: #fafafaff;' : ''
              "
            >
              系统默认
              <i class="el-icon-arrow-right"></i>
            </li>
          </ul>
        </div>
        <div class="right" v-if="isshow">
          <div
            class="rightitem"
            v-for="(item, index) in defaultMenu"
            :key="index"
            :style="item.isSelect ? 'color:#A9A9A9FF;cursor: no-drop;' : ' '"
            @click="item.isSelect ? '' : selectMenu(item)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div slot="reference">
        <slot name="reference"></slot>
      </div>
    </el-popover>

    <el-button id="popo-btn" v-show="false" ref="popo" />
  </div>
</template>

<script>
export default {
  name: 'cascader',
  data() {
    return {
      isshow: false,
    }
  },
  props: {
    defaultMenu: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    didingyi() {
      const val = {
        type: 5,
        name: '菜单名称',
        isSelect: true,
        id: Math.random(),
        is_inside_open: 1,
        is_confirm: 1,
        advertInfo: [],
        link_url: '',
        content: '',
      }
      this.$refs.popo.$el.click()
      this.$emit('selectMenu', val)
    },
    selectMenu(item) {
      this.$refs.popo.$el.click()
      this.$emit('selectMenu', item)
    },
    systemDefault(val) {
      if (val) {
        this.isshow = true
      } else {
        this.isshow = false
      }
    },
  },
}
</script>

<style  lang="scss">
#popo-btn {
  width: 0;
  height: 0;
  border: none;
  padding: 0;
  margin: 0;
}
.customMenuleftclass {
  margin-top: 0 !important;
  padding: 0;
  .popMainContain {
    display: flex;
    .left {
      width: 150px;
      border-right: 1px solid #eeeeee80;
      .leftUL {
        .letftitem {
          padding: 10px 17px;
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          color: #2f2f2f;
          cursor: pointer;
        }
        .letftitem:hover {
          color: #0aa29bff;
          background-color: #fafafaff;
        }
      }
    }
    .right {
      width: 150px;
      .rightitem {
        cursor: pointer;
        padding: 14px 17px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
#cascader {
}
</style>