<template>
  <div id="customMenu-left">
    <div class="tu">
      <!-- <img src="~@ass/img/1.4.4.4/img_zdycdslt@2x.png" alt="" /> -->
      <img src="~@ass/img/1.4.43/img_slt@2x.png" alt="" />
    </div>
    <div class="menuContain">
      <ul class="containUl" ref="containUl">
        <!-- <vuedraggable
          :style="`width: ${leftwidth}`"
          v-model="newMenuList"
          @end="add2"
          @start="start2"
          animation="200"
        > -->
          <transition-group class="wrapper" style="width:100%;">
            <li
              class="item"
              v-for="i in newMenuList"
              :key="i.id"
              :style="i.id == selectindex ? 'color:#0AA29BFF' : ''"
              ref="wrapperwidth"
              >
              <!-- @click="selectMenu(i.id)" -->
              {{ i.name }}
            </li>
          </transition-group>
        <!-- </vuedraggable> -->
        <!-- <li
          class="item cascaderitem cp"
          :style="`padding: 0; width: ${itemwidth}; `"
          v-if="!hidden"
        >
          <cascader :defaultMenu="defaultMenu" @selectMenu="selectvalCascader">
            <template #reference>
              <i style="color: #0aa29bff">+</i>
            </template>
          </cascader>
        </li> -->
      </ul>
      <!-- <div class="tips">
        提示：拖拽菜单可进行排序，最多可添加5个菜单
        系统模块可修改名称，不可编辑内容
      </div> -->
    </div>
  </div>
</template>
<script>
// import vuedraggable from 'vuedraggable'
// import cascader from '@cm/toolAssembly/cascader'
export default {
  name: 'customMenu-left',
  components: {
    // cascader,
    // vuedraggable,
  },
  data() {
    return {
      ismove: false,
      isadd: true,
      leftwidth: '0',
      itemwidth: '100%',
      list: [1, 2, 3, 4, 4, 5],
      lis: [],
      hidden: false,
      newMenuList: [],
      selectindex: 1,
    }
  },
  watch: {
    menuList(val) {
      this.newMenuList = _.assign([], val)
    },

    selectId() {
      if (!this.ismove) {
        this.selectindex = this.selectId
      }
    },

    // newMenuList(val) {
    //   if (val.length >= 5) {
    //     this.hidden = true
    //   } else {
    //     this.hidden = false
    //   }

    //   if (!this.ismove) {
    //     this.selectindex =
    //       this.newMenuList.length > 0 ? this.newMenuList[0].id : ''
    //     this.$emit('selectMenu', this.selectindex)
    //   }

    //   if (this.isadd) {
    //     this.$nextTick(() => {
    //       setTimeout(() => {
    //         if (this.newMenuList.length == 1) {
    //           this.itemwidth = '50%'
    //           this.leftwidth = '50%'
    //         }
    //         if (this.newMenuList.length == 0) {
    //           this.itemwidth = '100%'
    //           this.leftwidth = '0'
    //         }

    //         if (this.newMenuList.length <= 5 && this.newMenuList.length > 1) {
    //           this.itemwidth =
    //             this.newMenuList.length == 0
    //               ? '100%'
    //               : this.$refs.wrapperwidth[0].offsetWidth + 'px'
    //           this.leftwidth = `calc(100% - ${this.itemwidth})`
    //         }
    //         if (this.newMenuList.length == 5) {
    //           this.itemwidth = 0 + 'px'
    //           this.leftwidth = '100%'
    //         }
    //       }, 200)
    //     })
    //   }
    //   this.isadd = false
    // },
  },
  computed: {
    defaultMenu() {
      let val = [
        {
          isSys: true,
          id: Math.random(),
          type: 1,
          name: '互动',
          isSelect: false,
        },
        {
          isSys: true,
          id: Math.random(),
          type: 2,
          name: '直播简介',
          isSelect: false,
        },
        {
          isSys: true,
          id: Math.random(),
          type: 3,
          name: '老师',
          isSelect: false,
        },
        {
          isSys: true,
          id: Math.random(),
          type: 4,
          name: '回放',
          isSelect: false,
        },
      ]
      val.forEach((item) => {
        let index = this.newMenuList.findIndex((i) => i.type == item.type)
        if (index > -1) {
          item.isSelect = true
        }
      })
      return val
    },
  },
  props: {
    menuList: {
      type: Array,
      default: () => [],
    },
    selectId: [String, Number],
  },
  methods: {
    changeisadd() {
      this.isadd = true
    },
    add2() {
      this.$emit('update:menuList', this.newMenuList)
      this.st = setTimeout(() => {
        this.ismove = false
      }, 1000)
    },
    start2() {
      this.ismove = true
      clearTimeout(this.st)
    },

    selectvalCascader(item) {
      this.isadd = true
      this.newMenuList.push(item)
      this.add2()
      this.selectindex = item.id
      this.$emit('selectMenu', item.id)
      this.ismove = true
    },

    selectMenu(id) {
      this.selectindex = id
      this.$emit('selectMenu', id)
    },
  },
}
</script>

<style lang="scss" scoped>
.cascaderitem {
  line-height: 25px !important;
  text-align: center !important;
  min-width: 90px !important;
}
.wrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  .item {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    max-height: 25px;
    line-height: 25px;
    font-size: 12px;
    flex: 1;
    font-weight: 500;
    color: #333333;
    // padding: 5px 0;
    border-right: 1px solid #eaeaea80;
    border-bottom: 1px solid #eaeaea80;
    text-align: center;
    cursor: pointer;
    .el-icon-plus {
      line-height: 35px;
    }
  }

  // .item:nth-child(1) {

  //   border-left: 1px solid #eaeaea80;
  // }

  // .lastchile:last-child {
  //   padding: 0;
  //   width: 100px;
  // }
}
.vuedraggable {
  width: 300px;
  height: 50px;
  background-color: #42b983;
  color: #ffffff;
}

#customMenu-left {
  background: #f5f5f5ff;
  width: 350px;
  min-width: 300px;
  position: relative;
  height: 682px;
  img {
    height: 100%;
    width: 100%;
  }
  .tu {
    width: 100%;
    height: auto;
  }

  .menuContain {
    overflow: hidden;
    position: absolute;
    width: 100%;
    top: 47.5%;
    .containUl {
      height: 25px;
      overflow: hidden;
      border: 1px solid #eaeaea80;
      display: flex;
      background-color: #fff;
    }
    // .tips {
    //   font-size: 12px;
    //   color: #4a4a4a;
    //   margin: 50px auto;
    //   width: 272px;
    //   background-color: #f5f5f5ff;
    //   line-height: 16px;
    // }
  }
}
</style>
