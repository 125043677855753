<template>
  <div class="customMenu-right">

    <div class="menuContain">
      <div class="tips">
        <span class="title mr30">菜单设置</span>
        <span>
          提示：拖拽菜单可进行排序，最多可添加5个菜单
          系统模块可修改名称，不可编辑内容
        </span>
      </div>
      <ul class="containUl" ref="containUl">
        <vuedraggable
          :style="`width: ${leftwidth}`"
          @end="add2"
          @start="start2"
          v-model="newMenuList"
          animation="200"
        >
          <transition-group class="wrapper">
            <li
              class="item"
              v-for="i in newMenuList"
              :key="i.id"
              :style="i.id == selectindex ? 'color:#0AA29BFF' : ''"
              @click="selectMenu(i.id)"
              ref="wrapperwidth"
            >
              {{ i.name }}
            </li>
          </transition-group>
        </vuedraggable>
        <li
          class="item cascaderitem cp"
          :style="`padding: 0; width: ${itemwidth}; `"
          v-if="!hidden"
        >
          <cascader :defaultMenu="defaultMenu" @selectMenu="selectvalCascader">
            <template #reference>
              <i style="color: #0aa29bff">+</i>
            </template>
          </cascader>
        </li>
      </ul>
    </div>
    <div class="option" v-if="showOpt">
      <div class="option-content">
        <div class="t1">
          菜单名称
          <span class="tag" :class="{ sys: options.isSys }">
            {{ options.isSys ? '系统' : '自定义' }}
          </span>
        </div>
        <div class="t2">
          <el-input
            placeholder="请输入菜单名称"
            maxlength="5"
            show-word-limit
            v-model="options.name"
          ></el-input>
        </div>
        <el-popconfirm
          title="是否确定删除该菜单？"
          class="remove"
          @confirm="removeModule"
        >
          <el-button
            slot="reference"
            type="text"
            class="redcolor"
            :disabled="options.type == 1"
          >
            删除
          </el-button>
        </el-popconfirm>
      </div>
    </div>
    <template v-if="!options.isSys&&showOpt">
      <div class="option">
        <div class="option-content">
          <div class="t1">菜单类型</div>
          <div class="t2">
            <el-radio-group v-model="options.type" @change="radioChange">
              <el-radio :label="5">图文</el-radio>
              <el-radio :label="6">链接</el-radio>
              <el-radio :label="7">广告图</el-radio>
            </el-radio-group>
          </div>
        </div>
        <editor
          v-if="options.type == 5"
          v-model="options.content"
          class="editor"
        ></editor>
        <el-input
          v-if="options.type == 6"
          class="menu-text-input"
          placeholder="请输入https开头跳转链接"
          type="textarea"
          resize="none"
          maxlength="255"
          :autosize="{ minRows: 3 }"
          v-model="options.link_url"
        ></el-input>
        <div class="add-image" v-if="options.type == 7">
          <div
            class="add-img-list"
            v-for="item in options.advertInfo"
            :key="item.id"
          >
            <div class="upload">
              <span class="zi" v-if="!item.url">添加图片</span>
              <img
                class="absolute advert-cover"
                v-if="item.url"
                :src="item.url"
                alt=""
              />
              <change-photoimg
                :size="2"
                @complete="item.url = arguments[0]"
                class="absolute"
                style="width: 100%; height: 100%"
              ></change-photoimg>
            </div>
            <div class="link">
              <el-input
                placeholder="请输入https开头跳转链接"
                type="textarea"
                resize="none"
                maxlength="255"
                :autosize="{ minRows: 3 }"
                v-model="item.link"
              ></el-input>
            </div>
            <el-popconfirm
              title="是否确定删除该广告图？"
              class="remove"
              @confirm="removeAddr(item)"
            >
              <div slot="reference" class="el-icon-close"></div>
            </el-popconfirm>
          </div>
          <div
            class="add-btn"
            v-if="options.advertInfo && options.advertInfo.length < 10"
          >
            <span @click="addAd">+新增图片</span>
          </div>
        </div>
      </div>
      <template v-if="options.type == 6">
        <div class="option">
          <div class="option-content">
            <div class="t1">
              打开方式
              <help-icon
                content="观看页内打开需使用https外链地址，展示效果取决于外部网站的适配情况，叮叮开课无法控制展示效果；新页面则为跳出观看页面打开"
              />
            </div>
            <div class="t2">
              <el-radio-group v-model="options.is_inside_open">
                <el-radio :label="1">观看页内</el-radio>
                <el-radio :label="2">新页面</el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>
        <div class="option" v-if="options.is_inside_open == 2">
          <div class="option-content">
            <div class="t1">
              跳转确认
              <help-icon
                content="指点击文字时是否询问用户跳转，为否时，点击直接跳转"
              />
            </div>
            <div class="t2">
              <el-radio-group v-model="options.is_confirm">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="2">否</el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>
<script>
import vuedraggable from 'vuedraggable'
import cascader from '@cm/toolAssembly/cascader'
import helpIcon from '@/components/helpIcon'
import editor from '@/components/editor'
import changePhotoimg from '@cm/base/changePhotoimg'

export default {
  name: 'customMenu-right',

  components: {
    cascader,
    vuedraggable,
    helpIcon,
    editor,
    changePhotoimg,
  },

  props: {
    value: Object,
    menuList: {
      type: Array,
      default: () => [],
    },
    selectId: [String, Number],
  },

  data() {
    return {
      options: {},
      
      ismove: false,
      isadd: true,
      leftwidth: '0',
      itemwidth: '100%',
      list: [1, 2, 3, 4, 4, 5],
      lis: [],
      hidden: false,
      newMenuList: [],
      selectindex: 1,
    }
  },

  computed: {
    defaultMenu() {
      let val = [
        {
          isSys: true,
          id: Math.random(),
          type: 1,
          name: '互动',
          isSelect: false,
        },
        {
          isSys: true,
          id: Math.random(),
          type: 2,
          name: '直播简介',
          isSelect: false,
        },
        {
          isSys: true,
          id: Math.random(),
          type: 3,
          name: '老师',
          isSelect: false,
        },
        {
          isSys: true,
          id: Math.random(),
          type: 4,
          name: '回放',
          isSelect: false,
        },
      ]
      val.forEach((item) => {
        let index = this.newMenuList.findIndex((i) => i.type == item.type)
        if (index > -1) {
          item.isSelect = true
        }
      })
      return val
    },
    showOpt(){
      return Object.keys(this.options).length>0
    },
  },
  watch: {
    value: {
      handler(val) {
        this.options = val
      },
      immediate: true,
      deep: true,
    },
    menuList: {
      handler(val){
        this.newMenuList = _.assign([], val)
      },
      immediate: true,
    },
    selectId: {
      handler(){
        if (!this.ismove) {
          this.selectindex = this.selectId
        }
      },
      immediate: true,
    },
    newMenuList: {
      handler(val){
        if (val.length >= 5) {
          this.hidden = true
        } else {
          this.hidden = false
        }
  
        if (!this.ismove) {
          this.selectindex =
            this.newMenuList.length > 0 ? this.newMenuList[0].id : ''
          this.$emit('selectMenu', this.selectindex)
        }
  
        if (this.isadd) {
          this.$nextTick(() => {
            setTimeout(() => {
              if (this.newMenuList.length == 1) {
                this.itemwidth = '50%'
                this.leftwidth = '50%'
              }
              if (this.newMenuList.length == 0) {
                this.itemwidth = '100%'
                this.leftwidth = '0'
              }
  
              if (this.newMenuList.length <= 5 && this.newMenuList.length > 1) {
                this.itemwidth =
                  this.newMenuList.length == 0
                    ? '100%'
                    : this.$refs.wrapperwidth[0].offsetWidth + 'px'
                this.leftwidth = `calc(100% - ${this.itemwidth})`
              }
              if (this.newMenuList.length == 5) {
                this.itemwidth = 0 + 'px'
                this.leftwidth = '100%'
              }
            }, 200)
          })
        }
        this.isadd = false
      },
      immediate: true,
    },
  },

  methods: {
    radioChange(val) {
      if (val == 7 && this.options.advertInfo.length == 0) {
        this.addAd()
      }
    },
    
    // 移除菜单
    removeModule() {
      this.$emit('removeModule', this.options.id)
    },

    // 添加广告
    addAd() {
      this.options.advertInfo.push({ link: '', url: '', id: Math.random() })
    },

    // 删除广告
    removeAddr({ id }) {
      const index = this.options.advertInfo.findIndex((item) => item.id == id)
      this.options.advertInfo.splice(index, 1)
      this.$emit('removeAddr')
    },

    changeisadd() {
      this.isadd = true
    },
    add2() {
      this.$emit('update:menuList', this.newMenuList)
      this.st = setTimeout(() => {
        this.ismove = false
      }, 1000)
    },
    start2() {
      this.ismove = true
      clearTimeout(this.st)
    },

    selectvalCascader(item) {
      this.isadd = true
      this.newMenuList.push(item)
      this.add2()
      this.selectindex = item.id
      this.$emit('selectMenu', item.id)
      this.ismove = true
    },

    selectMenu(id) {
      this.selectindex = id
      this.$emit('selectMenu', id)
    },
  },
}
</script>
<style lang="scss" >
/**
公共性样式，请勿在此写本组件基础样式
*/
.customMenu-right-live-room-qrcode {
  padding: 0;
  width: 320px;
  height: 308px;
  text-align: center;
  line-height: 308px;
  background: #ffffff;
  box-shadow: 0px 6px 33px 0px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  border: 1px solid #e6e6e6;
  .mid {
    line-height: initial;
    .qrcode {
      width: 156px;
      height: 156px;
      background: #d8d8d8;
    }
    .text {
      font-size: 16px;
      color: #333333;
      margin-top: 30px;
      text-align: center;
    }
  }
}
</style>
<style lang="scss" scoped>
#changePhotoimg {
  width: 124px !important;
  height: 70px !important;
}
.cascaderitem {
  line-height: 35px !important;
  text-align: center !important;
  min-width: 90px !important;
}
.wrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  .item {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    max-height: 40px;
    font-size: 13px;
    flex: 1;
    font-weight: 500;
    color: #333333;
    padding: 13px 0;
    border-right: 1px solid #eaeaea80;
    border-bottom: 1px solid #eaeaea80;
    text-align: center;
    cursor: pointer;
    .el-icon-plus {
      line-height: 35px;
    }
  }

  // .item:nth-child(1) {

  //   border-left: 1px solid #eaeaea80;
  // }

  // .lastchile:last-child {
  //   padding: 0;
  //   width: 100px;
  // }
}
.vuedraggable {
  width: 300px;
  height: 50px;
  background-color: #42b983;
  color: #ffffff;
}
.customMenu-right {
  flex: 1;
  // min-width: 457px;
  height: 682px;
  margin-left: 20px;
  padding: 0 20px 30px;
  background: #f5f5f5;
  box-sizing: border-box;
  border: 1px solid #e4e4e4;
  .menuContain {
    overflow: hidden;

    .tips {
      font-size: 12px;
      color: #4a4a4a;
      // margin: 50px auto;
      // width: 272px;
      margin: 30px 0 20px 0;
      background-color: #f5f5f5ff;
      line-height: 16px;
      .title { 
        font-size: 15px;
        font-weight: bold;
        color: #333333;
      }
    }
    .containUl {
      height: 40px;
      width: 400px;
      overflow: hidden;
      border: 1px solid #eaeaea80;
      display: flex;
      background-color: #fff;
      margin: 40px 0 30px 0;
    }
  }
  .option {
    // margin-bottom: 30px;
    .option-content {
      display: flex;
      align-items: center;
      .t1 {
        min-width: 75px;
        font-size: 14px;
        color: #333333;
        display: flex;
        align-items: center;
        .popover {
          margin-left: 5px;
        }
        .tag {
          width: 40px;
          padding: 0 3px;
          line-height: 17px;
          font-size: 12px;
          font-weight: 500;
          color: #3d80ef;
          text-align: center;
          border-radius: 2px;
          margin-left: 6px;
          border: 1px solid #3d80ef;
          &.sys {
            color: #0aa29b;
            border-color: #0aa29b;
          }
        }
      }
      ::v-deep .t2 {
        flex: 1;
        margin-left: 60px;
        max-width: 520px;
        input {
          height: 40px;
        }
      }

      .remove {
        width: 54px;
        padding: 15px 0;
        text-align: center;
        line-height: 17px;
        font-size: 14px;
        color: #ff3535;
        cursor: pointer;
        margin-left: 35px;
      }
    }
    .editor {
      margin-top: 15px;
    }

    ::v-deep .w-e-text-container {
      height: 350px !important;
    }
    ::v-deep .menu-text-input {
      margin-top: 15px;
      max-width: 688px;
    }
    .add-image {
      margin-top: 15px;
      .add-img-list {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        max-width: 716px;
        .upload {
          width: 124px;
          background: #ffffff;
          border-radius: 2px;
          line-height: 70px;
          height: 70px;
          font-size: 14px;
          color: #999999;
          text-align: center;
          position: relative;
          .advert-cover {
            object-fit: contain;
          }
        }
        ::v-deep .link {
          flex: 1;
          margin-left: 10px;
          textarea {
            height: 70px;
            border-radius: 2px;
            box-sizing: border-box;
            background: #ffffff;
            border: 1px solid #e8e8e8;
          }
        }
        .remove {
          cursor: pointer;
          color: #999999;
          margin-left: 8px;
        }
      }
      .add-btn {
        font-size: 0;
        span {
          width: 100px;
          font-size: 14px;
          color: #0aa29b;
          padding-top: 20px;
          cursor: pointer;
          display: inline-block;
        }
      }
    }
  }
  .option:last-child {
    margin-bottom: 0;
  }
}
</style>
