<template>
  <div class="shortcutBar">
    <div class="title">
      <div class="text">展示内容</div>
      <helpIcon
        content="<img src='https://f.dingdingkaike.com.cn/img_kjl_cdan.png'>"
      />
    </div>
    <div>
      <div v-for="item in shortcutBar" :key="item.id" class="switch-btn">
        <el-switch
          v-model="item.is_show"
          active-value="1"
          inactive-value="2"
          @change="setQuick(item)"
        ></el-switch>
        <span class="text">{{ item.name }}</span>
        <helpIcon
          v-if="item.info"
          style="margin-left: 6px"
          :content="item.info"
        ></helpIcon>
      </div>
    </div>
  </div>
</template>
<script>
import helpIcon from '@/components/helpIcon'

export default {
  name: 'shortcutBar',

  components: {
    helpIcon,
  },

  data() {
    return {
      shortcutBar: [],
    }
  },

  created() {
    this.getList()
  },

  methods: {
    getList() {
      this.$root.$http({
        url: '/liveMenu/quickMenu',
        data: {
          open_class_id: this.$route.params.id,
        },
        callback: ({ data }) => {
          this.shortcutBar = data
        },
      })
    },

    // 保存快捷功能
    setQuick(item) {
      this.$root.$http({
        url: '/liveMenu/setQuick',
        data: item,
        callback: () => {
          this.$root.prompt({
            msg: '操作成功',
            type: 'success',
          })
        },
        error: () => {
          this.$root.prompt({
            msg: '操作失败',
          })
        },
        complete: () => {
          this.getList()
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.shortcutBar {
  display: flex;
  .title {
    display: flex;
    margin-right: 36px;
    .text {
      font-size: 14px;
      color: #333333;
    }
    .popover {
      margin-left: 5px;
    }
  }
  .switch-btn {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #333333;
    margin-bottom: 20px;
    .text {
      font-size: 14px;
      color: #333333;
      margin-left: 12px;
    }
  }
}
</style>
